<template>
   <div>
      <trinity-rings-spinner
         :animation-duration="1500"
         :size="66"
         color="#ff1d5e"
         />
   </div>
</template>

<script>
import { TrinityRingsSpinner } from 'epic-spinners'

export default {
   name: "Loader",
   components: {TrinityRingsSpinner}
}
</script>