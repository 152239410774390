<template>
   <div>
      <div class="row mb-5">
         <div class="col">
            <div class="card border-left-warning shadow h-100 py-2">
               <div class="card-body">
                  <form action="" @submit.prevent="search">
                     <div class="row">
                        <div class="col">
                           <div class="row">
                              <div class="col">
                                 <input type="text" v-model="mobile" class="form-control" placeholder="رقم الجوال" >
                              </div>
                              <div class="col">
                                 <select class="form-control" v-model="userType" aria-placeholder="النوع">
                                    <option value="">عملاء ومزودين</option>
                                    <option value="provider">مزودين فقط</option>
                                 </select>
                              </div>
                              <div class="col">
                                 <select class="form-control" v-model="company" aria-placeholder="الشركة التابع لها">
                                    <option value="">الشركة التابع لها</option>
                                    <option v-for="(comp,index) in compan" :key="index" :value="comp.id">{{comp.name}}</option>
                                 </select>
                              </div>
                              <div class="col">
                                 <select class="form-control" v-model="blocked" aria-placeholder="الحظر">
                                 <option value="">حاله حظر</option>
                                 <option value="1">محظور</option>
                                 </select>
                              </div>
                              <div class="col">
                                 <select class="form-control" v-model="sortingBy" aria-placeholder="ترتيب حسب">
                                    <option value="">ترتيب حسب</option>
                                    <option value="balance_minus">اقل رصيد (الاعلى عمولة)</option>
                                    <option value="balance_plus">اعلى رصيد</option>
                                 </select>
                              </div>
                           </div>
                        </div>
                        <div class="col-auto">
                           <button type="submit" class="btn btn-success">
                              <i class="fas fa-search"></i>
                           </button>
                           <button type="button" v-if="hasSearch" class="btn btn-light ml-2" @click.prevent="resetSearch">إلغاء</button>
                        </div>
                     </div>
                  </form>
               </div>
            </div>
         </div>
      </div>

      <div class="row mb-3" v-if="getUserData.is_admin || getUserData.can_export">
         <div class="col text-right">
            <button type="button" class="btn btn-light" data-toggle="modal" data-target="#exportModal">
               <i class="far fa-file-excel mr-1"></i> التصدير
            </button>
         </div>
      </div>

      <div class="card shadow mb-4">
         <div class="card-header py-3">
            <h6 class="m-0 font-weight-bold text-primary">عملاء ومزودين</h6>
         </div>

         <div class="card-body">
            <div class="row">
               <div class="col text-right pb-3 pagination-div">
                  <a href="" class="d-inline-block mr-2" v-if="prevPage.length" @click.prevent="goToPrev"><i class="far fa-caret-square-right"></i></a>
                  <div class="d-inline-block rounded border border-secondary">{{ pageNumber }}</div>
                  <a href="" class="d-inline-block ml-2" v-if="nextPage" @click.prevent="goToNext"><i class="far fa-caret-square-left"></i></a>
               </div>
            </div>
            <div class="table-responsive">
               <div class="big-loader text-center pt-50 pb-50" v-if="loading">
                  <loader />
               </div>
               <table v-if="!loading" class="table text-center table-bordered table-striped " id="dataTable" width="100%" cellspacing="0">
                  <thead>
                     <tr>
                        <th>#</th>
                        <th>الاسم</th>
                        <th>الجوال</th>
                        <th>مزود خدمة</th> 
                        <th>كشف الحساب القديم</th>
                        <th>كشف حساب المزود</th>
                        <th>كشف حساب عميل</th>
                        <th>العمليات</th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr v-for="(row, index) in rows" :key="index">
                        <td v-if="pageNumber==1">{{ (index + 1) }}</td>
                        <td v-else>{{ (index + 1) + ((pageNumber - 1)*40)  }}</td>
                        <td>{{ row.name }}</td>
                        <td>{{row.mobile}}</td>
                        <td>
                           <i class="far fa-check-circle" v-if="row.is_provider"></i>
                           <i class="fas fa-times" v-else></i>
                        </td>
                        <td>
                           <router-link :to="`/users/payments/${row.id}`" class="btn btn-light nowrap">
                              <i class="fas fa-search-dollar"></i> كشف الحساب
                           </router-link>
                        </td>
                        <td>
                           <router-link v-if="row.is_provider" :to="`/users/paymentsNew/${row.id}?type=provider`" class="btn btn-light p-1">
                              <i class="far fa-2x fa-list-alt"></i>
                           </router-link>
                        </td>
                        <td>
                           <router-link :to="`/users/paymentsNew/${row.id}?type=user`" class="btn btn-light p-1">
                              <i class="fas fa-2x fa-list-alt"></i>
                           </router-link>
                        </td>
                        <td>
                           <router-link :to="'/users/'+row.id" class="btn btn-info">
                              <i class="fas fa-edit"></i>
                           </router-link>
                        </td>
                     </tr>
                  </tbody>
               </table>
            </div>
         </div>
      </div>
      <div class="modal fade" id="exportModal" tabindex="-1" aria-labelledby="exportModalLabel" aria-hidden="true" >
         <div class="modal-dialog modal-lg">
            <div class="modal-content">
               <div class="modal-header">
                  <h5 class="modal-title" id="exportModalLabel">التصدير</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                     <span aria-hidden="true">&times;</span>
                  </button>
               </div>
               <form action="" @submit.prevent="exportPorvider">
                  <div class="modal-body">
                     <div class="row">
                        <div class="col">
                           <div class="alert alert-info">تصدير المزودين المستحق عليهم عمولة إلى إزهلها ولم يمر على آخر طلب لهم اكثر من ثلاثة اشهر</div>
                           <div class="alert alert-warning">يرجى العلم ان التصدير قد يأخذ بعض الوقت بناء حجم البيانات القابلة للتصدير</div>
                        </div>
                     </div>
                     <div class="row" v-if="exportError !=''">
                        <div class="col">
                           <div class="alert alert-danger">{{ exportError }}</div>
                        </div>
                     </div>
                     <div class="row" v-if="exportDone">
                        <div class="col">
                           <div class="alert alert-success">تم تصدير الملف بنجاح</div>
                        </div>
                     </div>
                     <div class="row">
                        <div class="col">

                        </div>
                     </div>
                     <div class="modal-footer">
                        <inlineloader v-if="exportLoading" />
                        <button type="submit" class="btn btn-primary" v-else>تصدير</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">إغلاق</button>
                     </div>
                  </div>
               </form>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
   import { mapGetters } from 'vuex';
   export default 
   {
      name: "Users",
      computed:{
         ...mapGetters(['getUserData'])
      },
      data(){
         return {
            loading: true,
            cities_loader: true,
            breadcrum: {
               "/": "الرئيسية",
               "#": "عملاء ومزودين",
            },
            rows: [],
            compan:[],
            pageNumber: 1,
            nextPage: null,
            prevPage: [],
            hasSearch: false,
            mainUrl: "/api/users",
            endPoint: "/api/users",
            searchUrl: "",
            services: [],
            company: "",
            orderStatus: {
               draft: "Open",
               sent: "Scheduled",
               sale: "Live",
               done: "Done",
               cancel: "Cancelled",
               hold: "Hold",
               expired: "Expired",
               unavailable: "Unavailable",
               cancelled_by_user: "Canceled by user",
               cancelled_by_provider: "Cancelled by provider",
               reassign: "Reassign",
            },
            cities: [
               {
                  id: "not",
                  text: "بحث بالمدينة",
               }
            ],
            mobile: "",
            userType: "",
            sortingBy: "",
            blocked:"",
            exportError:"",
            exportDone: false,
            exportLoading: false,
         }
      },
      methods: {
         getData(){
               this.$http.get(this.endPoint).then(response => {
               this.loading = false;
               this.rows = response.data.rows;
               this.nextPage = response.data.next;
            }).catch(err => {
               this.$store.commit("SET_ERROR", err.data.error);
            });
         },
         goToNext(){
            this.prevPage.push(this.nextPage);
            this.endPoint = this.mainUrl + "?page=" + this.nextPage;
            if(this.searchUrl != "")
               this.endPoint = this.endPoint + "&" + this.searchUrl;
            this.loading = true;
            this.pageNumber = this.pageNumber + 1;
            this.getData();
         },
         goToPrev(){
            let prevIndex = this.prevPage.length - 1;
            
            if(this.pageNumber == 2){
               this.endPoint = this.mainUrl;
               if(this.searchUrl != "")
                  this.endPoint = this.endPoint + "?" + this.searchUrl;
            }else{
               this.endPoint = this.mainUrl + "?page=" + this.prevPage[prevIndex];
               if(this.searchUrl != "")
                  this.endPoint = this.endPoint + "&" + this.searchUrl;
            }
            
            this.$delete(this.prevPage, prevIndex);
            
            this.loading = true;
            this.pageNumber = this.pageNumber - 1;
            this.getData();
            if(this.pageNumber == 1){
               this.prevPage = [];
            }
         },
         search(){
            this.searchUrl = "";
            let searchParam = [];
            if(this.mobile.trim() != ""){
               searchParam.push("mobile="+this.mobile.trim());
            }
            if(this.userType != ""){
               searchParam.push("userType="+this.userType);
            }
            if(this.blocked !=""){
               searchParam.push("blocked="+this.blocked);
            }
            if(this.sortingBy != ""){
               searchParam.push("sortingBy="+this.sortingBy);
            }
            if(this.company !=""){
               searchParam.push("company="+this.company);
            }

            if(searchParam.length == 0){
               return;
            }
            this.searchUrl = searchParam.join("&");

            this.endPoint = this.mainUrl + "?" + searchParam.join("&");
            this.hasSearch = true;
            this.loading = true;
            this.pageNumber = 1;
            this.nextPage = null;
            this.prevPage = [];
            this.getData();
         },
         resetSearch(){
            this.mobile = "";
            this.searchUrl = "";
            this.userType = "";
            this.sortingBy = "";
            this.blocked="";
            this.company= "";

            this.endPoint = this.mainUrl;
            this.loading = true;
            this.hasSearch = false;
            this.getData();
         },
         exportPorvider(){
            this.exportError = "";
            this.exportDone = false;
            this.exportLoading = true;
            this.$http.get("/api/export/providers/providerCommissions",{responseType: "blob"}).then((response) => {
               var fileURL = window.URL.createObjectURL(new Blob([response.data]));
               var fileLink = document.createElement('a');

               fileLink.href = fileURL;
               fileLink.setAttribute('download', 'requests_commissions.xlsx');
               document.body.appendChild(fileLink);

               fileLink.click();
               this.exportLoading = false;
               this.exportDone = true;
            }).catch(() => {
               this.exportError = "غير قادر على التصدير";
               this.exportLoading = false;
            });
         },
         companies(){
            this.$http.get("/api/companies").then(response => {
               this.compan = response.data.rows;
            });
         }
      },
      created(){
         window.scroll(0, 0);
         this.$store.commit("SET_ACTIVE_MENU", "users");
         this.$store.commit("SET_BREADCRUMB", this.breadcrum);
         this.$store.commit("SET_ERROR", "");
         this.$store.commit("SET_SUCCESS", "");
         this.$store.commit("SET_TITLE", "عملاء ومزودين");
         
         this.getData();
         this.companies();
      }
   }
</script>