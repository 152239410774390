<template>
  <div class="home">
    <div class="row" v-if="loading">
      <div class="col">
        <div class="big-loader text-center pt-50 pb-50" v-if="loading">
          <loader />
        </div>
      </div>
    </div>

    <div class="row" v-if="!loading">
      <div class="col">
        <div class="card shadow mb-4">
          <div class="card-header py-3">
            <h6>متابعة خدمة العملاء للطلبات</h6>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col"></div>
              <div class="col-sm-3 col-6">
                <div class="card border-left-success shadow h-100 py-2">
                  <div class="card-body">
                    <div class="row no-gutters align-items-center">
                      <div class="col mr-2">
                        <p>بطل الشهر</p>
                        <div class="text-xs font-weight-bold text-success text-uppercase mb-1">{{ owner_name }}</div>
                        <div class="h5 mb-0 font-weight-bold text-gray-800">{{ owner_done }} طلب</div>
                      </div>
                      <div class="col-auto">
                        <i class="fas fa-trophy fa-2x text-gray-300"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="chart-h-300">
              <ChartLine :data="customerServices" :options="chartOption" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row pt-5 pb-5" v-if="!loading">
      <div class="col">
        <div class="card shadow mb-4">
          <div class="card-header py-3">
            <h6>موظفي خدمة العملاء المميزين خلال العام</h6>
          </div>
          <div class="card-body">
            <div class="chart-h-300">
              <ChartLine :data="year_owners" :options="chartOption" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChartLine from "@/components/ChartLine.vue";

export default {
  name: 'Home',
  components: {
    ChartLine
  },
  data(){
    return {
      loading: true,
      breadcrum: {
          "#": "الرئيسية",
      },
      customerServices: {},
      chartOption: {
        responsive: true,
        maintainAspectRatio: false
      },
      owner_name: "",
      owner_done: 0,
      year_owners: {},
    }
  },
  methods: {
    getData(){
      this.$http.get("/api/dashboard").then(response => {
          this.loading = false;
          this.customerServices = response.data.customer_services;
          this.owner_name = response.data.owner_name;
          this.owner_done = response.data.owner_done;
          this.year_owners = response.data.year_owners;
      }).catch(error => {
          this.$store.commit("SET_ERROR", error.data.error);
      });
    }
  },
  created(){
    this.$store.commit("SET_ACTIVE_MENU", "");
    this.$store.commit("SET_BREADCRUMB", this.breadcrum);
    this.$store.commit("SET_ERROR", "");
    this.$store.commit("SET_SUCCESS", "");
    this.$store.commit("SET_TITLE", "لوحة التحكم");
    
    this.getData();
  }
}
</script>

<style>
  .chart-h-300 {
    position: relative;
    height: 300px;
  }
  .chart-h-300 > div {
    position: relative;
    height: 100%;
  }
</style>