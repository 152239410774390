<template>
    <div>
        <div class="row mb-5">
            <div class="col">
                <div class="card border-left-warning shadow h-100 py-2 ">
                    <div class="card-body">
                        <form action="" @submit.prevent="search">
                            <div class="row">
                                <div class="col">
                                    <div class="row">
                                        <div class="col">
                                            <select class="form-control" v-model="company_type" aria-placeholder="">
                                                <option value="">نوع الشركة</option>
                                                <option value="supplier">supplier</option>
                                            </select>
                                        </div>
                                        <div class="col">
                                            <select class="form-control" v-model="active" aria-placeholder="">
                                                <option value="">بدون تحديد</option>
                                                <option value="active">نشط</option>
                                                <option value="inactive">غير نشط</option>
                                            </select>
                                        </div>
                                        <div class="col">
                                            <select class="form-control" v-model="sortingBy" aria-placeholder="ترتيب حسب">
                                                <option value="">ترتيب حسب</option>
                                                <option value="balance_minus"> (الاعلي عمولة)اقل رصيد</option>
                                                <option value="balance_plus">اعلي رصيد</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-auto">
                                    <button type="submit" class="btn btn-success">
                                        <i class="fas fa-search"></i>
                                    </button>
                                    <button type="button" v-if="hasSearch" class="btn btn-light ml-2" @click.prevent="resetSearch">إلغاء</button>
                                </div>       
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="card shadow mb-4">
            <div class="card-header py-3">
                <h6 class="m-0 font-weight-bold text-primary">الشركات المشغلة</h6>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col text-right pb-3 pagination-div">
                        <a href="" class="d-inline-block mr-2" v-if="prevPage.length" @click.prevent="goToPrev"><i class="far fa-caret-square-right"></i></a>
                        <div class="d-inline-block rounded border border-secondary"> {{pageNumber}} </div>
                        <a href="" class="d-inline-block ml-2" v-if="nextPage" @click.prevent="goToNext"><i class="far fa-caret-square-left"></i></a>
                    </div>
                </div>
                <div class="table-responsive">
                    <div class="big-loader text-center pt-50 pb-50" v-if="loading">
                        <loader />
                    </div>
                    <table class="table table-bordered table-striped text-center " v-if="!loading" id="dataTable" width="100%" cellspacing="0">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>الاسم</th>
                                <th>نوع الشركة</th>
                                <th>الحالة</th>
                                <th>الرصيد</th>
                                <th>الرصيد الجديد</th>
                                <th>العمولة</th>
                                <th>كشف الحساب</th>
                                <th>كشف الحساب الجديد</th>
                                <th>العمليات</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(row,index) in rows" :key="index">
                                <td>{{(index + 1)}}</td>
                                <td>{{row.name}}</td>
                                <td>{{row.company_type}}</td>
                                <td>{{(row.active)? "نشط" : "غير نشط"}}</td>
                                <td class="ltr">{{row.balance}}</td>
                                <td>{{row.wallet}}</td>
                                <td>{{row.commission}}</td>
                                <td>
                                    <router-link :to="`/PaymentsC/${row.id}`" class="btn btn-light nowrap">
                                        <i class="fas fa-search-dollar"></i> كشف الحساب
                                    </router-link>
                                </td>
                                <td>
                                    <router-link :to="`/paymentHistory/${row.id}?type=company`" class="btn btn-light nowrap">
                                        <i class="fas fa-search-dollar"></i> كشف الحساب الجديد 
                                    </router-link>
                                </td>
                                <td>
                                    <router-link :to="'/CompaniesForm/'+row.id" class="btn btn-info">
                                        <i class="fas fa-edit"></i>
                                    </router-link>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

    export default {
        name:"operationCompanies",
        data(){
            return{
                loading: true,
                cities_loader: true,
                breadcrum: {
                    "/operationCompanies": "الرئيسية",
                    "#": "الشركات المشغلة",
                },
                active: "",
                company_type: "",
                name: "",
                commission:"",
                sortingBy: "",
                pageNumber: 1,
                nextPage: null,
                prevPage: [],
                rows: [],

                hasSearch: false,
                mainUrl: "/api/companies",
                endPoint: "/api/companies",
                services: [],
                orderStatus: {
                    draft: "Open",
                    sent: "Scheduled",
                    sale: "Live",
                    done: "Done",
                    cancel: "Cancelled",
                    hold: "Hold",
                    expired: "Expired",
                    unavailable: "Unavailable",
                    cancelled_by_user: "Canceled by user",
                    cancelled_by_provider: "Cancelled by provider",
                    reassign: "Reassign",
                },
                cities:[
                    {
                        id:"not",
                        text: "بحث بالمدينة",
                    }
                ]

            }
        },
        methods: {
            getData(){
                this.$http.get(this.endPoint).then(response => {
                    this.loading = false,
                    this.rows = response.data.rows;
                    this.nextPage = response.data.next;
                }).catch(err => {
                    this.$store.commit("SET_ERROR", err.data.error);
                });
            },
            goToNext(){
                this.prevPage.push(this.nextPage);
                this.endPoint = this.mainUrl +"?page" + this.nextPage;
                if(this.searchUrl !="")
                    this.endPoint = this.endPoint + "&" + this.searchUrl;
                this.loading = true;
                this.pageNumber = this.pageNumber + 1;
                this.getData();
            },
            goToPrev(){
                let prevIndex = this.prevPage.length -1;

                if(this.pageNumber == 2){
                    this.endPoint = this.mainUrl;
                    if(this.searchUrl != "")
                        this.endPoint = this.endPoint + "?" + this.searchUrl;
                }else{
               this.endPoint = this.mainUrl + "?page=" + this.prevPage[prevIndex];
                    if(this.searchUrl != "")
                        this.endPoint = this.endPoint + "&" + this.searchUrl;
                }

                this.$delete(this.prevPage, prevIndex);
                this.loading = true;
                this.pageNumber = this.pageNumber - 1;
                this.getData();
                if(this.pageNumber == 1){
                    this.prevPage = [];
                }
            },
            search(){
                this.searchUrl = "";
                let searchParam = [];
                if(this.company_type !=""){
                    searchParam.push("company_type="+this.company_type);
                }
                if(this.active.trim() != ""){
                    searchParam.push("active="+this.active.trim());
                }
                if(this.sortingBy !=""){
                    searchParam.push("sortingBy="+this.sortingBy);
                }
                if(searchParam.length == 0){
                    return;
                }
                this.searchUrl = searchParam.join("&");

                this.endPoint = this.mainUrl + "?" + searchParam.join("&");
                this.hasSearch = true;
                this.loading = true;
                this.pageNumber = 1;
                this.nextPage = null;
                this.prevPage = [];
                this.getData();
            },
            resetSearch(){
                this.company_type = "";
                this.active = "";
                this.sortingBy = "";
                this.searchUrl = "";
               
                this.endPoint = this.mainUrl;
                this.loading = true;
                this.hasSearch = false;
                this.getData();
            }
        },
        created(){
            window.scroll(0,0);
            this.$store.commit("SET_ACTIVE_MENU", "operationCompanies");
            this.$store.commit("SET_BREADCRUMB", this.breadcrum);
            this.$store.commit("SET_ERROR", "");
            this.$store.commit("SET_SUCCESS", "");
            this.$store.commit("SET_TITLE", "الشركات المشغلة");
            
            this.getData();
        }
    }
</script>