<template>
    <div>
        <div class="card-header py-3">
            <h6 class="m-0 font-weight-bold text-primary">الطلبات</h6>
        </div>
        <div class="card shadow mb-4">
            <div class="card-body">
                <div class="row">
                    <div class="col text-right pb-3 pagination-div">
                    <a href="" class="d-inline-block mr-2" v-if="prevPage.length" @click.prevent="goToPrev"><i class="far fa-caret-square-right"></i></a>
                    <div class="d-inline-block rounded border border-secondary">{{ pageNumber }}</div>
                    <a href="" class="d-inline-block ml-2" v-if="nextPage" @click.prevent="goToNext"><i class="far fa-caret-square-left"></i></a>
                    </div>
                </div>

                <div class="table-responsive">
                    <div class="big-loader text-center pt-50 pb-50" v-if="loading">
                        <loader />
                    </div>
                    <table v-if="!loading" class="table table-bordered table-striped text-center" id="dataTabel" width="100%" cellspacing="0">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>الخدمة</th>
                                <th>العميل</th>
                                <th>المزود</th>
                                <th>قيمةالخدمة</th>
                                <th>الاجمالي</th>
                                <th>الدفع</th>
                                <th>المدينة</th>
                                <th>الحالة</th>
                                <th>التاريخ</th>
                                <th>العمليات</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(row , index) in rows " :key="index">
                                <td>{{row.order_number}} </td>
                                <td> {{(services[row.main_services_code])? services[row.main_services_code] : row.services_name }}</td>
                                <td>{{row.user_name}} <br /> {{ row.user_mobile }}</td>
                                <td> {{ row.provider_name }} <br /> {{row.provider_mobile}} </td>
                                <td> {{row.service_cost }}</td>
                                <td> {{row.total_price }}</td>
                                <td> {{row.payment_method }}</td>
                                <td> {{ row.city }}</td>
                                <td>{{(orderStatus[row.status])? orderStatus[row.status] : row.status }} </td>
                                <td>{{row.order_time}}</td>
                                <td>
                                    <router-link :to="'/order/'+row.id+'/'+row.status" class="btn btn-info" >
                                        <i class="fas fa-edit"></i>
                                    </router-link>   
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="row">
                    <div class="col text-right pb-3 pagination-div">
                    <a href="" class="d-inline-block mr-2" v-if="prevPage.length" @click.prevent="goToPrev"><i class="far fa-caret-square-right"></i></a>
                    <div class="d-inline-block rounded border border-secondary">{{ pageNumber }}</div>
                    <a href="" class="d-inline-block ml-2" v-if="nextPage" @click.prevent="goToNext"><i class="far fa-caret-square-left"></i></a>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="exportModal" tabindex="-1" aria-labelledby="exportModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exportModalLabel">تصدير الطلبات</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form action="" @submit.prevent="exportOrders">
                        <div class="modal-body">
                            <div class="row">
                                <div class="col">
                                    <div class="alert alert-waring"> يرجي العلم ان التصدير يأخذ بعض الوقت بناء علي الفترة المحددة للتصدير </div>
                                </div>
                            </div>
                            <div class="row" v-if="exportError != ''">
                                <div class="col">
                                    <div class="alert alert-danger"> {{exportError}} </div>
                                </div>
                            </div>
                            <div class="row" v-if="exportDone">
                                <div class="col">
                                    <div class="alert alert-success"> تم تصدير الملف بنجاح </div>
                                </div>
                            </div>
                            <div class="col">
                                <select class="form-control" v-model="exportStatus" aria-placeholder="الحالة">
                                    <option value=""></option>
                                    <option value=""></option>
                                </select>
                                <div class="col">
                                    <select class="form-control" v-model="exportPayment" aria-placeholder="الدفع">
                                        <option value="all">الكل</option>
                                        <option value="Cash">Cash</option>
                                        <option value="online">Online Payments</option>
                                        <option value="Applepay">ApplePay</option>
                                        <option value="CreditCard">CreditCard</option>
                                        <option value="Wallet">Wallet</option>
                                        <option value="STCPay">STCPay</option>
                                        <option value="Credit">Credit</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
        import { mapGetters } from 'vuex';

        export default {
        name: "OrdersFliter",
        computed: {
            ...mapGetters (["getUserData"])
        },
        data(){
            return{
                status: this.$route.query.status,
                period: this.$route.query.period,
                loading: true,
                cities_loader: true,
                breadcrum:{
                    "/" : "الرئيسية",
                    "#" : "الطلبات"
                },
                rows:[],
                pageNumber: 1,
                nextPage: null,
                prevPage:[],
                hasSearch: false,
                mainUrl:"/api/orders",
                endPoint: "/api/orders",
                searchUrl: "",
                exportError: "",
                exportDone: "",
                exportStatus: "",
                exportPayment: "",
                services:[],
                orderStatus:{
                    draft: "open",
                    sent: "Scheduled",
                    sale: "Live",
                    approved: "Approved",
                    done : "Done",
                    cancel: "Cancelled",
                    hold: "Hold",
                    expired: "Expired",
                    Unavailable:"Unavailable",
                    cancelled_by_user: "Cancelled by user",
                    cancelled_by_provider: "Cancelled by provider",
                    reassign: "Reassign",
                },
                
            cities:[
                {
                    id: "not",
                    text: "بحث بالمدينة",
                }
            ],
        }
    },
        methods:{
            getCities(){
                this.$http.get("/api/Orders/cities").then( response => {
                    this.cities_loader = false;
                    this.cities = response.data.data;
                    this.services = response.data.services;
                }).catch(error => {
                    this.$store.commit("SET_ERROR" , error.data.error);
                });
            },

            getData(){
                this.$http.get(this.endPoint).then (response => {
                    this.loading= false;
                    this.rows = response.data.rows;
                    this.nextPage = response.data.next;
                }).catch(err => {
                    this.$store.commit("SET_ERROR" , err.data.error);
                });
            },

            goToNext(){
                this.prevPage.push(this.nextPage);
                this.endPoint = this.mainUrl + "?page=" + this.nextPage + "&status=" + this.status +"&period="+this.period;
                this.loading = true;
                this.pageNumber = this.pageNumber + 1;
                this.getData();
            },

            goToPrev(){
                let prevIndex = this.prevPage.length - 1;
                if(this.pageNumber == 2){
                    this.endPoint = this.mainUrl + "?status="+this.status + "&period="+this.period;
                    
                }else{
                    this.endPoint = this.mainUrl + "?page=" + this.prevPage[prevIndex]+ "&status="+this.status + "&period="+this.period;
                }
                this.$delete(this.prevPage, prevIndex);
                this.loading = true;
                this.pageNumber = this.pageNumber -1;
                this.getData();
                if(this.pageNumber==1){
                this.prevPage=[];
                }
            },

            
            exportOrders(){
                this.exportError = "";
                this.exportDone = false;
                this.exportLoading = true;
                if(this.exportStart == "" | this.exportEnd == ""){
                    this.exportError = " لابد من تحديد الفترة المراد تصديرها "
                    this.exportLoading = false;
                    return;
                }

                this.$http.post("/api/export/Orders/slice", {
                    "start" : this.getDataFormat(this.exportStart),
                    "end" : this.getDataFormat(this.exportEnd),
                    "status": this.exportStatus,
                    "payment" : this.exportPayment,
                }, {responseType: "blob"}).then ((response)=> {
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var filelink = document.createElement('a');

                    filelink.href = fileURL;
                    filelink.setAttribute('download', 'orders_export.xlsx');
                    document.body.appendChild(filelink);

                    filelink.click();
                    this.exportLoading= false;
                    this.exportDone = true;
                    }).catch(() => {
                    this.exportError = "غير قادر علي التصدير";
                    this.exportLoading = false;
                });
            },

            getDateFormat(dat){
                let date = new Date(dat);
                return date.getFullYear() + '-' +
                (date.getMonth () +1) + '-' +
                date.getDate();
            }
        },

            created(){
                window.scroll(0,0);
                this.$store.commit("SET_ACTIVE_MENU", "Orders_Fliter" );
                this.$store.commit("SET_BREADCRUMB" , this.breadcrum);
                this.$store.commit("SET_ERROR" , "");
                this.$store.commit("SET_SUCCESS" , "" );
                this.$store.commit("SET_TITLE", "الطلبات");
                
                this.endPoint = "/api/orders?status="+this.status+ "&period=" +this.period;

                this.getCities();
                this.getData();
            }       
        }
</script>

