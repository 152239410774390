<template>
  <div class="row justify-content-center">
    <div class="col-sm-6">
      <div class="card o-hidden border-0 shadow-lg my-5">
        <div class="card-body p-0">
          <!-- Nested Row within Card Body -->
          <div class="row">
            <div class="col">
              <div class="p-5">
                <div class="text-center">
                  <h1 class="h4 text-gray-900 mb-4">تسجيل الدخول</h1>
                </div>
                <form class="user" @submit.prevent="login()">
                  <div class="form-group" v-if="error != ''">
                    <div class="alert alert-danger">{{ error }}</div>
                  </div>
                  <div class="form-group">
                    <input
                      type="email"
                      class="form-control form-control-user"
                      id="exampleInputEmail"
                      aria-describedby="emailHelp"
                      placeholder="البريد الإلكتروني"
                      v-model="email"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="password"
                      class="form-control form-control-user"
                      id="exampleInputPassword"
                      placeholder="كلمة المرور"
                      v-model="password"
                    />
                  </div>
                  <button
                    type="submit"
                    class="btn btn-primary btn-user btn-block"
                  >
                    Login
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    name: "Login",
    data() {
      return {
        email: "",
        password: "",
        error: "",
      };
    },
    computed:{
      ...mapGetters(["isAuth"])
    },
    methods: {
      login(){
          this.$http.post("/api/auth/login", {
            "email": this.email,
            "password": this.password
          }).then((response) => {
            // this.$store.commit("CURRENT_USER_FETCHED", response.data.user);
            this.$store.dispatch("setLoginUser", response.data.user);
            window.location.href = '/';
          }).catch((err) => {
            this.error = err.data.error;
          });
      }
    },
    updated(){
      if(this.isAuth){
        window.location.href = '/';
      }
    }
  };
</script>