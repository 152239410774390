<template>
   <div>
      <div class="row" v-if="loading">
         <div class="col">
            <div class="big-loader text-center pt-50 pb-50">
               <loader />
            </div>
         </div>
      </div>

      <div class="row pb-5" v-if="!hasError&&(getUserData.is_admin||getUserData.can_edit)">
         <div class="col text-right">
            <button class="btn btn-info" @click="getfollow()" v-if="!followUser">متابعة الطلب </button> 
            <div v-else>جاري متابعة الطلب من قبل : {{followUser}}</div>
         </div>
      </div>

      <div class="row" v-if="!hasError">
         <div class="col pb-5">
            <div class="google-map" ref="googleMap" style="height: 300px;"></div>
         </div>
      </div>

      <div class="row" v-if="!hasError">
         <div class="col">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
               <li class="nav-item">
                  <a href="#home" class="nav-link active" id="home-tab" data-toggle="tab" role="tab" aria-controls="home" aria-selected="true">البيانات الاساسية</a>
               </li>
               <li class="nav-item">
                  <a href="#items" class="nav-link" id="items-tab" data-toggle="tab" role="tab" aria-controls="items" aria-selected="false">المنتجات</a>
               </li>
               <li class="nav-item">
                  <a class="nav-link" id="chat-tab" data-toggle="tab" href="#chat" role="tab" aria-controls="chat" aria-selected="false">المحادثات</a>
               </li>
               <li class="nav-item">
                  <a class="nav-link" id="dispatch-tab" data-toggle="tab" href="#dispatch" role="tab" aria-controls="dispatch" aria-selected="false">الإسناد</a>
               </li>
               <li class="nav-item">
                  <a class="nav-link" id="-logtab" data-toggle="tab" href="#loging" role="tab" aria-controls="loging" aria-selected="false">سجل العمليات</a>
               </li>
               <li class="nav-item">
                  <a class="nav-link" id="-support-tab" data-toggle="tab" href="#support" role="tab" aria-controls="support" aria-selected="false">طلبات تحتاج الي إسناد</a>
               </li>
            </ul>
            <div class="tab-content" id="myTabContent">
               <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                  <form action="" @submit.prevent="submit">
                     <table class="table table-striped">
                        <tbody>
                           <tr>
                              <th>رقم الطلب</th>
                              <td>{{ order.order_number }}</td>
                              <th>تاريخ الطلب</th>
                              <td class="date-content">{{ order.order_time }}</td>
                           </tr>
                           <tr>
                              <th>التصنيف</th>
                              <td>{{ order.main_category }}</td>
                              <th>الخدمة</th>
                              <td>
                                 <select class="form-control" v-model="formService">                                 
                                    <option v-for="(key ,value) in services" :key="value" :value="value">{{key}}</option>
                                 </select>
                              </td>
                           </tr>
                           <tr v-if="['044667234', '4453234', '04498876'].includes(order.main_service_code)">
                              <th>من مدينة </th>
                              <td>{{ order.towing_from_name }}</td>
                              <th>الي مدينة</th>
                              <td>{{ order.towing_to_name }}</td>
                           </tr>
                           <tr>
                              <th>المنطقة</th>
                              <td>{{ order.region }}</td>
                              <th>المدينة</th>
                              <td>{{ (order.city_name_en)? order.city_name_en : "لا يوجد" }}</td>
                           </tr>
                           <tr>
                              <th>العنوان</th>
                              <td>{{ order.locationFormattedAddress }}</td>
                              <th>طريقة تنفيذ الطلب</th>
                              <td>{{ order.order_start }}</td>
                           </tr>
                           <tr>
                              <th>العميل</th>
                              <td>{{ order.user_name }}</td>
                              <th>جوال العميل</th>
                              <td>{{ order.user_mobile }}</td>
                           </tr>
                           <tr>
                              <th>المزود</th>
                              <td>{{ order.provider_name }}</td>
                              <th>جوال المزود</th>
                              <td><input type="text" class="form-control ltr" v-model="formProviderNumber"></td>
                           </tr>
                           <tr>
                              <th>الحالة</th>
                              <td>
                                 <select class="form-control" v-model="formStatus" aria-placeholder="الحالة">
                                    <option value="">حالة الطلب</option>
                                    <option v-for="(key, value) in orderStatus" :key="value" :value="value">{{ key }}</option>
                                 </select>
                              </td>
                              <th>سبب الإلغاء</th>
                              <td>{{ order.reason_ar }}</td>
                           </tr>
                           <tr v-if="['61', '0096673', '03324556'].includes(order.main_service_code)">
                              <th>المسافة المقطوعة</th>
                              <td>{{ order.matrix_km }}</td>
                              <th>الوقت المستغرق</th>
                              <td>{{ order.matrix_minutes }}</td>
                           </tr>
                           <tr>
                              <td colspan="4"><hr></td>
                           </tr>
                           <tr>
                              <th>قيمة الخدمة بالنظام</th>
                              <td>{{ order.service_cost_init }} ريال</td>
                              <th>القيمة النهائية</th>
                              <td>{{ order.service_cost }} ريال</td>
                           </tr>
                           <tr>
                              <th>قيمة الضريبة</th>
                              <td>{{ order.taxed_amount }} ريال</td>
                              <th>قيمة الفاتورة</th>                           
                              <td> <input type="number" class="form-control" v-model="formCost">ريال</td>
                           </tr>
                           <tr>
                              <th>الاجمالي شامل الفاتورة والضريبة</th>
                              <td>{{ order.total_price }} ريال</td>
                              <th>طريقة الدفع</th>
                              <td>
                                 <select class="form-control" v-model="formPaymentMethod" aria-placeholder="طريقة الدفع">
                                    <option value="Cash">Cash</option>
                                    <option value="ApplePay">ApplePay</option>
                                    <option value="CreditCard">CreditCard</option>
                                    <option value="Wallet">Wallet</option>
                                    <option value="STCPay">STCPay</option>
                                    <option value="Credit">Credit</option>
                                    <option value="waiting_for_payment">Waiting for payment</option>
                                    <option value="rejected">Rejected</option>
                                    <option value="cancelled_for_payment">Cancelled_for_payment</option>
                                 </select>
                              </td>
                           </tr>
                           <tr>
                              <td colspan="4"><hr></td>
                           </tr>
                           <tr>
                              <th>كوبون الخصم</th>
                              <td>{{ order.coupon_code }}</td>
                              <th>قيمة الخصم</th>
                              <td>{{ (order.discount)? order.discount + " ريال" : "" }}</td>
                           </tr>
                           <tr>
                              <td colspan="4" class="text-center">
                                 <button type="submit" class="btn btn-success" v-if="!formLoader">تعديل</button>
                                 <div class="text-center pt-2 pb-2" v-if="formLoader">
                                    <inlineloader />
                                 </div>
                              </td>
                           </tr>
                        </tbody>
                     </table>
                  </form>
                  <div class="row">
                     <div class="col-12 col-sm-8">
                        <div class="card">
                              <div class="card-header">
                                 <th>المزودين المقترحين</th>
                              </div>
                              <div class="card-body">
                                 <div class="table-responsive">
                                    <table class="table table-striped">
                                       <thead>
                                          <tr>
                                             <th>الاسم</th>
                                             <th>الموبيل</th>
                                          </tr>
                                       </thead>
                                       <tbody>
                                          <tr v-for="(a , index) in arroundProviders" :key="index">
                                             <td>{{a.name}}</td>
                                             <td>{{a.mobile}}</td>
                                          </tr>
                                       </tbody>
                                    </table>
                                 </div>
                              </div>
                        </div>
                     </div>
                  </div>
               </div>

               <div class="tab-pane fade" id="items" role="tabpanel" aria-labelledby="items-tab">
                  <div class="row" v-if="deliveryPlaces">
                     <div class="col">
                        <div class="table-responsive" v-for="(place, index) in deliveryPlaces" :key="index">
                           <table class="table">
                              <tbody>
                                 <tr v-if="place.place">
                                    <th>المكان</th>
                                    <td>{{ place.place.name }}</td>
                                    <th>الحالة</th>
                                    <td>{{ place.state }}</td>
                                 </tr>
                                 <tr>
                                    <th colspan="4">الطلبات</th>
                                 </tr>
                                 <tr>
                                    <td></td>
                                    <td colspan="2">
                                       <table class="table table-bordered">
                                          <tbody>
                                             <tr>
                                                <th>الصنف</th>
                                                <th>الكمية</th>
                                                <th>السعر</th>
                                             </tr>
                                             <tr v-for="(item, index) in place.items" :key="index">
                                                <td>{{ item.name }}</td>
                                                <td>{{ item.quantity }}</td>
                                                <td>{{ (item.price)? `${item.price} ريال` : "" }}</td>
                                             </tr>
                                          </tbody>
                                       </table>
                                    </td>
                                    <td></td>
                                 </tr>
                                 <tr>
                                    <th>قيمة الفاتورة</th>
                                    <td>{{ place.invoice_amount }}</td>                              
                                    <th>الفاتورة</th>
                                    <td>
                                       <img :src="place.invoice_image" v-if="place.invoice_image" class="img-flud">
                                    </td>
                                 </tr>
                              </tbody>
                           </table>
                        </div>
                     </div>
                  </div>
               </div>

               <div class="tab-pane fade" id="chat" role="tabpanel" aria-labelledby="chat-tab">
                  <div class="row" v-if="chats">
                     <div class="col">
                        <div class="chat-container p-3">
                           <ul>
                              <li v-for="chat in chats" :key="chat.id" :class="chat.target">
                                 <div class="row pb-3">
                                    <div class="col-auto">
                                       <img src="/img/taxi-driver.png" v-if="chat.target == 'users'">
                                       <img src="/img/profile.png" v-else>
                                    </div>
                                    <div class="col">
                                       <div v-if="chat.messageType == 'USER_TEXT'">{{ chat.messageContent }}</div>
                                       <div v-else>{{ chat.messageContent }}</div>
                                       <small class="date-content">{{ chat.time }}</small>
                                    </div>
                                 </div>
                              </li>
                           </ul>
                        </div>
                     </div>
                  </div>
                  <div class="row justify-content-center">
                     <div class="col-12 col-sm-8" >
                        <form action="" @submit.prevent="chat()">
                           <label class="chat ">محادثة</label>
                           <div class="input-group mb-3 ">
                              <input type="text" class="form-control" v-model="message" >
                              <button type="submit" class="btn btn-success">إرسال</button>
                           </div>
                        </form>
                     </div>
                  </div>
               </div>

               <div class="tab-pane fade" id="dispatch" role="tabpanel" aria-labelledby="dispatch-tab">
                  <div class="row pb-2 pt-4" v-if="order.waiting_approval_time">
                     <div class="col text-center">الوقت المستغرق في الاسناد {{ order.waiting_approval_time / 60 }} دقيقة</div>
                  </div>
                  <div class="row">
                     <div class="col pt-4 pb-2">
                        <h3>عدد المزودين بناء على المسافة</h3>
                     </div>
                  </div>
                  <div class="row" v-if="dispatch_array">
                     <div class="col">
                        <div class="table-responsive">
                           <table class="table table-striped">
                              <tr>
                                 <th>المسافة</th>
                                 <th>عدد المزودين</th>
                              </tr>
                              <tr v-for="index in dispatch_array" :key="index">
                                 <td>{{ index }}</td>
                                 <td>{{ order.providers_count_km[index] }}</td>
                              </tr>
                           </table>
                        </div>
                     </div>
                  </div>
                  <div class="row pt-3">
                     <div class="col"><hr></div>
                  </div>
                  <div class="row">
                     <div class="col pt-4 pb-2">
                        <h3>بيان المزودين المرسل إليهم الطلب</h3>
                     </div>
                  </div>
                  <div class="row">
                     <div class="col">
                        <div class="table-responsive">
                           <table class="table table-striped">
                              <tr>
                                 <th>#</th>
                                 <th>الاسم</th>
                                 <th>الجوال</th>
                                 <th>المسافة</th>
                                 <th>الدرجة</th>
                                 <th>القبول</th>
                              </tr>
                              <tr v-for="(r, index) in suggested_providers" :key="index">
                                 <td>{{ index + 1 }}</td>
                                 <td>{{ r.name }}</td>
                                 <td>{{ r.mobile }}</td>
                                 <td>{{ r.distance }}</td>
                                 <td>{{ r.score }}</td>
                                 <td>{{ r.accepted }}</td>
                              </tr>
                           </table>
                        </div>
                     </div>
                  </div>
               </div>

               <div class="tab-pane fade" id="loging" role="tabpanel" aria-labelledby="loging">
                  <div class="row">
                     <div class="col">
                        <div class="card m-3 ">
                           <div class="card-body">
                              <div class="table-responsive">
                                 <table class="table table-striped table-hover">
                                    <thead>
                                       <tr>
                                          <th>التاريخ</th>
                                          <th>العنوان</th>
                                          <th>نوع العملية</th>
                                          <th>المستخدم</th>
                                          <th>البيانات</th>
                                       </tr>
                                    </thead>
                                       <tbody>
                                          <tr v-for="(val,index) in log" :key="index">
                                             <td>{{ val.created_at_string }}</td>
                                             <td>{{val.title}}</td>
                                             <td>{{val.action}}</td>
                                             <td>{{val.user_name}}</td>
                                             <td>
                                                <ul>
                                                   <li v-for="(d_val,d_index) in val.req_data" :key="d_index">
                                                   {{ d_index }} = {{ d_val }}
                                                   </li>
                                                </ul>
                                             </td>
                                          </tr> 
                                       </tbody>
                                 </table>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="tab-pane fade" id="support" role="tabpanel" aria-labelledby="support">
                  <div class="card shadow mb-4">
                     <div class="card-body">
                        <div class="row">
                           <div class="col text-right pb-3 pagination-div ">
                              <a href="" class="d-inline-block mr-2" v-if="prevPage.length" @click.prevent="goToPrev"><i class="far fa-caret-square-right"></i></a>
                              <div class="d-inline-block rounded border border-secondary">{{ pageNumber }}</div>
                              <a href="" class="d-inline-block ml-2" v-if="nextPage" @click.prevent="goToNext"><i class="far fa-caret-square-left"></i></a>
                           </div>
                        </div>
                        <div class="table-responsive">
                           <div class="row">
                              <div class="col">
                                    <div class="big-loader text-center pt-50 pb-50" v-if="loading">
                                       <loader />
                                    </div>
                                 <table v-if="!loading" class="table table-bordered table-striped text-center" id="dataTabel" width="100%" cellspacing="0">
                                    <thead>
                                       <tr>
                                          <th>#</th>
                                          <th>الخدمة</th>
                                          <th>العميل</th>
                                          <th>المزود</th>
                                          <th>قيمةالخدمة</th>
                                          <th>الاجمالي</th>
                                          <th>الدفع</th>
                                          <th>المدينة</th>
                                          <th>الحالة</th>
                                          <th>التاريخ</th>
                                          <th>العمليات</th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       <tr v-for="(row , index) in rows " :key="index">
                                          <td>{{row.order_number}} </td>
                                          <td> {{(services[row.main_services_code])? services[row.main_services_code] : row.services_name }}</td>
                                          <td>{{row.user_name}} <br /> {{ row.user_mobile }}</td>
                                          <td> {{ row.provider_name }} <br /> {{row.provider_mobile}} </td>
                                          <td> {{row.service_cost }}</td>
                                          <td> {{row.total_price }}</td>
                                          <td> {{row.payment_method }}</td>
                                          <td> {{ row.city }}</td>
                                          <td>{{(orderStatus[row.status])? orderStatus[row.status] : row.status }} </td>
                                          <td>{{row.order_time}}</td>
                                          <td>
                                                <router-link :to="'/orderEdit/'+ row.id" class="btn btn-info" @click.prevent="openForce('/orderEdit/'+ row.id)" >
                                                   <i class="fas fa-edit"></i>
                                                </router-link>   
                                          </td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>
                           </div>
                        </div>
                        <div class="row">
                           <div class="col text-right pt-3 pagination-div ">
                              <a href="" class="d-inline-block mr-2" v-if="prevPage.length" @click.prevent="goToPrev"><i class="far fa-caret-square-right"></i></a>
                              <div class="d-inline-block rounded border border-secondary">{{ pageNumber }}</div>
                              <a href="" class="d-inline-block ml-2" v-if="nextPage" @click.prevent="goToNext"><i class="far fa-caret-square-left"></i></a>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>   
         </div>
      </div>
   </div>
</template>

<script>
   import GoogleMapsApiLoader from 'google-maps-api-loader';
   import { mapGetters } from 'vuex';

   export default {
      name: "OrderEdit",
      computed: {
         ...mapGetters(["getUserData"])
      },
      data(){
         return {
            order_ref: this.$route.params.order_ref,
            loading: true,
            formLoader: false,
            hasError: false,
            order: [],
            log:[],
            rows:[],
            services: [],
            prevPage:[],
            mainUrl: "/api/orders?",
            endPoint: "/api/orders?",
            nextPage: null,
            pageNumber: 1,
            suggested_providers:[],
            dispatch_array: false,
            lastTrack: null,
            chats: null,
            message: "",
            deliveryPlaces: null,
            arroundProviders: [],
            followUser:"",
            breadcrum: {
               "/": "الرئيسية",
               "/allOrders": "الطلبات",
            },
            orderStatus: {
               draft: "Open",
               sent: "Scheduled",
               sale: "Live",
               approved: "Approved",
               done: "Done",
               cancel: "Cancelled",
               hold: "Hold",
               expired: "Expired",
               unavailable: "Unavailable",
               cancelled_by_user: "Canceled by user",
               cancelled_by_provider: "Cancelled by provider",
               reassign: "Reassign",
               waiting_for_payment: "Waiting for payment",
               rejected: "Rejected",
               cancelled_for_payment: "Cancelled_for_payment",
            },
            
            formProviderNumber: "",
            formStatus: "",
            formPaymentMethod: "Cash",
            formService: null,
            formCost: "",
            
            mapConfig: {
               zoom: 10,
               center: { lat: -25.363, lng: 131.044 }
            },
            mapApi: "AIzaSyCvp2KggXNAbN1zdNRSBHh3eNNXsEp1XEo",
            google: null,
            map: null,
            clientMarker: { lat: -25.363, lng: 131.044 },
            markersList: [],
            infoWindowList: [],
         }
      },
      methods: {
         getService(){
            this.$http.get("/api/orders/cities").then(response => {
               this.services = response.data.services;
            });
         },
         getData(){
            setTimeout(function(usr){
               if(!usr.is_admin && !usr.can_edit){
                  window.location.href = "/";
                  return;
               }
            }, 2000, this.getUserData);
            this.$http.get("/api/orders/get/"+ this.order_ref + "?providers=true").then(response => {
               this.loading = false;
               this.order = response.data.row;
               this.formService = this.order.main_service_code;
               this.formCost = this.order.items_cost
               this.log = response.data.logs;
               if(this.order.providers_count_km)
                  this.dispatch_array = Object.keys(this.order.providers_count_km).sort();
               this.mapConfig.center = {lat: this.order.latitude, lng: this.order.longitude};
               this.clientMarker = {lat: this.order.latitude, lng: this.order.longitude};
               this.lastTrack = response.data.lastTrack;
               this.suggested_providers = response.data.suggested_providers;
               this.chats = response.data.chats;
               this.deliveryPlaces = response.data.deliveryPlaces;
               this.arroundProviders = response.data.arroundProviders;
               if(this.order.provider_mobile){
                  this.formProviderNumber = this.order.provider_mobile;
               }
               this.formStatus = this.order.status;
               this.formPaymentMethod = this.order.payment_method;
               this.followUser = response.data.followUser;
               
               this.breadcrum[`/order/${this.order_ref}/draft`] = this.order.order_number;
               this.breadcrum["#"] = "تعديل";
               this.$store.commit("SET_BREADCRUMB", this.breadcrum);
               
               this.initializeMap();
            }).catch(error => {
               console.log(error);
               this.hasError = true;
               this.$store.commit("SET_ERROR", "لدينا خطأ فني");
            });
         },
         initializeMap() {
            const mapContainer = this.$refs.googleMap;
            this.map = new this.google.maps.Map(
               mapContainer, this.mapConfig
            );

            new this.google.maps.Marker({
               position: this.clientMarker,
               map: this.map,
               icon: "/img/man.png",
            });

            if(this.order.places){
               this.order.places.map(place => {
                  new this.google.maps.Marker({
                     position: {lat: place.location._latitude, lng: place.location._longitude},
                     map: this.map,
                     icon: "/img/cutlery.png",
                  });
               });
            }

            if(this.lastTrack){
               new this.google.maps.Marker({
                  position: {lat: this.lastTrack.latitude, lng: this.lastTrack.longitude},
                  map: this.map,
                  icon: "/img/location-pin.png",
               });
            }

            if(this.arroundProviders){
               this.arroundProviders.map(rProvider => {
                  this.markersList[rProvider.id] = new this.google.maps.Marker({
                     position: {lat: rProvider.latitude, lng: rProvider.longitude},
                     map: this.map,
                     icon: "/img/car-parking.png",
                  });

                  this.infoWindowList[rProvider.id] = new this.google.maps.InfoWindow({
                     content: `<div style="padding: 10px;">الاسم: ${rProvider.name} <br>الجوال: ${rProvider.mobile}</div>`
                  });

                  this.markersList[rProvider.id].addListener("click", () => {
                     this.infoWindowList[rProvider.id].open(this.map, this.markersList[rProvider.id]);
                  });
               });
            }
         },
         getExpire(){
            this.$http.get(this.endPoint + "status=followNeeds&order_ex=" + this.order_ref).then(response => {
               this.loading = false ;
               this.rows = response.data.rows;
               this.nextPage = response.data.next;
            });
         },
         goToNext(){
            this.prevPage.push(this.nextPage);
            this.loading= true;
            this.endPoint= this.mainUrl + "page=" + this.nextPage + "&";
            this.pageNumber = this.pageNumber + 1;
            this.getExpire();
         },
         goToPrev(){
            let prevIndex= this.prevPage.length -1;
            if(this.pageNumber== 2){
               this.endPoint= this.mainUrl;
            }else{
               this.endPoint= this.mainUrl + "page=" + this.prevPage[prevIndex]+ "&";
            }
            this.$delete(this.prevPage , prevIndex);
            this.loading = true;
            this.pageNumber = this.pageNumber -1 ;
            this.getExpire();
            if(this.pageNumber== 1) {
               this.prevPage= [];
            } 
         },
         submit(){
            this.formLoader = true;
            this.$store.commit("SET_ERROR", "");
            this.$store.commit("SET_SUCCESS", "");
            if(this.formService == ""){
               this.$store.commit("SET_ERROR", "يجب اختيار الخدمة ") 
               return;
            }
              
            this.$http.post("/api/orders/set/" + this.order_ref, {
               provider_mobile: this.formProviderNumber,
               status: this.formStatus,
               payment_method: this.formPaymentMethod,
               service_code: this.formService,
               items_cost: this.formCost
            }).then(response => {
               this.formLoader = false;
               if(response){
                  window.scrollTo(0, 0);
                  this.$store.commit("SET_SUCCESS", "تم التعديل بنجاح ، في حالة إسناد مزود إلى الطلب سيستغرق الامر حوالي دقيقة ليتم التنفيذ");
                  this.loading = true;
                  this.getData();
               }
            }).catch(err => {
               this.$store.commit("SET_ERROR", err.data.error);
               this.formLoader = false;
               window.scrollTo(0, 0);
            });
            // TODO: create page 
         },
         getfollow(){
            this.$http.get("/api/orders/followOrder/"+this.order_ref).then(response => {
               this.followUser = response.data.name;
            });
         },
         chat(){
            if(!this.message){
               return;
            }
            this.$http.post(" /api/orders/sendChat/" + this.order_ref, {
               message: this.message
            }).then(() => {
               this.message = "";
               this.getData();
            });

         },
         openForce(urlLink){
            window.location.href = urlLink;
         }
      }, 
      created(){
         window.scroll(0, 0);
         this.getService();
         this.$store.commit("SET_ERROR", "");
         this.$store.commit("SET_SUCCESS", "");
         this.$store.commit("SET_TITLE", "تعديل طلب");
         this.getData();
         this.getExpire();
      },
      async mounted() {
         const googleMapApi = await GoogleMapsApiLoader({
            apiKey: this.mapApi
         });
         this.google = googleMapApi;
      },
   }
</script>