<template>
    <div class="row">
        <div class="col">
            <div class="card shadow mb-4">
                <form action="" @submit.prevent="changePass">
                    <div class="card-body">
                        <div class="row justify-content-center">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="">كلمة المرور الحاليه</label>
                                    <input type="password" v-model="old_pass" class="form-control" placeholder="كلمة المرور الحاليه">
                                </div>
                                <div class="form-group">
                                    <label for="">كلمة المرور الجديده</label>
                                    <input type="password" v-model="new_pass" class="form-control" placeholder="كلمة المرور الجديده">
                                </div>
                                <div class="form-group">
                                    <label for="">تاكيد كلمة المرور</label>
                                    <input type="password" v-model="confirm_pass" class="form-control" placeholder="تاكيد كلمة المرور">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer text-center">
                        <button type="submit" class="btn btn-success">إرسال</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RestPass_Form',
    data(){
        return{
            old_pass: "",
            new_pass: "",
            confirm_pass: "",
            breadcrum: {
                "/": "الرئيسية",
                "#": "تغيير كلمة المرور",
            },
        };
    },
    methods:{
        changePass(){
            this.$http.post("/api/users/changePass" ,{
                "old_pass" : this.old_pass,
                "new_pass" : this.new_pass,
                "confirm_pass" : this.confirm_pass,
            }).then(res => {
                // console.log(res.data);
                this.$store.commit("SET_SUCCESS", res.data.message);
                this.$store.commit("SET_ERROR", "");
            }).catch(err => {
                // console.log(err);
                this.$store.commit("SET_SUCCESS", "");
                this.$store.commit("SET_ERROR", err.data.message);
            });
        }
    },
    created(){
        window.scroll(0,0);
        this.$store.commit("SET_BREADCRUMB", this.breadcrum);
        this.$store.commit("SET_ERROR", "");
        this.$store.commit("SET_SUCCESS", "");
        this.$store.commit("SET_TITLE", "تغيير كلمة المرور");
    }
}
</script>
