<template>
   <div>
      <div class="row" v-if="loading">
         <div class="col">
            <div class="big-loader text-center pt-50 pb-50">
               <loader />
            </div>
         </div>
      </div>

      <div class="row pb-5" v-if="!loading && !hasError && (getUserData.is_admin || getUserData.can_create)">
         <div class="col text-right">
            <router-link to="/packages/create" class="btn btn-light"><i class="fas fa-plus-circle"></i> إضافة باقة</router-link>
         </div>
      </div>

      <div class="row pb-5" v-if="!loading && !hasError">
         <div class="col">
            <div class="card shadow mb-4">
               <div class="card-body">
                  <div class="row">
                     <div class="col">
                        <datatable
                           title="الباقات"
                           :columns="tableColums"
                           :rows="rows"
                           :exportable="true"
                           v-if="rows.length"
                        >
                           <th slot="thead-tr">
                              العمليات
                           </th>
                           <template slot="tbody-tr" scope="props">
                              <td>
                                 <router-link :to="'/packages/view/' + props.row.id" class="btn btn-info btn-sm text-white"><i class="fas fa-eye"></i></router-link>
                                 <router-link :to="'/packages/' + props.row.id" class="btn btn-warning btn-sm text-white ml-2" v-if="getUserData.is_admin || getUserData.can_edit"><i class="fas fa-edit"></i></router-link>
                                 <!-- router-link :to="'/packages/delete/' + props.row.id" class="btn btn-danger btn-sm text-white ml-2"><i class="fas fa-trash-alt"></i></router-link -->
                              </td>
                           </template>
                        </datatable>
                        <div v-else class="alert alert-info">لا توجد بيانات</div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
   import DataTable from "vue-materialize-datatable";
   import { mapGetters } from 'vuex';
   export default {
      name: "Packages",
      components: {
         "datatable": DataTable
      },
      computed: {
         ...mapGetters(["getUserData"])
      },
      data(){
         return {
            loading: true,
            hasError: false,
            rows: [],
            tableColums: [
               {
                  label: "خاص بالتطبيق",
                  field: "app",
                  numeric: false,
                  html: false
               },
               {
                  label: "الاسم",
                  field: "name_ar",
                  numeric: false,
                  html: false
               },
               {
                  label: "القيمة",
                  field: "amount",
                  numeric: true,
                  html: false
               },
               {
                  label: "الحالة",
                  field: "status",
                  numeric: false,
                  html: false
               },
               {
                  label: "بتاريخ",
                  field: "created_at",
                  numeric: false,
                  html: false
               },
            ],
            breadcrum: {
               "/": "الرئيسية",
               "#": "الباقات",
            },
         }
      },
      methods: {
         getData(){
            this.$store.commit("SET_ERROR", "");
            this.$http.get("/api/packages").then(response => {
               this.loading = false;
               this.rows = response.data.rows;
            }).catch(error => {
               console.log(error);
               this.hasError = true;
               this.$store.commit("SET_ERROR", "لدينا خطأ فني");
            });
         },
      }, 
      created(){
         this.$store.commit("SET_ACTIVE_MENU", "packages");
         this.$store.commit("SET_BREADCRUMB", this.breadcrum);
         this.$store.commit("SET_ERROR", "");
         this.$store.commit("SET_SUCCESS", "");
         this.$store.commit("SET_TITLE", "الباقات");

         this.getData();
      },
   }
</script>