<template>
    <div>
        <div class="row pb-5" v-if="!hasError">
            <div class="col text-right">
                <router-link :to="`/users/CreatePaymentNew/create/`+this.user_ref+'?owner_type='+this.type" class="btn btn-warning" v-if="getUserData.is_admin || getUserData.can_create">
                    <i class="far fa-edit"></i>
                    إضافة سند قبض
                </router-link>
            </div>
        </div>

        <div class="card shadow mb-4">
            <div class="card-header py-3">
                <h6 class="m-0 font-weight-bold text-primary"> كشف حساب {{row.name}}</h6>
            </div>
            <div class="card-body">
                <div class="row mb-4 justify-content-between">
                    <div class="col">
                        <div class="card emad text-white shadow">
                            <div class="card-body text-center">
                                <strong class="mr-2">الرصيد القديم:</strong>{{( row.balance )? row.balance : 0}}
                            </div>
                        </div> 
                    </div>
                    <div v-if="type == 'provider'"  class="col">
                        <div class="card bg-primary text-white shadow">
                            <div class="card-body text-center">
                                <strong class="mr-2">رصيد المزود :</strong>{{(row.wallet_provider)? row.wallet_provider: 0}}
                            </div>
                        </div>
                    </div>
                    <div v-if="type == 'user'" class="col">
                        <div class="card bg-em text-white shadow">
                            <div class="card-body text-center">
                                <strong class="mr-2">رصيد العميل :</strong>{{(row.wallet_user)? row.wallet_user: 0}}
                            </div>
                        </div>
                    </div>
                    <div v-if="type =='provider'" class="col">
                        <div class="card bg-info text-white shadow">
                            <div class="card-body text-center">
                                <strong class="mr-2"> الرصيد في الشركة:</strong>{{(row.wallet_company)? row.wallet_company : 'لا يوجد'}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col text-right pb-3 pagination-div">
                        <a href="" class="d-inline-block mr-3" v-if="prevPage.length" @click.prevent="goToPrev">
                            <i class="far fa-caret-square-right"></i>
                        </a>
                        <div class="d-inline-block rounded border border-secondary">{{pageNumber}}</div>
                        <a href="" class="d-inline-block ml-3" v-if="nextPage" @click.prevent="goToNext">
                            <i class="far fa-caret-square-left"></i>
                        </a>
                    </div>
                </div>
                <div class="table-responsive">
                    <div class="big-loader text-center pt-50 pb-50" v-if="loading">
                        <loader />
                    </div>
                    <table v-if="!loading" class="table text-center table-bordered table-striped" id="dataTable" width="100%" cellspacing="0">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>رقم الطلب</th>
                                <th>التاريخ</th>
                                <th>البيان</th>
                                <th>القيمة</th>
                                <!-- th>دائن / مدين</th -->
                                <th>الرصيد قبل الطلب</th>
                                <th>الرصيد بعد العملية</th>
                                <th>طريقة الدفع</th>
                                <th>الرقم المرجعي</th>
                                <th>إضافةالرصيد</th>
                                <th>الحالة</th>
                                <th>التفاصيل</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(payment,index) in payments" :key="index">
                                <td v-if="pageNumber == 1">{{( index + 1)}}</td>
                                <td v-else>{{( index + 1 ) + ((pageNumber -1)*40)}}</td>
                                <td>{{payment.order_number}}</td>
                                <td>{{payment.created_at}}</td>
                                <td>{{payment.title}}</td>
                                <td>{{payment.amount}}</td>
                                <!-- td>{{payment.credit_debit}}</td -->
                                <td>{{payment.wallet_before}}</td>
                                <td>{{payment.wallet_after}}</td>
                                <td>{{payment.gateway}}</td>
                                <td>{{payment.reference}}</td>
                                <td>{{payment.wallet_recharge}}</td>
                                <td>{{payment.status}}</td>
                                <td>
                                    <router-link :to="'/paymentsDetails/'+payment.id" class="btn btn-info">
                                        <i class="fas fa-th"></i>
                                    </router-link>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row">
                    <div class="col text-right pb-3 pagination-div">
                        <a href="" class="d-inline-block mr-2" v-if="prevPage.length" @click.prevent="goToPrev"><i class="far fa-caret-square-right"></i></a>
                        <div class="d-inline-block rounded border border-secondary">{{ pageNumber }}</div>
                        <a href="" class="d-inline-block ml-2" v-if="nextPage" @click.prevent="goToNext"><i class="far fa-caret-square-left"></i></a>
                    </div>                    
                </div>
            </div>
        </div>
    </div>
</template>
<script>
     import { mapGetters } from 'vuex';
    export default{
        name:"paymentsNew",
        computed: {
         ...mapGetters(["getUserData"])
        },
        data(){
            return{
                user_ref: this.$route.params.user_ref,
                type: this.$route.query.type,
                loading: true,
                hasError: false,
                prevPage: [],
                pageNumber: 1,
                nextPage: null,
                endPoint: "/api/payments?owner_ref=" + this.$route.params.user_ref +"&collection=users&owner_type="+this.$route.query.type,
                mainUrl: "/api/payments?owner_ref=" +  this.$route.params.user_ref +"&collection=users&owner_type="+this.$route.query.type,
                row:{},
                payments:[],
                breadcrum: {
               "/": "الرئيسية",
               "/users": "عملاء ومزودين",
                },

            }
        },
        methods:{
            getData(){
                this.$store.commit("SET_ERROR", "");
                this.$http.get(this.endPoint).then(response => {
                this.loading = false,
                this.row = response.data.row;
                this.nextPage = response.data.next;                
                this.payments = response.data.payments;
                this.breadcrum["/users/"+this.user_ref] = this.row.name;
                this.breadcrum["#"] = "كشف الحساب";
                this.$store.commit("SET_BREADCRUMB", this.breadcrum);
                this.$store.commit("SET_TITLE", "كشف حساب : " + this.row.name);
                    
                }).catch(error => {
                    console.log(error);
                    this.hasError = true;
                    this.$store.commit("SET_ERROR", "لدينا خطأ فني");
                });
            },
            goToNext(){
                this.prevPage.push(this.nextPage);                
                this.endPoint = this.mainUrl + "&page=" + this.nextPage;
                this.pageNumber = this.pageNumber + 1;
                this.loading = true ;
                this.getData();
            },
            goToPrev(){
                let prevIndex = this.prevPage.length -1 ;
                if(this.pageNumber == 2){
                    this.endPoint = this.mainUrl;
                }else{
                    this.endPoint = this.mainUrl + "&page=" + this.prevPage[prevIndex];
                }
                this.$delete(this.prevPage , prevIndex);
                this.loading = true;
                this.pageNumber = this.pageNumber -1 ;
                this.getData();
            }
        },
        created(){
            window.scroll(0,0);
            this.$store.commit("SET_ERROR", "");
            this.$store.commit("SET_SUCCESS", "");

            this.getData();
        },
    }
</script>