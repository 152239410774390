<template>
    <div>
        <div class="row" v-if="loading">
            <div class="col">
                <div class="big-loader text-center pt-50 pb-50">
                    <loader />
                </div>
            </div>
        </div>

        <div class="row" v-if="!hasError && !loading">
            <div class="col">
                <div class="card shadow mb-4">
                    <form action="" @submit.prevent="submit">
                        <div class="card-body">
                            <div class="row justify-content-center">
                                <div class="col-sm-8">
                                    <div class="form-group">
                                        <label for="">الاسم</label>
                                        <input type="text" v-model="name" class="form-control" placeholder="الاسم">
                                    </div>
                                    <div class="form-group">
                                        <label for="">نوع الشركة </label>
                                        <input type="text" v-model="company_type" class="form-control" placeholder="نوع الشركة">
                                    </div>
                                    <div class="form-group">
                                        <label for="">العمولة</label>
                                        <input type="text" v-model="commission" class="form-control" placeholder="العمولة">
                                    </div>
                                    <div class="form-group">
                                        <label for="">الحالة</label>
                                        <select v-model="status" class="form-control">
                                            <option value="active">نشط</option>
                                            <option value="inactive">غير نشط</option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <label for="">الرصيد</label>
                                        <input type="text" v-model="balance" class="form-control" placeholder="الرصيد">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer text-center">
                            <button type="submit" class="btn btn-success" v-if="!formloader">إرسال</button>
                            <div class="text-center pt-2 pb-2" v-if="formloader">
                                <inlineloader />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import alertifyjs from 'alertifyjs';
    import { mapGetters } from 'vuex';
    export default{
        name:"CompaniesForm",
        computed:{
            ...mapGetters(["getUserData"])
        },
        data(){
            return{
                user_ref: this.$route.params.user_ref,
                loading: true,
                hasError: false,
                formloader: false,
                name: "",
                company_type: "",
                commission: "",
                balance: "",
                status: "",
                mainUrl: "/api/companies",
                endPoint: "/api/companies",
                breadcrum:{
                    "/operationCompanies" : "الرئيسية",
                    "/CompaniesForm" : "نموذج الشركات",
                },

            }
        },
        methods:{
            getData(){
                setTimeout(function(usr){
                    if(!usr.is_admin && !usr.can_edit){
                        window.location.href="/";
                        return;
                    }
                }, 2000, this.getUserData);
                this.$http.get("/api/companies/get/"+ this.user_ref).then(response => {
                    this.loading = false,
                    this.row = response.data.row;
                    this.providerRow = response.data.providerRow;
                    if(this.back){
                        this.breadcrum[`/compaines/${this.user_ref}?back=${this.back}`]=this.row.name;
                    }else{
                        this.breadcrum[`/compaines/${this.user_ref}`]= this.row.name;
                    }
                    this.breadcrum["#"] = "تعديل بيانات";
                    this.$store.commit("SET_BREADCRUMB", this.breadcrum);

                    this.name = response.data.row.name;
                    this.is_provider_approved = (this.row.is_provider_approved)? "approved" : "not_approved";
                    this.company_type = response.data.row.company_type;
                    this.commission = response.data.row.commission;
                    this.status = (this.row.active)? "active" : "inactive";
                    this.balance = response.data.row.balance; 
                }).catch(error => {
                    console.log(error);
                    this.hasError = true;
                    this.$store.commit("SET_ERROR","لدينا خطأ فني ");
                });
            },
            editCompanies(){
                this.formloading = true;
                this.$store.commit("SET_ERROR", "");

                this.$http.post("/api/companies/set/"+this.user_ref,{
                    name:this.name.trim(),
                    is_blocked: this.is_blocked,
                    is_provider_approved: this.is_provider_approved,
                }).then(response => {
                    this.formloading = false;
                    if(response.data && response.data.status == "OK"){
                        alertifyjs.success("تم التعديل بنجاح");
                        this.$route.back();
                    }else{
                        this.$store.commit("SET_ERROR", "لدينا خظأ فني");
                    }
                });
            }
        },
        created(){
            window.scroll(0,0);
            this.$store.commit("SET_ACTIVE_MENU", "companies");
            this.$store.commit("SET_BREADCRUMB", this.breadcrum);
            this.$store.commit("SET_ERROR", "");
            this.$store.commit("SET_SUCCESS", "");
            this.$store.commit("SET_TITLE", "نموذج الشركات");
            this.getData();
        },
    }
</script>