<template>
    <div>
        <div class="row mb-5">
            <div class="col">
                <div class="card border-left-warning shadow h-100 py-2">
                    <div class="card-body">
                        <form action="" @submit.prevent="search">
                            <div class="row">
                                <div class="col">
                                    <div class="row">
                                        <div class="col ltr">
                                           <datepicker v-model="searchStart" input-class="form-control" format="yyyy-MM-dd" placeholder="الي تاريخ"></datepicker>
                                        </div>
                                        <div class="col">
                                            <input v-model="mobile" type="text" class="form-control" placeholder="رقم الجوال">
                                        </div>
                                        <div class="col">
                                            <select v-model="searchType" class="form-control">
                                                <option value="user">العملاء</option>
                                                <option value="provider">المزودين</option>
                                                <option value="company">الشركات</option>
                                                <option value="">الكل</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-auto">
                                    <button type="submit" class="btn btn-success">
                                        <i class="fas fa-search"></i>
                                    </button>
                                    <button type="button" v-if="hasSearch" class="btn btn-light ml-2" @click.prevent="resetSearch">إالغاء</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mb-3" v-if="getUserData.is_admin || getUserData.can_export">
            <div class="col text-right">
                <button type="button" class="btn btn-light" data-toggle="modal" data-target="#exportModal">
                    <i class="far fa-file-excel mr-1"></i> التصدير
                </button>
            </div>
        </div>

        <div class="card shadow mb-4">
            <div class="card-header py-3">
                <h6 class="m-0 font-weight-bold text-primary">المعاملات المالية الجديدة</h6>
            </div>

            <div class="card-body">
                <div class="row">
                    <div class="col text-right pb-3 pagination-div">
                        <a href="" class="d-inline-block mr-2" v-if="prevPage.length" @click.prevent="goToPrev">
                            <i class="fas fa-caret-square-right"></i>
                        </a>
                        <div class="d-inline-block rounded border border-secondary">{{pageNumber}}</div>
                        <a href="" class="d-inline-block ml-2" v-if="nextPage" @click.prevent="goToNext">
                            <i class="fas fa-caret-square-left"></i>
                        </a>
                    </div>
                </div>
                <div class="table-responsive">
                    <div class="big-loader text-center pt-50 pb-50" v-if="loading">
                        <loader />
                    </div>
                    <table v-if="!loading" class="table table-bordered tabel-striped text-center" id="dataTable" width="100%" cellspacing="0">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>الاسم</th>
                                <th>رقم الطلب</th>
                                <th>التاريخ</th>
                                <th>البيان</th>
                                <th>القيمة</th>
                                <!-- th>دائن / مدين</th -->
                                <th>الرصيد قبل الطلب</th>
                                <th>الرصيد بعد العملية</th>
                                <th>طريقة الدفع</th>
                                <!-- th>الرقم المرجعي</th -->
                                <th>إضافةالرصيد</th>
                                <th>الحالة</th>
                                <th>التفاصيل</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(payment,index) in payments" :key="index">
                                <td v-if="pageNumber == 1">{{( index + 1)}}</td>
                                <td v-else>{{( index + 1 ) + ((pageNumber -1)*40)}}</td>
                                <td>{{payment.owner_name}}</td>
                                <td>{{payment.order_number}}</td>
                                <td>{{payment.created_at}}</td>
                                <td>{{payment.title}}</td>
                                <td>{{payment.amount}}</td>
                                <!-- td>{{payment.credit_debit}}</td -->
                                <td>{{payment.wallet_before}}</td>
                                <td>{{payment.wallet_after}}</td>
                                <td>{{payment.gateway}}</td>
                                <!-- td>{{payment.reference}}</td -->
                                <td>{{payment.wallet_recharge}}</td>
                                <td>{{payment.status}}</td>
                                <td>
                                    <router-link :to="'/paymentsDetails/'+payment.id" class="btn btn-info">
                                        <i class="fas fa-th"></i>
                                    </router-link>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="modal fade" id="exportModal" tabindex="-1" aria-labelledby="exportModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exportModalLabel">التصدير</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form action="" @submit.prevent="exportTransactions">
                        <div class="modal-body">
                            <div class="row">
                                <div class="col">
                                    <div class="alert alert-warning">يرجى العلم ان التصدير قد يأخذ بعض الوقت بناء على الفترة المحددة للتصدير</div>
                                </div>
                            </div>
                            <div class="row" v-if="exportError !=''">
                                <div class="col">
                                    <div class="alert alert-danger">{{ exportError }}</div>
                                </div>
                            </div>
                            <div class="row" v-if="exportDone">
                                <div class="col">
                                    <div class="alert alert-success">تم تصدير الملف بنجاح</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="row">
                                    <div class="col ltr">
                                        <datepicker v-model="exportEnd" input-class="form-control" format="yyyy-MM-dd" placeholder="الي تاريخ"></datepicker>
                                    </div>
                                    <div class="col">
                                        <input v-model="mobile" type="text" class="form-control" placeholder="رقم الجوال">
                                    </div>
                                    <div class="col">
                                        <select class="form-control" v-model="exportType" >
                                            <option value="user">العملاء</option>
                                            <option value="provider">المزودين</option>
                                            <option value="company">الشركات</option>
                                            <option value="">الكل</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <inlineloader v-if="exportLoading" />
                                <button type="submit" class="btn btn-primary" v-else>تصدير</button>
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">إغلاق</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Datepicker from "vuejs-datepicker";
    import { mapGetters } from "vuex";
    export default {
        name:"newTransactions",
        components: {
            Datepicker
        },
        computed:{
            ...mapGetters(["getUserData"])
        },
        data(){
            return{
                loading: true,
                cities_loader: true,
                breadcrum:{
                    "/" : "الرئيسية",
                    "#" : "المعاملات الماليةالجديدة",
                },
                searchStart: "",
                searchType:"",
                hasSearch: false,
                pageNumber: 1,
                prevPage: [],
                nextPage: null,
                payments:[],
                mainUrl: "/api/payments",
                endPoint: "/api/payments",
                searchUrl: "",
                exportError: "",
                exportEnd: "",
                exportDone: false,
                exportLoading :false,
                mobile: "", 
                exportType:"",
                
            }
        },
        methods:{
            getData(){
                this.$http.get(this.endPoint).then(response => {
                    this.loading = false;
                    this.payments = response.data.payments;
                    this.nextPage = response.data.next;
                }).catch(err => {
                    this.$store.commit("SET_ERROR", err.data.error)
                });
            },
            goToNext(){
                this.prevPage.push(this.nextPage);
                this.endPoint = this.mainUrl + "?page" + this.nextPage;
                if(this.searchUrl !="")
                    this.endPoint = this.endPoint + "&" + this.searchUrl;
                this.loading = true;
                this.pageNumber = this.pageNumber + 1;
                this.getData();
            },
            goToPrev(){
                let prevIndex = this.prevPage.lenght - 1;
                if(this.pageNumber == 2){
                    this.endPoint = this.mainUrl;
                    if(this.searchUrl !="")
                        this.endPoint = this.endPoint + "?" + this.searchUrl;
                }else{
                    this.endPoint = this.mainUrl + "?page" + this.prevPage[prevIndex];
                    if(this.searchUrl !="")
                        this.endPoint = this.endPoint + "&" + this.searchUrl;

                }
                this.$delete(this.prevPage, prevIndex);

                this.loading = true;
                this.pageNumber = this.pageNumber - 1;
                this.getData();
                if(this.pageNumber == 1){
                    this.prevPage = [];
                }
            },
            search(){
                this.searchUrl = "";
                let searchParam = [];

                if(this.searchStart !=""){
                    searchParam.push("date="+this.searchStart);
                }
                if(this.mobile.trim() !=""){
                    searchParam.push("mobile="+this.mobile.trim());
                }
                if(this.searchType != ""){
                    searchParam.push("owner_type="+(this.searchType));
                }
                if(searchParam.length == 0){
                    return;
                }
                this.searchUrl = searchParam.join("&");

                this.endPoint = this.mainUrl + "?" + searchParam.join("&");
                this.hasSearch = true;
                this.loading = true;
                this.pageNumber = 1;
                this.nextPage = null;
                this.prevPage = [];
                this.getData();

            },
            resetSearch(){
                this.searchStart = "";
                this.searchUrl = "";
                this.searchType = "";
                this.mobile = "";

                this.endPoint = this.mainUrl;
                this.loading = true;
                this.hasSearch = false;
                this.getData();
            },
            exportTransactions(){
                this.exportError = "";
                this.exportLoading = true;
                this.exportDone = false;
                if(this.exportEnd == ""){
                    this.exportError = "لابد من تحديد الفترة المراد تصديرها";
                    this.exportLoading = false;
                    return;
                }
                this.$http.post("/api/export/payments", {
                    "date" : this.getDataFormat(this.exportEnd),
                    "mobile" : this.mobile,
                    "owner_type" : this.exportType,
                },{responseType: "blob"}).then((response) => {
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fileLink = document.createElement('a');

                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', 'payment_history.xlsx');
                    document.body.appendChild(fileLink);

                    fileLink.click();
                    this.exportLoading = false;
                    this.exportDone = true;
                }).catch(() => {
                    this.exportError = "غير قادر على التصدير";
                    this.exportLoading = false;
                });
            },

            getDataFormat(dat){
                let date = new Date(dat);
                return date.getFullYear() + '-' + 
                (date.getMonth() +1) + '-' + 
                date.getDate();
            }
        },
        created(){
            window.scroll(0,0);
            this.$store.commit("SET_ACTIVE_MENU", "newTransactions");
            this.$store.commit("SET_BREADCRUMB", this.breadcrum);
            this.$store.commit("SET_ERROR", "");
            this.$store.commit("SET_SUCCESS", "");
            this.$store.commit("SET_TITLE", "المعاملات المالية");
            
            this.getData();
        }
    }
</script>