<template>
    <div>
        <div v-if="loading">
            <div class="big-loader text-center pt-50 pb-50" > 
                <loader />
            </div>
        </div>

        <div class="row">
            <div class="col">
                <div class="card bg-light text-black shadow">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12 col-sm-3">
                                <div class="input-group mb-3">
                                    <span class="input-group-text" id="basic-addon1">
                                        <i class="far fa-calendar-alt"></i>
                                    </span>
                                    <select class="form-control" v-model ="period" @change="getdash()" aria-describedby="basic-addon1">
                                        <option value="today"> Today </option>
                                        <option value="week">ThisWeek </option>
                                        <option value="month"> ThisMonth </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col">
                <div class="card">
                    <div class="card-header">
                        <h6 class="font-weight-bold">نسبة الطلبات المكتملة {{done_vale}}%</h6>
                    </div>
                    <div class="card-body">
                        <div class="progress">
                            <div class="progress-bar bg-info" role="progressbar" :style='"width:"+done_vale +"%"' :aria-valuenow="done_vale" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mb-4 mt-4 justify-content-between">
            <div class="col" >
                <router-link :to="'/OrdersFliter?status=draft&period='+period">
                    <div class="card emad text-white shadow">
                        <div class="card-body text-center">
                            Open Order
                            <div class="text-white-50 small">{{item.draft}}</div>
                        </div>
                    </div>
                </router-link>
            </div>
            <div class="col">
                <router-link :to="'/OrdersFliter?status=live&period='+period">
                    <div class="card bg-primary text-white shadow">
                        <div class="card-body text-center">
                            Live Order
                            <div class="text-white-50 small">{{item.live}}</div>
                        </div>
                    </div>
                </router-link>
            </div>
            <div class="col">
                <router-link :to="'/OrdersFliter?status=sent&period=' + period">
                    <div class="card bg-info text-white shadow">
                        <div class="card-body text-center">
                            Scheduled Order
                            <div class="text-white-50 small">{{item.sent}}</div>
                        </div>
                    </div>
                </router-link>
            </div>
            <div class="col">
                <router-link :to="'/OrdersFliter?status=expired&period=' + period">
                    <div class="card bg-danger text-white shadow">
                        <div class="card-body text-center">
                            Expired Order
                            <div class="text-white-50 small">{{item.expired}}</div>
                        </div>
                    </div>
                </router-link>
            </div>
            <div class="col">
                <router-link :to="'/OrdersFliter?status=unavailable&period='+period">
                    <div class="card bg-dark text-white shadow">
                        <div class="card-body text-center">
                            Unavailable Order
                            <div class="text-white-50 small">{{item.unavailable}}</div>
                        </div>
                    </div>
                </router-link>
            </div>
            <div class="col">
                <router-link :to="'/OrdersFliter?status=hold&period='+period">
                    <div class="card bg-primary text-white shadow">
                        <div class="card-body text-center">
                            Hold Order 
                            <div class="text-white-50 small">{{item.hold}}</div>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>

        <div class="row mb-4 mt-4 justify-content-between">
            <div class="col">
                <router-link :to="`/OrdersFliter?status=cancel&period=${period}`">
                    <div class="card bg-danger text-white shadow">
                        <div class="card-body text-center">
                            Canceled By Provider
                            <div class="text-white-50 small">{{item.cancelled_by_provider}}</div>
                        </div>
                    </div>
                </router-link>
            </div>
            <div class="col">
                <router-link :to="`/OrdersFliter?status=cancelled_by_user&period=${period}`">
                    <div class="card bg-danger text-white shadow">
                        <div class="card-body text-center">
                                Cancelled By User
                            <div class="text-white-50 small">{{item.cancelled_by_user}}</div>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
        <div class="row mb-4 mt-4 justify-content-between">
            <div class="col">
                    <div class="card bg-info text-white shadow">
                        <div class="card-body text-center">
                            Total Order
                            <div class="text-white-50 small">{{item.total}}</div>
                        </div>
                    </div>
            </div>
            <div class="col">
                <router-link v-bind:to="`/OrdersFliter?status=done&period=${period}`">
                    <div class="card bg-success text-white shadow" >
                        <div class="card-body text-center" >
                            Done Order
                            <div class="text-white-50 small">{{item.done}}</div>
                        </div>
                    </div>
                </router-link>
            </div>
            
        </div>
    </div>
</template>



<script>
    import Loader from '../../components/Loader.vue';
    export default{
    components: { Loader },
        name:'OrderdashBoared',
        data(){
            return{
            loading:true,
            done_vale: 0,
            cities_loader: true,
            period:"today",
            item:{
                cancelled_by_provider:"",
                cancelled_by_user:"",
                done:"",
                draft:"",
                expired:"",
                hold:"",
                live:"",
                sent:"",
                total:"",
                unavailable:"",
            },
            breadcrum: {  
            "/": "الرئيسية",
            "#": "ملخص الطلبات",
            },
            }
        },
        methods:{
            getdash(){
                this.loading= true;
                this.done_vale= 0,
                this.$http.get(`/api/dashboard/orders?period=${this.period}`).then(response => {
                    this.item = response.data;
                    this.loading = false;
                    let done_valeT=(this.item.done / this.item.total )*100;
                    this.done_vale = done_valeT.toFixed(2);
                });
            }
        },

        created(){
        window.scroll(0,0);
        this.$store.commit("SET_ACTIVE_MENU", "OrderdashBoared");
        this.$store.commit("SET_BREADCRUMB", this.breadcrum);
        this.$store.commit("SET_ERROR", "");
        this.$store.commit("SET_SUCCESS", "");
        this.$store.commit("SET_TITLE", "ملخص الطلبات");         
        this.getdash();
    }
}
</script>