<template>
   <div>
      <div class="row" v-if="loading">
         <div class="col">
            <div class="big-loader text-center pt-50 pb-50">
               <loader />
            </div>
         </div>
      </div>

      <div class="row pb-5" v-if="!hasError">
         <div class="col text-right">
            <router-link to="/managers/create" class="btn btn-warning"><i class="fas fa-plus"></i> إضافة مدير جديد</router-link>
         </div>
      </div>

      <div class="row" v-if="!hasError && !loading">
         <div class="col">
            <div class="card shadow mb-4">
               <div class="card-body">
                  <div class="row">
                     <div class="col">
                        <datatable
                           title="مديرين الموقع"
                           :columns="tableColums"
                           :rows="rows"
                           :exportable="false"
                           v-if="rows"
                        >
                           <th slot="thead-tr">
                              العمليات
                           </th>
                           <template slot="tbody-tr" scope="props">
                              <td>
                                 <router-link :to="`/managers/${props.row.id}`" class="btn btn-default">
                                    <i class="far fa-edit"></i> تعديل
                                 </router-link>
                              </td>
                           </template>
                        </datatable>
                        <div v-else class="alert alert-info">لا توجد بيانات</div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
   import DataTable from "vue-materialize-datatable";
   import { mapGetters } from 'vuex';

   export default {
      name: "Managers",
      components: {
         "datatable": DataTable
      },
      computed: {
         ...mapGetters(["getUserData"])
      },
      data(){
         return {
            loading: true,
            hasError: false,
            rows: null,
            tableColums: [
               {
                  label: "الاسم",
                  field: "name",
                  numeric: false,
                  html: false
               },
               {
                  label: "البريد الإلكتروني",
                  field: "email",
                  numeric: false,
                  html: false
               },
               {
                  label: "الحاله",
                  field: "status",
                  numeric: false,
                  html: false
               },
            ],
            breadcrum: {
               "/": "الرئيسية",
               "#": "مديرين الموقع",
            },
            userData: mapGetters(["getUserData"]),
         }
      },
      methods: {
         getData(){
            setTimeout(function(usr){
               if(!usr.is_admin){
                  window.location.href = "/";
               return;
               }
            },2000, this.getUserData)
            
            this.$http.get("/api/auth/list").then(response => {
               this.loading = false;
               this.rows = response.data.rows;
               this.status = response.data.rows.status;
            }).catch(error => {
               console.log(error);
               this.hasError = true;
               this.$store.commit("SET_ERROR", "لدينا خطأ فني");
            });
         },
      }, 
      created(){
         window.scroll(0,0);
         this.$store.commit("SET_ACTIVE_MENU", "managers");
         this.$store.commit("SET_ERROR", "");
         this.$store.commit("SET_SUCCESS", "");
         this.$store.commit("SET_BREADCRUMB", this.breadcrum);
         this.$store.commit("SET_TITLE", "مديرين الموقع");

         this.getData();
      },
   }
</script>