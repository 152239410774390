<template>
   <div>
      <div class="row" v-if="loading">
         <div class="col">
            <div class="big-loader text-center pt-50 pb-50">
               <loader />
            </div>
         </div>
      </div>

      <div class="row">
         <div class="col">
            <div class="alert alert-info">
               <p><strong>اجمالي المزوين :</strong> تمثل المزودين الذين لم يمر على آخر ظهور لهم اكثر من شهرين.</p>
               <p><strong>المزود المتاح لإستقبال الطلبات :</strong> هو المزود المتاح وليس لديه طلب الآن وآخر ظهور له لم يمر عليه اكثر من 15 دقيقة.</p>
            </div>
         </div>
      </div>

      <div class="row pb-4">
         <div class="col">
            <ul class="list-group list-group-flush">
               <li class="list-group-item" v-bind:class="{active: activeSelection == 'all'}" @click="showProviders('all')"><img src="/img/all.png" alt=""> جميع المزودين {{ providersCount }} مزود</li>
               <li class="list-group-item" v-bind:class="{active: activeSelection == 'active_providers'}" @click="showProviders('active_providers')"><img src="/img/sport-car_24.png" alt=""> المزود متاح لإستقبال الطلبات {{ activeProvidersCount }} مزود</li>
               <li class="list-group-item" v-bind:class="{active: activeSelection == 'ordered_providers'}" @click="showProviders('ordered_providers')"><img src="/img/car-parking.png" alt=""> المزود لديه طلب الآن {{ orderedProvidersCount }} مزود</li>
               <li class="list-group-item" v-bind:class="{active: activeSelection == 'inactive_providers'}" @click="showProviders('inactive_providers')"><img src="/img/sport-car_red_24.png" alt=""> المزود غير متاح لإستقبال الطلبات {{ inactiveProvidersCount }} مزود</li>
            </ul>
         </div>
         <div class="col">
            <div class="card border-left-success shadow py-2">
               <div class="card-body">
                  <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                           <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                              اجمالي عدد المزودين
                           </div>
                           <div class="h5 mb-0 font-weight-bold text-gray-800">{{ providersCount }}</div>
                        </div>
                        <div class="col-auto">
                           <i class="fas fa-user-tag fa-2x text-gray-300"></i>
                        </div>
                  </div>
               </div>
            </div>

            <!-- div class="row pt-4">
               <div class="col">
                  <div class="bg-warning text-center p-2" v-if="endOfStream == false">جاري تحميل البيانات ...</div>
                  <div class="bg-success text-center text-white p-2" v-else>تم تحميل البيانات</div>
               </div>
               <div class="col text-center">
                  <button class="btn btn-light" v-if="endOfStream == true" @click="setupStream()">
                     <i class="fas fa-sync"></i> إعادة تحميل البيانات
                  </button>
               </div>
            </div -->
         </div>
      </div>

      <div class="row">
         <div class="col pb-5">
            <div class="google-map" ref="googleMap" style="height: 600px;"></div>
         </div>
      </div>
   </div>
</template>

<script>
   import GoogleMapsApiLoader from 'google-maps-api-loader';

   export default {
      name: "AllProviders",
      data(){
         return {
            loading: false,
            hasError: false,
            lastTrack: null,
            chats: null,
            deliveryPlaces: null,
            url        : '/api/stream/providers',
            streamData : null,
            isConnected: false,
            breadcrum: {
               "/": "الرئيسية",
               "#": "عين الصقر",
            },
            
            mapConfig: {
               zoom: 7,
               center: { lat: 23.8859, lng: 45.0792 }
            },
            mapApi: "AIzaSyCvp2KggXNAbN1zdNRSBHh3eNNXsEp1XEo",
            google: null,
            map: null,
            providers: {},
            providersCount: 0,
            infoWindowList: {},
            myInterval: null,
            activeSelection: "all",
            activeProviders: {},
            orderedProviders: {},
            inactiveProviders: {},
            activeProvidersCount: 0,
            orderedProvidersCount: 0,
            inactiveProvidersCount: 0,
            endOfStream: false,
         }
      },
      methods: {
         setupStream(){
            this.endOfStream = false;
            this.clearMarkers();
            this.providers = {};
            this.activeProviders = {};
            this.orderedProviders = {};
            this.inactiveProviders = {};
            this.streamData = new EventSource(this.url + "?token=" + localStorage.getItem("ezUserToken"));

            this.streamData.addEventListener('message', event => {
               this.isConnected = true;
               let data = JSON.parse(event.data);

               if(data.endOfStream){
                  this.isConnected = false;
                  this.endOfStream = data.endOfStream;
                  this.streamData.close();
                  return true;
               }
               
               if(data.location != undefined && data.location != ""){
                  let markerImg = "/img/sport-car_red_24.png";
                  let currentStatus = "inactive";

                  if(data.current_order_id != undefined && data.current_order_id != ""){
                     markerImg = "/img/car-parking.png";
                     currentStatus = "ordered";
                  }else if(data.is_ready == undefined || data.is_ready == false){
                     markerImg = "/img/sport-car_red_24.png";
                     currentStatus = "inactive";
                  }

                  if(data.is_provider_active){
                     markerImg = "/img/sport-car_24.png";
                     currentStatus = "active";
                  }

                  if(this.providers[data.id] != undefined){
                     this.providers[data.id].setPosition({lat: data.location._latitude, lng: data.location._longitude});
                     this.providers[data.id].setIcon(markerImg);
                  }else{
                     this.providers[data.id] = new this.google.maps.Marker({
                        position: {lat: data.location._latitude, lng: data.location._longitude},
                        map: this.map,
                        icon: markerImg,
                     });
   
                     this.infoWindowList[data.id] = new this.google.maps.InfoWindow({
                        content: `<div style="padding: 10px;">الاسم: ${data.name} <br>الجوال: <a href="/users/${data.id}" target="_blank">${data.mobile}</a><br>التقييم: ${data.review_avg}<br>آخر ظهور: ${data.last_location_current}</div>`
                     });
   
                     this.providers[data.id].addListener("click", () => {
                        this.infoWindowList[data.id].open(this.map, this.providers[data.id]);
                     });

                     this.providersCount = Object.keys(this.providers).length;
                  }

                  switch (currentStatus) {
                     case "active":
                        if(this.activeProviders[data.id] != undefined){
                           this.activeProviders[data.id].setPosition({lat: data.location._latitude, lng: data.location._longitude});
                           this.activeProviders[data.id].setIcon(markerImg);
                        }else{
                           this.activeProviders[data.id] = new this.google.maps.Marker({
                              position: {lat: data.location._latitude, lng: data.location._longitude},
                              icon: markerImg,
                           });
                           this.activeProviders[data.id].addListener("click", () => {
                              this.infoWindowList[data.id].open(this.map, this.activeProviders[data.id]);
                           });
                           this.activeProvidersCount = Object.keys(this.activeProviders).length;
                        }
                        break;
                     
                     case "ordered":
                        if(this.orderedProviders[data.id] != undefined){
                           this.orderedProviders[data.id].setPosition({lat: data.location._latitude, lng: data.location._longitude});
                           this.orderedProviders[data.id].setIcon(markerImg);
                        }else{
                           this.orderedProviders[data.id] = new this.google.maps.Marker({
                              position: {lat: data.location._latitude, lng: data.location._longitude},
                              icon: markerImg,
                           });
                           this.orderedProviders[data.id].addListener("click", () => {
                              this.infoWindowList[data.id].open(this.map, this.orderedProviders[data.id]);
                           });
                        }
                        this.orderedProvidersCount = Object.keys(this.orderedProviders).length;
                        break;

                     case "inactive":
                        if(this.inactiveProviders[data.id] != undefined){
                           this.inactiveProviders[data.id].setPosition({lat: data.location._latitude, lng: data.location._longitude});
                           this.inactiveProviders[data.id].setIcon(markerImg);
                        }else{
                           this.inactiveProviders[data.id] = new this.google.maps.Marker({
                              position: {lat: data.location._latitude, lng: data.location._longitude},
                              icon: markerImg,
                           });
                           this.inactiveProviders[data.id].addListener("click", () => {
                              this.infoWindowList[data.id].open(this.map, this.inactiveProviders[data.id]);
                           });
                           this.inactiveProvidersCount = Object.keys(this.inactiveProviders).length;
                        }
                        break;
                  
                     default:
                        break;
                  }
               }
            }, false);

            this.streamData.addEventListener('error', event => {
               if(event.readyState == EventSource.CLOSED){
                  console.log('Event was closed');
                  console.log(EventSource);
               }
            }, false);
         },
         initializeMap(){
            const mapContainer = this.$refs.googleMap;
            this.map = new this.google.maps.Map(
               mapContainer, this.mapConfig
            );
            this.setupStream();
         },
         /* setProvidersInterval(){
            this.myInterval = setInterval(() => {
               if(this.isConnected){
                  this.streamData.close();
               }
               this.setupStream();
            }, 900000);
         }, */
         clearMarkers(){
            switch (this.activeSelection) {
               case "all":
                  Object.keys(this.providers).map(pro => {
                     this.providers[pro].setMap(null);
                  });
                  break;
               case "active_providers":
                  Object.keys(this.activeProviders).map(pro => {
                     this.activeProviders[pro].setMap(null);
                  });
                  break;
               case "ordered_providers":
                  Object.keys(this.orderedProviders).map(pro => {
                     this.orderedProviders[pro].setMap(null);
                  });
                  break;
               case "inactive_providers":
                  Object.keys(this.inactiveProviders).map(pro => {
                     this.inactiveProviders[pro].setMap(null);
                  });
                  break;
            
               default:
                  break;
            }
         },
         showProviders(show){
            this.clearMarkers();

            switch (show) {
               case "all":
                  Object.keys(this.providers).map(pro => {
                     this.providers[pro].setMap(this.map);
                  });
                  break;
               case "active_providers":
                  Object.keys(this.activeProviders).map(pro => {
                     this.activeProviders[pro].setMap(this.map);
                  });
                  break;
               case "ordered_providers":
                  Object.keys(this.orderedProviders).map(pro => {
                     this.orderedProviders[pro].setMap(this.map);
                  });
                  break;
               case "inactive_providers":
                  Object.keys(this.inactiveProviders).map(pro => {
                     this.inactiveProviders[pro].setMap(this.map);
                  });
                  break;
            
               default:
                  break;
            }
            this.activeSelection = show;
         }
      }, 
      created(){
         this.$store.commit("SET_ERROR", "");
         this.$store.commit("SET_SUCCESS", "");
         this.$store.commit("SET_BREADCRUMB", this.breadcrum);
         this.$store.commit("SET_TITLE", "عين الصقر");
      },
      async mounted() {
         const googleMapApi = await GoogleMapsApiLoader({
            apiKey: this.mapApi
         });
         this.google = googleMapApi;
         this.initializeMap();
         // this.setProvidersInterval();
      },
      beforeDestroy(){
         // clearInterval(this.myInterval);
         if(this.isConnected){
            this.streamData.close();
         }
      }
   }
</script>