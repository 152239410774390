<template>
   <div>
      <div class="row" v-if="loading">
         <div class="col">
            <div class="big-loader text-center pt-50 pb-50">
               <loader />
            </div>
         </div>
      </div>

      <div class="row pb-4">
         <div class="col">
            <div class="alert alert-info">إضافة خط تعني السعر بين مدينتين سواء ذهاب او عودة ، اي انك ان اضفت الرياض - الدمام لست بحاجة إلى إضافة الدمام - الرياض</div>
         </div>
      </div>

      <div class="row" v-if="!hasError && !loading">
         <div class="col">
            <div class="card shadow mb-4">
               <div class="card-body">
                  <div class="row">
                     <div class="col">
                        <form action="" @submit.prevent="lineSubmit">
                           <div class="row pb-4">
                              <div class="col">
                                 <label for="">بين مدينة</label>
                                 <select class="form-control" v-model="line_from" aria-placeholder="بين مدينة">
                                    <option value="">اختر المدينة</option>
                                    <option v-for="(key, value) in cities" :key="value" :value="key.id">{{ key.name_ar }}</option>
                                 </select>
                              </div>
                              <div class="col">
                                 <label for="">ومدينة</label>
                                 <select class="form-control" v-model="line_to" aria-placeholder="بين مدينة">
                                    <option value="">اختر المدينة</option>
                                    <option v-for="(key, value) in cities" :key="value" :value="key.id">{{ key.name_ar }}</option>
                                 </select>
                              </div>
                           </div>

                           <div class="row pb-4">
                              <div class="col">
                                 <label for="">السعر غير شامل الضريبة - سطحة عادية</label>
                                 <input type="number" v-model="price" class="form-control">
                              </div>
                              <div class="col">
                                 <label for="">السعر غير شامل الضريبة - سطحة هيدروليك</label>
                                 <input type="number" v-model="price_hydrick" class="form-control">
                              </div>
                           </div>
                           <div class="row pb-4">
                              <div class="col">
                                 <label for="">السعر غير شامل الضريبة - سطحة ونش</label>
                                 <input type="number" v-model="price_winch" class="form-control">
                              </div>
                           </div>

                           <div class="row">
                              <div class="col text-center">
                                 <button type="submit" class="btn btn-success" v-if="!formLoading">إرسال</button>
                                 <div class="text-center pt-2 pb-2" v-if="formLoading">
                                    <inlineloader />
                                 </div>
                              </div>
                           </div>
                        </form>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
   import alertifyjs from "alertifyjs";
   import { mapGetters } from 'vuex';
   export default {
      name: "linesForm",
      computed: {
         ...mapGetters(["getUserData"])
      },
      data(){
         return {
            line_ref: this.$route.params.line_ref,
            loading: true,
            formLoading: false,
            hasError: false,
            breadcrum: {
               "/": "الرئيسية",
               "/lines": "خطوط السطحات",
               "#": '',
            },

            line_from: "",
            line_to: "",
            price: 0,
            price_hydrick: 0,
            price_winch: 0,
            cities: [],
            name_from: '',
            name_to: '',
         }
      },
      methods: {
         getCities(){
            this.$http.get('/api/towing/cities').then(response => {
               this.cities = response.data.rows;
            }).catch(err => {
               this.$store.commit("SET_ERROR", err.data.error);
            });
         },
         getData(){
            if(this.line_ref != "create"){
               setTimeout(function(usr){
                  if(!usr.is_admin && !usr.can_edit){
                     window.location.href = "/";
                  return;
                  }
               }, 2000, this.getUserData);

               this.$http.get("/api/towing/lines/get/"+ this.line_ref).then(response => {
                  this.loading = false;
                  const row = response.data.row;

                  this.line_from = row.line_from;
                  this.line_to = row.line_to;
                  this.price = row.price;
                  this.price_hydrick = row.price_hydrick;
                  this.price_winch = row.price_winch;
                  this.name_from = row.name_from;
                  this.name_to = row.name_to;

                  this.breadcrum["#"] = `${this.name_from} - ${this.name_to}`;
                  this.$store.commit("SET_BREADCRUMB", this.breadcrum);
               }).catch(error => {
                  this.hasError = true;
                  if(error.data && error.data.error){
                     this.$store.commit("SET_ERROR", error.data.error);
                  }else{
                     this.$store.commit("SET_ERROR", "لدينا خطأ فني");
                  }
               });
            }else{
               setTimeout(function(usr){
                  if(!usr.is_admin && !usr.can_create){
                     window.location.href = "/";
                  return;
                  }
               }, 2000, this.getUserData);
               
               // if(!this.getUserData.is_admin && !this.getUserData.can_create){
               //    window.location.href = "/";
               //    return;
               // }
               this.loading = false;
            }
         },
         lineSubmit(){
            this.formLoading = true;
            this.$store.commit("SET_ERROR", "");

            if(this.line_from.trim() == ""){
               this.$store.commit("SET_ERROR", "يجب إدخال المدينة الاولى");
               this.formLoading = false;
               window.scroll(0, 0);
               return false;
            }
            if(this.line_to.trim() == ""){
               this.$store.commit("SET_ERROR", "يجب إدخال المدينة الثانية");
               this.formLoading = false;
               window.scroll(0, 0);
               return false;
            }

            this.$http.post("/api/towing/lines/set/"+ this.line_ref, {
               line_from: this.line_from,
               line_to: this.line_to,
               price: this.price,
               price_hydrick: this.price_hydrick,
               price_winch: this.price_winch,
            }).then(response => {
               this.formLoading = false;
               if(response.data && response.data.status == "OK"){
                  if(this.line_ref == "create"){
                     alertifyjs.success("تمت الإضافة بنجاح");
                  }else{
                     alertifyjs.success("تم التعديل بنجاح");
                  }
                  this.$router.replace("/lines");
               }else if(response.data && response.data.status == "ERROR"){
                  this.$store.commit("SET_ERROR", response.data.message);
               }else{
                  this.$store.commit("SET_ERROR", "لدينا خطأ فني");
               }
            }).catch(error => {
               this.formLoading = false;
               if(error.data && error.data.error){
                  this.$store.commit("SET_ERROR", error.data.error);
               }else{
                  this.$store.commit("SET_ERROR", "لدينا خطأ فني");
               }
            });
         }
      }, 
      created(){
         window.scroll(0, 0);
         this.getCities();
         this.$store.commit("SET_ERROR", "");
         this.$store.commit("SET_SUCCESS", "");

         if(this.line_ref != "create"){
            this.$store.commit("SET_TITLE", "تعديل بيانات");
         }else{
            this.$store.commit("SET_TITLE", "إضافة جديد");
            this.breadcrum["#"] = "إضافة جديد";
         }

         this.$store.commit("SET_BREADCRUMB", this.breadcrum);
         this.getData();
      },
   }
</script>