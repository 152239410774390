<template>
   <div>
      <div class="row" v-if="loading">
         <div class="col">
            <div class="big-loader text-center pt-50 pb-50">
               <loader />
            </div>
         </div>
      </div>

      <div class="row" v-if="!hasError && !loading">
         <div class="col">
            <div class="card shadow mb-4">
               <form action="" @submit.prevent="submit">
               <div class="card-body">
                  <div class="row justify-content-center">
                     <div class="col-sm-8">
                        <div class="form-group">
                           <label>رقم الطلب:</label>
                           <input type="text" class="form-control" v-model="order_number" placeholder="رقم الطلب">
                        </div>
                        <div class="form-group">
                           <label>المبلغ:</label>
                           <input type="text" class="form-control ltr" v-model="amount" placeholder="المبلغ">
                        </div>
                        <div class="form-group">
                           <label>وصف العملية: <small>سيظهر فى تطبيق المزود</small></label>
                           <input type="text" class="form-control" v-model="discription" placeholder="وصف العملية">
                        </div>
                     </div>
                  </div>
               </div>
               <div class="card-footer text-center">
                  <button type="submit" class="btn btn-success" v-if="!formLoader">إرسال</button>
                  <div class="text-center pt-2 pb-2" v-if="formLoader">
                     <inlineloader />
                  </div>
               </div>
               </form>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
   import { mapGetters } from 'vuex'; 

   export default {
      name: "CreatePayment",
      computed: {
         ...mapGetters(["getUserData"])
      },
      data(){
         return {
            user_ref: this.$route.params.user_ref,
            loading: false,
            hasError: false,
            formLoader: false,
            order_number: "",
            amount: 0,
            discription: "",
            breadcrum: {
               "/": "الرئيسية",
               "/users": "عملاء ومزودين",
               "#": "إضافة سند قبض",
            },
         }
      },
      methods: {
         submit(){
            this.$store.commit("SET_ERROR", "");
            this.$store.commit("SET_SUCCESS", "");

            if(this.amount == 0 || this.discription.trim() == ""){
               this.$store.commit("SET_ERROR", "يجب ادخال المبلغ ووصف العملية");
               return false;
            }

            this.formLoader = true;
            this.$http.post("/api/users/payments/create/" + this.user_ref, {
               order_number: this.order_number.trim(),
               amount: this.amount,
               discription: this.discription.trim(),
            }).then(() => {
               this.formLoader = false;
               this.$router.replace('/users/payments/' + this.user_ref);
            }).catch(error => {
               this.formLoader = false;
               this.$store.commit("SET_ERROR", error.data.error);
            });
         }
      }, 
      created(){
         window.scroll(0,0);
         this.$store.commit("SET_ERROR", "");
         this.$store.commit("SET_SUCCESS", "");
         this.$store.commit("SET_BREADCRUMB", this.breadcrum);
         this.$store.commit("SET_TITLE", "إضافة سند قبض");

         setTimeout(function (usr){
            if(!usr.is_admin && !usr.can_create){
               window.location.href = "/";
               return;
            }
         },2000, this.getUserData );
      },
   }
</script>