<template>
   <div>
      <div class="row" v-if="loading">
         <div class="col">
            <div class="big-loader text-center pt-50 pb-50">
               <loader />
            </div>
         </div>
      </div>

      <div class="row pb-5" v-if="!hasError && (getUserData.is_admin || getUserData.can_edit)">
         <div class="col text-right">
            <router-link :to="`/orderEdit/${order_ref}`" class="btn btn-warning"><i class="far fa-edit"></i> تعديل</router-link>
         </div>
      </div>

      <div class="row" v-if="!hasError">
         <div class="col pb-5">
            <div class="google-map" ref="googleMap" style="height: 300px;"></div>
         </div>
      </div>

      <div class="row" v-if="!hasError">
         <div class="col">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
               <li class="nav-item">
                  <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">البيانات الاساسية</a>
               </li>
               <li class="nav-item">
                  <a class="nav-link" id="items-tab" data-toggle="tab" href="#items" role="tab" aria-controls="items" aria-selected="false">المنتجات</a>
               </li>
               <li class="nav-item">
                  <a class="nav-link" id="chat-tab" data-toggle="tab" href="#chat" role="tab" aria-controls="chat" aria-selected="false">المحادثات</a>
               </li>
               <li class="nav-item">
                  <a class="nav-link" id="dispatch-tab" data-toggle="tab" href="#dispatch" role="tab" aria-controls="dispatch" aria-selected="false">الإسناد</a>
               </li>
               <li class="nav-item">
                  <a class="nav-link" id="-logtab" data-toggle="tab" href="#loging" role="tab" aria-controls="loging" aria-selected="false">سجل العمليات</a>
               </li>
            </ul>
            <div class="tab-content" id="myTabContent">
               <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                  <table class="table table-striped">
                     <tbody>
                        <tr>
                           <th>رقم الطلب</th>
                           <td>{{ order.order_number }}</td>
                           <th>تاريخ الطلب</th>
                           <td class="date-content">{{ order.order_time }}</td>
                        </tr>
                        <tr>
                           <th>التصنيف</th>
                           <td>{{ order.main_category }}</td>
                           <th>الخدمة</th>
                           <td>{{ order.service_name }}</td>
                        </tr>
                        <tr v-if="['044667234', '4453234', '04498876'].includes(order.main_service_code)">
                           <th>من مدينة </th>
                           <td>{{ order.towing_from_name }}</td>
                           <th>الي مدينة</th>
                           <td>{{ order.towing_to_name }}</td>
                        </tr>
                        <tr>
                           <th>المنطقة</th>
                           <td>{{ order.region }}</td>
                           <th>المدينة</th>
                           <td>{{(order.city_name_en)? order.city_name_en : "لايوجد"}}</td>
                        </tr>
                        <tr>
                           <th>العنوان</th>
                           <td>{{ order.locationFormattedAddress }}</td>
                           <th>طريقة تنفيذ الطلب</th>
                           <td>{{ order.order_start }}</td>
                        </tr>
                        <tr>
                           <th>العميل</th>
                           <td>{{ order.user_name }}</td>
                           <th>جوال العميل</th>
                           <td>{{ order.user_mobile }}</td>
                        </tr>
                        <tr>
                           <th>المزود</th>
                           <td>{{ order.provider_name }}</td>
                           <th>جوال المزود</th>
                           <td>{{ order.provider_mobile }}</td>
                        </tr>
                        <tr>
                           <th>الحالة</th>
                           <td>{{ order.status }}</td>
                           <th>سبب الإلغاء</th>
                           <td>{{ order.reason_ar }}</td>
                        </tr>
                        <tr v-if="['61', '0096673', '03324556' ].includes(order.main_service_code)">
                           <th>المسافة المقطوعة</th>
                           <td>{{ order.matrix_km }}</td>
                           <th>الوقت المستغرق</th>
                           <td>{{ order.matrix_minutes }}</td>
                        </tr>
                        <tr>
                           <td colspan="4"><hr></td>
                        </tr>
                        <tr>
                           <th>قيمة الخدمة بالنظام</th>
                           <td>{{ order.service_cost_init }} ريال</td>
                           <th>القيمة النهائية</th>
                           <td>{{ order.service_cost }} ريال</td>
                        </tr>
                        <tr>
                           <th>قيمة الضريبة</th>
                           <td>{{ order.taxed_amount }} ريال</td>
                           <th>قيمة الفاتورة</th>
                           <td>{{ order.items_cost }} ريال</td>
                        </tr>
                        <tr>
                           <th>الاجمالي شامل الفاتورة والضريبة</th>
                           <td>{{ order.total_price }} ريال</td>
                           <th>طريقة الدفع</th>
                           <td>{{ order.payment_method }}</td>
                        </tr>
                        <tr>
                           <td colspan="4"><hr></td>
                        </tr>
                        <tr>
                           <th>كوبون الخصم</th>
                           <td>{{ order.coupon_code }}</td>
                           <th>قيمة الخصم</th>
                           <td>{{ (order.discount)? order.discount + " ريال" : "" }}</td>
                        </tr>
                     </tbody>
                  </table>
               </div>

               <div class="tab-pane fade" id="items" role="tabpanel" aria-labelledby="items-tab">
                  <div class="row" v-if="deliveryPlaces">
                     <div class="col">
                        <div class="table-responsive" v-for="(place, index) in deliveryPlaces" :key="index">
                           <table class="table">
                              <tbody>
                                 <tr v-if="place.place">
                                    <th>المكان</th>
                                    <td>{{ place.place.name }}</td>
                                    <th>الحالة</th>
                                    <td>{{ place.state }}</td>
                                 </tr>
                                 <tr>
                                    <th colspan="4">الطلبات</th>
                                 </tr>
                                 <tr>
                                    <td></td>
                                    <td colspan="2">
                                       <table class="table table-bordered">
                                          <tbody>
                                             <tr>
                                                <th>الصنف</th>
                                                <th>الكمية</th>
                                                <th>السعر</th>
                                             </tr>
                                             <tr v-for="(item, index) in place.items" :key="index">
                                                <td>{{ item.name }}</td>
                                                <td>{{ item.quantity }}</td>
                                                <td>{{ (item.price)? `${item.price} ريال` : "" }}</td>
                                             </tr>
                                          </tbody>
                                       </table>
                                    </td>
                                    <td></td>
                                 </tr>
                                 <tr>
                                    <th>قيمة الفاتورة</th>
                                    <td>{{ place.invoice_amount }}</td>
                                    <th>الفاتورة</th>
                                    <td>
                                       <img :src="place.invoice_image" v-if="place.invoice_image" class="img-flud">
                                    </td>
                                 </tr>
                              </tbody>
                           </table>
                        </div>
                     </div>
                  </div>
               </div>

               <div class="tab-pane fade" id="chat" role="tabpanel" aria-labelledby="chat-tab">
                  <div class="row" v-if="chats">
                     <div class="col">
                        <div class="chat-container p-3">
                           <ul>
                              <li v-for="chat in chats" :key="chat.id" :class="chat.target">
                                 <div class="row pb-3">
                                    <div class="col-auto">
                                       <img src="/img/taxi-driver.png" v-if="chat.target == 'users'">
                                       <img src="/img/profile.png" v-else>
                                    </div>
                                    <div class="col">
                                       <div v-if="chat.messageType == 'USER_TEXT'">{{ chat.messageContent }}</div>
                                       <div v-else>{{ chat.messageContent }}</div>
                                       <small class="date-content">{{ chat.time }}</small>
                                    </div>
                                 </div>
                              </li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>

               <div class="tab-pane fade" id="dispatch" role="tabpanel" aria-labelledby="dispatch-tab">
                  <div class="row pb-2 pt-4" v-if="order.waiting_approval_time">
                     <div class="col text-center">الوقت المستغرق في الاسناد {{ order.waiting_approval_time / 60 }} دقيقة</div>
                  </div>

                  <div class="row">
                     <div class="col pt-4 pb-2">
                        <h3>عدد المزودين بناء على المسافة</h3>
                     </div>
                  </div>
                  <div class="row" v-if="dispatch_array">
                     <div class="col">
                        <div class="table-responsive">
                           <table class="table table-striped">
                              <tr>
                                 <th>المسافة</th>
                                 <th>عدد المزودين</th>
                              </tr>
                              <tr v-for="index in dispatch_array" :key="index">
                                 <td>{{ index }}</td>
                                 <td>{{ order.providers_count_km[index] }}</td>
                              </tr>
                           </table>
                        </div>
                     </div>
                  </div>
                  <div class="row pt-3">
                     <div class="col"><hr></div>
                  </div>

                  <div class="row">
                     <div class="col pt-4 pb-2">
                        <h3>بيان المزودين المرسل إليهم الطلب</h3>
                     </div>
                  </div>

                  <div class="row">
                     <div class="col">
                        <div class="table-responsive">
                           <table class="table table-striped">
                              <tr>
                                 <th>#</th>
                                 <th>الاسم</th>
                                 <th>الجوال</th>
                                 <th>المسافة</th>
                                 <th>الدرجة</th>
                                 <th>القبول</th>
                              </tr>
                              <tr v-for="(r, index) in suggested_providers" :key="index">
                                 <td>{{ index + 1 }}</td>
                                 <td>{{ r.name }}</td>
                                 <td>{{ r.mobile }}</td>
                                 <td>{{ r.distance }}</td>
                                 <td>{{ r.score }}</td>
                                 <td>{{ r.accepted }}</td>
                              </tr>
                           </table>
                        </div>
                     </div>
                  </div>
               </div>

               <div class="tab-pane fade" id="loging" role="tabpanel" aria-labelledby="loging">
                  <div class="row">
                     <div class="col">
                        <div class="card m-3 ">
                           <div class="card-body">
                              <div class="table-responsive">
                                 <table class="table table-striped table-hover">
                                    <thead>
                                       <tr>
                                          <th>التاريخ</th>
                                          <th>العنوان</th>
                                          <th>نوع العملية</th>
                                          <th>المستخدم</th>
                                          <th>البيانات</th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       <tr v-for="(val,index) in log" :key="index">
                                          <td>{{ val.created_at_string }}</td>
                                          <td>{{val.title}}</td>
                                          <td>{{val.action}}</td>
                                          <td>{{val.user_name}}</td>
                                          <td>
                                             <ul>
                                                <li v-for="(d_val,d_index) in val.req_data" :key="d_index">
                                                {{ d_index }} = {{ d_val }}
                                                </li>
                                             </ul>
                                          </td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
   import GoogleMapsApiLoader from 'google-maps-api-loader';
   import { mapGetters } from 'vuex';

   export default {
      name: "Order",
      computed: {
         ...mapGetters(["getUserData"])
      },
      data(){
         return {
            order_ref: this.$route.params.order_ref,
            order_status: this.$route.params.order_status,
            loading: true,
            hasError: false,
            order: [],
            log:[],
            dispatch_array: false,
            suggested_providers: [],
            lastTrack: null,
            chats: null,
            deliveryPlaces: null,
            url        : '/api/stream/order/',
            streamData : null,
            isConnected: false,
            breadcrum: {
               "/": "الرئيسية",
               "/allOrders": "الطلبات",
            },
            
            mapConfig: {
               zoom: 10,
               center: { lat: -25.363, lng: 131.044 }
            },
            mapApi: "AIzaSyCvp2KggXNAbN1zdNRSBHh3eNNXsEp1XEo",
            google: null,
            map: null,
            clientMarker: { lat: -25.363, lng: 131.044 },
         }
      },
      methods: {
         setupStream(){
            let stream_status_arr = ["draft", "sent", "sale", "hold", "approved"];

            this.$http.get("/api/orders/get/"+ this.order_ref).then(response => {
               this.loading = false;
               this.order = response.data.row;
               this.log = response.data.logs;
               if(this.order.providers_count_km)
                  this.dispatch_array = Object.keys(this.order.providers_count_km).sort();
               this.suggested_providers = response.data.suggested_providers;
               this.mapConfig.center = {lat: this.order.latitude, lng: this.order.longitude};
               this.clientMarker = {lat: this.order.latitude, lng: this.order.longitude};
               this.lastTrack = response.data.lastTrack;
               this.chats = response.data.chats;
               this.deliveryPlaces = response.data.deliveryPlaces;
               this.breadcrum["#"] = this.order.order_number;
               this.$store.commit("SET_BREADCRUMB", this.breadcrum);
               this.$store.commit("SET_TITLE", "الطلب: " + this.order.order_number);
               
               this.initializeMap();
            }).catch(error => {
               console.log(error);
               this.hasError = true;
               this.$store.commit("SET_ERROR", "لدينا خطأ فني");
            });

            if(stream_status_arr.indexOf(this.order_status) != -1){
               this.streamData = new EventSource(this.url + this.order_ref + "?token=" + localStorage.getItem("ezUserToken"));
   
               this.streamData.addEventListener('message', event => {
                  this.isConnected = true;
                  this.loading = false;
                  let data = JSON.parse(event.data);
                  this.order = data;
               }, false);
   
               this.streamData.addEventListener('error', event => {
                  if(event.readyState == EventSource.CLOSED){
                     console.log('Event was closed');
                     console.log(EventSource);
                  }
               }, false);
            }
         },
         initializeMap() {
            const mapContainer = this.$refs.googleMap;
            this.map = new this.google.maps.Map(
               mapContainer, this.mapConfig
            );

            new this.google.maps.Marker({
               position: this.clientMarker,
               map: this.map,
               icon: "/img/man.png",
            });

            if(this.order.places){
               this.order.places.map(place => {
                  new this.google.maps.Marker({
                     position: {lat: place.location._latitude, lng: place.location._longitude},
                     map: this.map,
                     icon: "/img/cutlery.png",
                  });
               });
            }

            if(this.lastTrack){
               new this.google.maps.Marker({
                  position: {lat: this.lastTrack.latitude, lng: this.lastTrack.longitude},
                  map: this.map,
                  icon: "/img/location-pin.png",
               });
            }
         }
      }, 
      created(){
         window.scroll(0, 0);
         this.$store.commit("SET_ERROR", "");
         this.$store.commit("SET_SUCCESS", "");

         this.setupStream();
      },
      async mounted() {
         const googleMapApi = await GoogleMapsApiLoader({
            apiKey: this.mapApi
         });
         this.google = googleMapApi;
      },
      beforeDestroy(){
         if(this.isConnected){
            this.streamData.close();
         }
      }
   }
</script>