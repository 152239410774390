<template>
   <div>
      <div class="row" v-if="loading">
         <div class="col">
            <div class="big-loader text-center pt-50 pb-50">
               <loader />
            </div>
         </div>
      </div>

      <div class="row" v-if="!hasError && !loading">
         <div class="col">
            <div class="card shadow mb-4">
               <div class="card-body">
                  <div class="row">
                     <div class="col">
                        <form action="" @submit.prevent="packageSubmit">
                           <div class="row pb-4">
                              <div class="col">
                                 <label for="">خاص بتطبيق</label>
                                 <select v-model="app" class="form-control">
                                    <option value="customer">العميل</option>
                                    <option value="provider">المزود</option>
                                 </select>
                              </div>
                              <div class="col">
                                 <label for="">الحالة</label>
                                 <select v-model="status" class="form-control">
                                    <option value="active">مفعل</option>
                                    <option value="inactive">غير مفعل</option>
                                 </select>
                              </div>
                           </div>

                           <div class="row pb-4">
                              <div class="col">
                                 <label for="">الاسم - عربي</label>
                                 <input type="text" v-model="name_ar" class="form-control">
                              </div>
                              <div class="col">
                                 <label for="">الاسم - انجليزي</label>
                                 <input type="text" v-model="name_en" class="form-control">
                              </div>
                           </div>

                           <div class="row pb-4">
                              <div class="col">
                                 <label for="">الوصف - عربي</label>
                                 <textarea v-model="description_ar" rows="3" class="form-control"></textarea>
                              </div>
                              <div class="col">
                                 <label for="">الوصف - انجليزي</label>
                                 <textarea v-model="description_en" rows="3" class="form-control"></textarea>
                              </div>
                           </div>

                           <div class="row pb-4">
                              <div class="col">
                                 <label for="">قابلة للشراء</label>
                                 <select v-model="available_to_buy" class="form-control">
                                    <option value="yes">نعم ، يظهر فى التطبيق</option>
                                    <option value="no">لا ، لا يظهر فى التطبيق</option>
                                    <option value="bank_card">تفعيل تلقائي عند استخدام بطاقة بنكية</option>
                                    <option value="stc_cashback">STC Cash back</option>
                                 </select>
                                 <input type="text" v-model="bank_name" v-if="available_to_buy == 'bank_card'" placeholder="اسم البنك" class="form-control mt-3">
                              </div>
                              <div class="col">
                                 <label for="">مدة فعالية الباقة</label>
                                 <div class="row">
                                    <div class="col">
                                       <input type="number" v-model="activation_period_number" class="form-control">
                                    </div>
                                    <div class="col">
                                       <select v-model="activation_period_type" class="form-control">
                                          <option value="year">سنة</option>
                                          <option value="month">شهر</option>
                                          <option value="day">يوم</option>
                                       </select>
                                    </div>
                                 </div>
                                 <p>خاصة بباقات العملاء فقط وليس خصومات البنك او stc</p>
                              </div>
                           </div>

                           <div class="row pb-4">
                              <div class="col">
                                 <label for="">ملاحظات داخليه لا تظهر في التطبيق</label>
                                 <textarea v-model="notice" rows="3" class="form-control"></textarea>
                              </div>
                           </div>

                           <div class="row pb-4">
                              <div class="col">
                                 <label for="">سعر الشراء</label>
                                 <input type="number" v-model="amount" class="form-control">
                              </div>
                              <div class="col" v-if="app == 'provider'">
                                 <label for="">يتم شحن محظفة المزود بمبلغ</label>
                                 <input type="number" v-model="pay_credit" class="form-control">
                              </div>
                              <div class="col pt-4" v-if="app == 'customer'">
                                 <div class="form-check">
                                    <input type="checkbox" class="form-check-input" id="cs-input" v-model="custom_services">
                                    <label class="form-check-label" for="cs-input">تخصيص بعض الخدمات</label>
                                 </div>
                              </div>
                           </div>

                           <div class="row pb-4" v-if="custom_services && app == 'customer'">
                              <div class="col">
                                 <hr>
                              </div>
                           </div>

                           <div class="row pb-4" v-if="!custom_services && app == 'customer' && available_to_buy == 'bank_card'">
                              <div class="col-12">
                                 <div class="alert alert-warning">إن لم تقم بتخصيص خدمات سيتم تطبيق الخصم على جميع الخدمات</div>
                              </div>
                              <div class="col-12">
                                 <table class="table table-striped">
                                    <thead>
                                       <th>نوع الخصم</th>
                                       <th>قيمة الخصم</th>
                                       <th>الحد الاقصى</th>
                                       <th>مرات الاستخدام</th>
                                    </thead>
                                    <tbody>
                                       <tr>
                                          <td>
                                             <select v-model="g_discount_type" class="form-control">
                                                <option value="percent">نسبة مئوية</option>
                                                <option value="fixed">قيمة ثابتة</option>
                                             </select>
                                          </td>
                                          <td>
                                             <input type="number" v-model="g_discount_value" class="form-control">
                                          </td>
                                          <td>
                                             <input type="number" v-model="g_max_value" class="form-control">
                                          </td>
                                          <td>
                                             <input type="number" v-model="g_using_count" class="form-control">
                                          </td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>
                           </div>

                           <div class="row pb-4" v-if="custom_services && app == 'customer'">
                              <div class="col text-right">
                                 <button type="button" class="btn btn-light" data-toggle="modal" data-target="#customServiceModal"><i class="fas fa-plus"></i> تخصيص خدمة</button>
                              </div>
                           </div>

                           <div class="row pb-4" v-if="custom_services && app == 'customer'">
                              <div class="col">
                                 <table class="table table-striped">
                                    <thead>
                                       <th>#</th>
                                       <th>الخدمة</th>
                                       <th>نوع الخصم</th>
                                       <th>قيمة الخصم</th>
                                       <th>الحد الاقصى</th>
                                       <th>مرات الاستخدام</th>
                                       <th>حذف</th>
                                    </thead>
                                    <tbody>
                                       <tr v-for="(item, index) in services" :key="index">
                                          <td>{{ (index + 1) }}</td>
                                          <td>{{ item.name }}</td>
                                          <td>
                                             <select v-model="item.discount_type" class="form-control">
                                                <option value="percent">نسبة مئوية</option>
                                                <option value="fixed">قيمة ثابتة</option>
                                             </select>
                                          </td>
                                          <td>
                                             <input type="number" v-model="item.discount_value" class="form-control">
                                          </td>
                                          <td>
                                             <input type="number" v-model="item.max_value" class="form-control">
                                          </td>
                                          <td>
                                             <input type="number" v-model="item.using_count" class="form-control">
                                          </td>
                                          <td>
                                             <button class="btn btn-danger" @click.prevent="removeService(index)"><i class="far fa-trash-alt"></i></button>
                                          </td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>
                           </div>

                           <div class="row">
                              <div class="col text-center">
                                 <button type="submit" class="btn btn-success" v-if="!formLoading">إرسال</button>
                                 <div class="text-center pt-2 pb-2" v-if="formLoading">
                                    <inlineloader />
                                 </div>
                              </div>
                           </div>
                        </form>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="modal fade" id="customServiceModal" tabindex="-1" role="dialog" aria-labelledby="customServiceModalLabel" aria-hidden="true">
         <div class="modal-dialog" role="document">
            <div class="modal-content">
               <div class="modal-header">
               <h5 class="modal-title" id="customServiceModalLabel">إضافة خدمة للمزود</h5>
               <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
               </button>
               </div>
               <div class="modal-body">
                  <div class="row">
                     <div class="col">
                        <label>اختر الخدمة:</label>
                        <select v-model="newService" class="form-control" v-if="servicesArr">
                           <option value="">اختر الخدمة</option>
                           <option v-for="(serv, code) in servicesArr" :key="code" :value="code">{{ serv }}</option>
                        </select>
                     </div>
                  </div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-success" @click="addingNewService()">إضافة</button>
                  <button type="button" class="btn btn-secondary" data-dismiss="modal" ref="hidemodel">إلغاء الامر</button>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
   import alertifyjs from "alertifyjs";
   import { mapGetters } from 'vuex';

   export default {
      name: "PackageForm",
      computed: {
         ...mapGetters(["getUserData"])
      },
      data(){
         return {
            package_ref: this.$route.params.package_ref,
            loading: true,
            formLoading: false,
            hasError: false,
            servicesArr: [],
            newService: "",
            breadcrum: {
               "/": "الرئيسية",
               "/packages": "الباقات",
            },

            app: "customer",
            name_ar: "",
            name_en: "",
            description_ar: "",
            description_en: "",
            amount: 0,
            status: "active",
            pay_credit: 0,
            available_to_buy: "yes",
            bank_name: "",
            activation_period_number: 1,
            activation_period_type: "year",
            custom_services: false,
            services: [],
            g_discount_type: "percent",
            g_discount_value: 0,
            g_max_value: 0,
            g_using_count: 0,
            notice: "",
         }
      },
      methods: {
         getData(){
            this.$http.get("/api/orders/cities").then(response => {
               this.servicesArr = response.data.services;

               if(this.package_ref != "create"){
                  if(!this.getUserData.is_admin && !this.getUserData.can_edit){
                     window.location.href = "/";
                     return;
                  }

                  this.$http.get("/api/packages/get/"+ this.package_ref).then(response => {
                     this.loading = false;
                     const row = response.data.row;
                     this.breadcrum["#"] = row.name_ar;
                     this.$store.commit("SET_BREADCRUMB", this.breadcrum);

                     this.app = row.app;
                     this.name_ar = row.name_ar;
                     this.name_en = row.name_en;
                     this.description_ar = row.description_ar;
                     this.description_en = row.description_en;
                     this.amount = row.amount;
                     this.status = row.status;
                     this.pay_credit = row.pay_credit;
                     this.available_to_buy = row.available_to_buy;
                     this.bank_name = row.bank_name;
                     this.activation_period_number = row.activation_period_number;
                     this.activation_period_type = row.activation_period_type;
                     this.custom_services = row.custom_services;
                     this.services = row.services;
                     this.g_discount_type = (row.g_discount_type)? row.g_discount_type : "";
                     this.g_discount_value = (row.g_discount_value)? row.g_discount_value : 0;
                     this.g_max_value = (row.g_max_value)? row.g_max_value : 0;
                     this.g_using_count = (row.g_using_count)? row.g_using_count : 0;
                     this.notice = row.notice;
                  }).catch(error => {
                     this.hasError = true;
                     if(error.data && error.data.error){
                        this.$store.commit("SET_ERROR", error.data.error);
                     }else{
                        this.$store.commit("SET_ERROR", "لدينا خطأ فني");
                     }
                  });
               }else{
                  if(!this.getUserData.is_admin && !this.getUserData.can_create){
                     window.location.href = "/";
                     return;
                  }
                  this.loading = false;
               }
            }).catch(() => {
               this.hasError = true;
               this.$store.commit("SET_ERROR", "لدينا خطأ فني");
            });
         },
         removeService(index){
            this.$delete(this.services, index);
         },
         addingNewService(){
            if(this.newService == ""){
               alertifyjs.error("يجب اختيار الخدمة");
            }else{
               this.services.push({
                  code: this.newService,
                  name: this.servicesArr[this.newService],
                  discount_type: "percent",
                  discount_value: 0,
                  max_value: 0,
                  using_count: 0,
               });
               this.$refs.hidemodel.click();
               this.newService = "";
            }
         },
         packageSubmit(){
            this.formLoading = true;
            this.$store.commit("SET_ERROR", "");

            if(this.name_ar.trim() == ""){
               this.$store.commit("SET_ERROR", "يجب إدخال الاسم - عربي");
               this.formLoading = false;
               window.scroll(0, 0);
               return false;
            }
            if(this.name_en.trim() == ""){
               this.$store.commit("SET_ERROR", "يجب إدخال الاسم - انجليزي");
               this.formLoading = false;
               window.scroll(0, 0);
               return false;
            }
            if(this.description_ar.trim() == ""){
               this.$store.commit("SET_ERROR", "يجب إدخال الوصف - عربي");
               this.formLoading = false;
               window.scroll(0, 0);
               return false;
            }
            if(this.description_en.trim() == ""){
               this.$store.commit("SET_ERROR", "يجب إدخال الوصف - انجليزي");
               this.formLoading = false;
               window.scroll(0, 0);
               return false;
            }
            if(this.available_to_buy == "yes" && this.amount == 0){
               this.$store.commit("SET_ERROR", "يجب إدخال سعر الشراء لأنها ستظهر فى التطبيق");
               this.formLoading = false;
               window.scroll(0, 0);
               return false;
            }

            this.$http.post("/api/packages/set/"+ this.package_ref, {
               app: this.app,
               name_ar: this.name_ar,
               name_en: this.name_en,
               description_ar: this.description_ar,
               description_en: this.description_en,
               amount: this.amount,
               status: this.status,
               pay_credit: this.pay_credit,
               available_to_buy: this.available_to_buy,
               bank_name: this.bank_name,
               activation_period_number: this.activation_period_number,
               activation_period_type: this.activation_period_type,
               custom_services: this.custom_services,
               services: this.services,
               g_discount_type: this.g_discount_type,
               g_discount_value: this.g_discount_value,
               g_max_value: this.g_max_value,
               g_using_count: this.g_using_count,
               notice: this.notice,
            }).then(response => {
               this.formLoading = false;
               if(response.data && response.data.status == "OK"){
                  if(this.package_ref == "create"){
                     alertifyjs.success("تمت الإضافة بنجاح");
                  }else{
                     alertifyjs.success("تم التعديل بنجاح");
                  }
                  this.$router.replace("/packages");
               }else{
                  this.$store.commit("SET_ERROR", "لدينا خطأ فني");
               }
            }).catch(error => {
               this.formLoading = false;
               if(error.data && error.data.error){
                  this.$store.commit("SET_ERROR", error.data.error);
               }else{
                  this.$store.commit("SET_ERROR", "لدينا خطأ فني");
               }
            });
         }
      }, 
      created(){
         this.$store.commit("SET_ERROR", "");
         this.$store.commit("SET_SUCCESS", "");

         if(this.package_ref != "create"){
            this.$store.commit("SET_TITLE", "تعديل بيانات");
         }else{
            this.$store.commit("SET_TITLE", "إضافة جديد");
            this.breadcrum["#"] = "إضافة جديد";
         }

         this.$store.commit("SET_BREADCRUMB", this.breadcrum);
         this.getData();
      },
   }
</script>