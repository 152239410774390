<template>
   <div>
      <div class="row" v-if="loading">
         <div class="col">
            <div class="big-loader text-center pt-50 pb-50">
               <loader />
            </div>
         </div>
      </div>

      <div class="row" v-if="!hasError && !loading">
         <div class="col">
            <div class="card shadow mb-4">
               <div class="card-body">
                  <div class="row">
                     <div class="col">
                        <form action="" @submit.prevent="citySubmit">
                           <div class="row pb-4">
                              <div class="col">
                                 <label for="">الاسم - عربي</label>
                                 <input type="text" v-model="name_ar" class="form-control">
                              </div>
                              <div class="col">
                                 <label for="">الاسم - انجليزي</label>
                                 <input type="text" v-model="name_en" class="form-control">
                              </div>
                           </div>

                           <div class="row pb-4">
                              <div class="col">
                                 <label for="">latitude</label>
                                 <input type="text" v-model="latitude" class="form-control">
                              </div>
                              <div class="col">
                                 <label for="">longitude</label>
                                 <input type="text" v-model="longitude" class="form-control">
                              </div>
                           </div>

                           <div class="row pb-4">
                              <div class="col">
                                 <label for="">الترتيب</label>
                                 <input type="number" v-model="seq" class="form-control">
                              </div>
                              <div class="col">
                                 <div class="row pb-4">
                                    <div class="col">
                                       <div class="form-check">
                                          <input type="checkbox" class="form-check-input" id="pickup-input" v-model="can_pickup">
                                          <label class="form-check-label" for="pickup-input">قابل للشحن</label>
                                       </div>
                                    </div>
                                    <div class="col">
                                       <div class="form-check">
                                          <input type="checkbox" class="form-check-input" id="dropoff-input" v-model="can_dropoff">
                                          <label class="form-check-label" for="dropoff-input">قابل للتفريغ</label>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>

                           <div class="row">
                              <div class="col text-center">
                                 <button type="submit" class="btn btn-success" v-if="!formLoading">إرسال</button>
                                 <div class="text-center pt-2 pb-2" v-if="formLoading">
                                    <inlineloader />
                                 </div>
                              </div>
                           </div>
                        </form>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
   import alertifyjs from "alertifyjs";
   import { mapGetters } from 'vuex';
   export default {
      name: "citiesForm",
      computed: {
         ...mapGetters(["getUserData"])
      },
      data(){
         return {
            city_ref: this.$route.params.city_ref,
            loading: true,
            formLoading: false,
            hasError: false,
            breadcrum: {
               "/": "الرئيسية",
               "/cities": "المدن",
               "#": '',
            },

            name_ar: "",
            name_en: "",
            can_pickup: false,
            can_dropoff: false,
            latitude: 0,
            longitude: 0,
            seq: 0,
         }
      },
      methods: {
         getData(){
            if(this.city_ref != "create"){
               setTimeout(function(usr){
                  if(!usr.is_admin && !usr.can_edit){
                     window.location.href = "/";
                  return;
                  }
               },2000, this.getUserData);

               // if(!this.getUserData.is_admin && !this.getUserData.can_edit){
               //    window.location.href = "/";
               //    return;
               // }

               this.$http.get("/api/towing/cities/get/"+ this.city_ref).then(response => {
                  this.loading = false;
                  const row = response.data.row;

                  this.name_ar = row.name_ar;
                  this.name_en = row.name_en;
                  this.can_pickup = row.can_pickup;
                  this.can_dropoff = row.can_dropoff;
                  this.latitude = row.latitude? row.latitude : 0;
                  this.longitude = row.longitude? row.longitude : 0;
                  this.seq = row.seq? row.seq : 0;

                  this.breadcrum["#"] = this.name_ar;
                  this.$store.commit("SET_BREADCRUMB", this.breadcrum);
               }).catch(error => {
                  this.hasError = true;
                  if(error.data && error.data.error){
                     this.$store.commit("SET_ERROR", error.data.error);
                  }else{
                     this.$store.commit("SET_ERROR", "لدينا خطأ فني");
                  }
               });
            }else{
               setTimeout(function(usr){
                  if(!usr.is_admin && !usr.can_create){
                     window.location.href = "/";
                  return;
                  }
               }, 2000, this.getUserData);

               // if(!this.getUserData.is_admin && !this.getUserData.can_create){
               //    window.location.href = "/";
               //    return;
               // }
               this.loading = false;
            }
         },
         citySubmit(){
            this.formLoading = true;
            this.$store.commit("SET_ERROR", "");

            if(this.name_ar.trim() == ""){
               this.$store.commit("SET_ERROR", "يجب إدخال الاسم - عربي");
               this.formLoading = false;
               window.scroll(0, 0);
               return false;
            }
            if(this.name_en.trim() == ""){
               this.$store.commit("SET_ERROR", "يجب إدخال الاسم - انجليزي");
               this.formLoading = false;
               window.scroll(0, 0);
               return false;
            }

            this.$http.post("/api/towing/cities/set/"+ this.city_ref, {
               name_ar: this.name_ar,
               name_en: this.name_en,
               can_pickup: this.can_pickup,
               can_dropoff: this.can_dropoff,
               latitude: this.latitude,
               longitude: this.longitude,
               seq: this.seq,
            }).then(response => {
               this.formLoading = false;
               if(response.data && response.data.status == "OK"){
                  if(this.city_ref == "create"){
                     alertifyjs.success("تمت الإضافة بنجاح");
                  }else{
                     alertifyjs.success("تم التعديل بنجاح");
                  }
                  this.$router.replace("/cities");
               }else{
                  this.$store.commit("SET_ERROR", "لدينا خطأ فني");
               }
            }).catch(error => {
               this.formLoading = false;
               if(error.data && error.data.error){
                  this.$store.commit("SET_ERROR", error.data.error);
               }else{
                  this.$store.commit("SET_ERROR", "لدينا خطأ فني");
               }
            });
         }
      }, 
      created(){
         window.scroll(0, 0);
         this.$store.commit("SET_ERROR", "");
         this.$store.commit("SET_SUCCESS", "");

         if(this.city_ref != "create"){
            this.$store.commit("SET_TITLE", "تعديل بيانات");
         }else{
            this.$store.commit("SET_TITLE", "إضافة جديد");
            this.breadcrum["#"] = "إضافة جديد";
         }

         this.$store.commit("SET_BREADCRUMB", this.breadcrum);
         this.getData();
      },
   }
</script>