<template>
   <div>
      <div class="row" v-if="loading">
         <div class="col">
            <div class="big-loader text-center pt-50 pb-50">
               <loader />
            </div>
         </div>
      </div>

      <div class="row pb-5" v-if="!hasError && !loading">
         <div class="col text-right">
            <router-link :to="`/userForm/${user_ref}?back=${back}`" class="btn btn-warning" v-if="back && (getUserData.is_admin || getUserData.can_edit)"><i class="far fa-edit"></i> تعديل</router-link>
            <router-link :to="`/userForm/${user_ref}`" class="btn btn-warning" v-else-if="getUserData.is_admin || getUserData.can_edit"><i class="far fa-edit"></i> تعديل</router-link>
            <router-link :to="`/users/payments/${user_ref}`" class="btn btn-light nowrap ml-3">
               <i class="fas fa-search-dollar"></i>  كشف الحساب القديم
            </router-link>
            <router-link v-if="row.is_provider" :to="`/users/paymentsNew/${user_ref}?type=provider`" class="btn btn-light p-1">
               <i class="far fa-list-alt "></i> كشف حساب المزود
            </router-link>
            <router-link :to="`/users/paymentsNew/${user_ref}?type=user`" class="btn btn-light p-1">
               <i class="fas fa-list-alt ml-1"></i> كشف حساب العميل 
            </router-link>
            <a :href="`https://google.com/maps/search/${providerRow.location._latitude},${providerRow.location._longitude}`" target="_blank" v-if="providerRow && providerRow.location" class="btn btn-info ml-3">
               <i class="fas fa-map-marked-alt"></i> موقعه على الخريطة
            </a>
         </div>
      </div>

      <div class="row" v-if="!hasError && !loading">
         <div class="col">
            <div class="card shadow mb-4">
               <div class="card-body">
                  <div class="row mb-5" v-if="row.provider_img">
                     <div class="col text-center">
                        <img :src="row.provider_img" class="img-thumbnail profile-img">
                     </div>
                  </div>

                  <div class="row" v-if="row.is_rejected">
                     <div class="col">
                        <div class="alert alert-warning" role="alert">
                           هذا المزود تم رفض طلب الانضمام له  
                        </div>
                     </div>
                  </div>

                  <div class="row" v-if="row.is_blocked">
                     <div class="col">
                        <div class="alert alert-danger" role="alert">
                           هذا الحساب محظور 
                        </div>
                     </div>
                  </div>

                  <div class="row">
                     <div class="col">
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                           <li class="nav-item">
                              <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">البيانات الاساسية</a>
                           </li>
                           <li class="nav-item" v-if="providerRow">
                              <a class="nav-link" id="Equipment-tab" data-toggle="tab" href="#Equipment" role="tab" aria-controls="Equipment" aria-selected="false">المعدات والمرفقات</a>
                           </li>
                           <li class="nav-item" v-if="providerRow">
                              <a class="nav-link" id="active-services-tab" data-toggle="tab" href="#active-services" role="tab" aria-controls="active-services" aria-selected="false">الخدمات المفعلة</a>
                           </li>
                           <li class="nav-item" v-if="providerRow">
                              <a class="nav-link" id="inactive-services-tab" data-toggle="tab" href="#inactive-services" role="tab" aria-controls="inactive-services" aria-selected="false">الخدمات الغير مفعلة</a>
                           </li>
                           <li class="nav-item" v-if="providerRow">
                              <a class="nav-link" id="waiting-services-tab" data-toggle="tab" href="#waiting-services" role="tab" aria-controls="waiting-services" aria-selected="false">خدمات بإنتظار التفعيل</a>
                           </li>
                           <li class="nav-item">
                              <a class="nav-link" id="Operation-log-tab" data-toggle="tab" href="#Operation-log" role="tab" aria-controls="Operation-log" aria-selected="false">سجل العمليات</a> 
                           </li>
                        </ul>

                        <div class="tab-content" id="myTabContent">
                           <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                              <table class="table">
                                 <tbody>
                                    <tr>
                                       <td><strong class="mr-2">الاسم:</strong> {{ row.name }}</td>
                                       <td><strong class="mr-2">الجوال:</strong> {{ row.mobile }}</td>
                                    </tr>
                                    <tr>
                                       <td><strong class="mr-2">نوع الحساب:</strong> {{ (row.is_provider)? "مزود" : "عميل" }}</td>
                                       <td>
                                          <strong class="mr-2">تاريخ الانشاء:</strong>
                                          {{( row.creation_date)? row.creation_date: 'لا يوجد'}}
                                       </td>
                                    </tr>
                                    <tr v-if="providerRow">
                                       <td><strong class="mr-2">المدينة:</strong>{{providerRow.current_city}}</td>
                                       <td><strong class="mr-2">الشركة التابع لها المزود:</strong>{{row.fazaa_company_name}}</td>
                                    </tr>
                                    <tr v-if="providerRow">
                                       <td><strong class="mr-2">الحاله :</strong> {{(providerRow.is_ready)?"متصل":"غير متصل"}}</td>
                                       <td><strong class="mr-2">اخر ظهور:</strong> {{providerRow.last_location_current}} </td>
                                    </tr>
                                    <tr v-if="!providerRow">
                                       <td><strong class="mr-2">حالة الحظر:</strong> {{ (row.is_blocked)? "محظور" : "غير محظور" }}</td>
                                       <td></td>
                                    </tr>
                                    <tr>
                                       <td><strong class="mr-2">نوع المزود:</strong>{{row.fazaa_type}}</td>
                                       <td></td>
                                    </tr>
                                 </tbody>
                              </table>
                              <table class="table justify-content-center">
                                 <thead>
                                    <tr>
                                       <th>الرصيد</th>
                                       <th>الرصيد كعميل</th>
                                       <th>الرصيد كمزود</th>
                                       <th>الرصيد في الشركة</th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    <tr>
                                       <td>{{(row.balance)? row.balance : 'لا يوجد'}}</td>
                                       <td>{{(row.wallet_user)? row.wallet_user : 'لا يوجد'}}</td>
                                       <td>{{(row.wallet_provider)? row.wallet_provider : 'لا يوجد'}}</td>
                                       <td>{{(row.wallet_company)? row.wallet_company : 'لا يوجد'}}</td>
                                    </tr>
                                 </tbody>
                              </table>
                              <table class="table" v-if="providerRow">
                                 <tbody>
                                    <tr>
                                       <td><strong>STC Mobile:</strong> {{ providerRow.stc_mobile }}</td>
                                       <td><strong>الجنسية:</strong> {{ row.nationality }}</td>
                                    </tr>
                                    <tr>
                                       <td><strong>التوثيق:</strong> {{ (providerRow.trusted)? "موثق" : "غير موثق" }}</td>
                                       <td><strong>تاريخ التوثيق:</strong> {{ providerRow.trusted_date }}</td>
                                    </tr>
                                    <tr>
                                       <td><strong>حالة طلب الإنضمام:</strong> {{ (row.is_provider_approved)? "تمت الموافقة" : "بإنتظار الموافقة" }}</td>
                                       <td><strong>حالة الحظر:</strong> {{ (row.is_blocked)? "محظور" : "غير محظور" }}</td>
                                    </tr>
                                    <tr>
                                       <td><strong>التقييم:</strong> {{ providerRow.review_avg }}</td>
                                       <td><strong>عدد المقيمين:</strong> {{ providerRow.review_count }}</td>
                                    </tr>
                                    <tr>
                                       <td><strong>تاريخ الميلاد:</strong> {{ row.birthday_date }}</td>
                                       <td><strong>حالة التسجيل فى الهيئة:</strong> {{ (row.citc_id && row.citc_id != '')? "مسجل" : "غير مسجل" }}</td>
                                    </tr>
                                    <tr>
                                       <td><strong>نوع الجوال:</strong> {{ (row.device)? row.device.deviceType : "" }}</td>
                                       <td><strong>اصدار التطبيق:</strong> {{ (row.device)? row.device.buildNumber : "" }}</td>
                                    </tr>
                                    <tr>
                                       <td><strong>ماركة السيارة:</strong> {{ row.carBrand }}</td>
                                       <td><strong>نوع السيارة:</strong> {{ row.carModel }} {{ row.carYear }}</td>
                                    </tr>
                                    <tr>
                                       <td><strong>رقم اللوحة:</strong> {{ row.carNumber }}</td>
                                       <td><strong>رقم الهوية:</strong> {{ row.identity_number }}</td>
                                    </tr>
                                    <tr>
                                       <td>
                                          <div><strong>صورة التأمين:</strong></div>
                                          <img :src="row.carInsuranceImg" v-if="row.carInsuranceImg" class="img-thumbnail thum-400">
                                       </td>
                                       <td>
                                          <div><strong>صورة الهوية:</strong></div>
                                          <img :src="row.identity_img" v-if="row.identity_img" class="img-thumbnail thum-400">
                                       </td>
                                    </tr>
                                    <tr>
                                       <td>
                                          <div><strong>صورة السيارة:</strong></div>
                                          <img :src="row.car_img" v-if="row.car_img" class="img-thumbnail thum-400">
                                       </td>
                                       <td>
                                          <div><strong>صورة رخصة السيارة:</strong></div>
                                          <img :src="row.car_license_img" v-if="row.car_license_img" class="img-thumbnail thum-400">
                                       </td>
                                    </tr>
                                    <tr>
                                       <td>
                                          <strong>اولوية في قبول الطلبات: </strong>
                                          {{ (row.force_order_approval)? "نعم قبول مباشر للطلبات" : "No" }}
                                       </td>
                                       <td></td>
                                    </tr>
                                 </tbody>
                              </table>
                           </div>

                           <div class="tab-pane fade" id="Equipment" role="tabpanel" aria-labelledby="Equipment-tab" >
                              <div class="row" v-if="providerRow">
                                 <div class="col-12 col-sm-4 pt-3" v-for="(image, index) in providerRow.tools_images" :key="index" >
                                    <img :src="image" class="img-fluid img-thumbnail" alt="">
                                 </div>
                              </div>
                           </div>

                           <div class="tab-pane fade" id="active-services" role="tabpanel" aria-labelledby="active-services-tab">
                              <div class="row">
                                 <div class="col p-3">
                                    <div class="row mb-4">
                                       <div class="col">
                                          <h4>الخدمات المفعلة:</h4>
                                       </div>
                                       <div class="col text-right" v-if="getUserData.is_admin || getUserData.can_edit">
                                          <button type="button" class="btn btn-light" data-toggle="modal" data-target="#addingServiceModal"><i class="fas fa-plus"></i> إضافة خدمة</button>
                                       </div>
                                    </div>
                                    <table class="table table-striped" v-if="providerRow">
                                       <thead>
                                          <tr>
                                             <th>#</th>
                                             <th>اسم الخدمة</th>
                                             <th>حذف الخدمة</th>
                                          </tr>
                                       </thead>
                                       <tbody>
                                          <tr v-for="(item, index) in providerRow.active_services" :key="index">
                                             <td>{{ (index + 1) }}</td>
                                             <td>{{ (servicesArr[item])? servicesArr[item] : item }}</td>
                                             <td>
                                                <button class="btn btn-danger" @click.prevent="removeActiveService(item, index)" v-if="getUserData.is_admin || getUserData.can_edit"><i class="far fa-trash-alt"></i></button>
                                             </td>
                                          </tr>
                                       </tbody>
                                    </table>
                                 </div>
                              </div>
                           </div>

                           <div class="tab-pane fade" id="inactive-services" role="tabpanel" aria-labelledby="active-services-tab">
                              <div class="row">
                                 <div class="col p-3">
                                    <div class="row mb-4">
                                       <div class="col">
                                          <h4>الخدمات الغير مفعلة:</h4>
                                       </div>
                                    </div>
                                    <table class="table table-striped" v-if="providerRow">
                                       <thead>
                                          <tr>
                                             <th>#</th>
                                             <th>اسم الخدمة</th>
                                             <!-- th>حذف الخدمة</th -->
                                          </tr>
                                       </thead>
                                       <tbody>
                                          <tr v-for="(item, index) in providerRow.inactive_services" :key="index">
                                             <td>{{ (index + 1) }}</td>
                                             <td>{{ (servicesArr[item])? servicesArr[item] : item }}</td>
                                             <!-- td>
                                                <button class="btn btn-danger" @click.prevent="removeActiveService(item, index)"><i class="far fa-trash-alt"></i></button>
                                             </td -->
                                          </tr>
                                       </tbody>
                                    </table>
                                 </div>
                              </div>
                           </div>

                           <div class="tab-pane fade" id="waiting-services" role="tabpanel" aria-labelledby="waiting-services-tab">
                              <div class="row">
                                 <div class="col p-3">
                                    <h4 class="mb-4">خدمات بإنتظار التفعيل:</h4>
                                    <table class="table table-striped" v-if="providerRow">
                                       <thead>
                                          <tr>
                                             <th>#</th>
                                             <th>اسم الخدمة</th>
                                             <th>قبول</th>
                                             <th>رفض</th>
                                          </tr>
                                       </thead>
                                       <tbody>
                                          <tr v-for="(item, index) in providerRow.waiting_services" :key="index">
                                             <td>{{ (index + 1) }}</td>
                                             <td>{{ (servicesArr[item])? servicesArr[item] : item }}</td>
                                             <td>
                                                <button class="btn btn-success" @click.prevent="approveWaitingService(item, index)" v-if="getUserData.is_admin || getUserData.can_edit"><i class="far fa-check-square"></i></button>
                                             </td>
                                             <td>
                                                <button class="btn btn-danger" @click.prevent="removeWaitingService(item, index)" v-if="getUserData.is_admin || getUserData.can_edit"><i class="far fa-trash-alt"></i></button>
                                             </td>
                                          </tr>
                                       </tbody>
                                    </table>
                                 </div>
                              </div>
                           </div>
                           
                           <div class="tab-pane fade" id="Operation-log" role="tabpanel" aria-labelledby="Operation-log-tab">
                              <div class="row">
                                 <div class="col">
                                    <div class="card m-3">
                                       <div class="talbe-responsive">
                                          <table class="table table-striped table-hover">
                                             <thead>
                                                <tr>
                                                   <th>التاريخ</th>
                                                   <th>العنوان</th>
                                                   <th>نوع العملية</th>
                                                   <th>المستخدم</th>
                                                   <th>البيانات</th>
                                                </tr>
                                             </thead>
                                             <tbody>
                                                <tr v-for="(val,index) in log" :key=index>
                                                   <td>{{val.created_at_string}}</td>
                                                   <td>{{val.title}} </td>
                                                   <td>{{val.action}}</td>
                                                   <td>{{val.user_name}}</td>
                                                   <td>
                                                      <ul>
                                                         <li v-for="(aval,aindex) in val.req_data" :key="aindex">
                                                            {{aindex}}={{aval}};
                                                         </li>
                                                      </ul>
                                                   </td>
                                                </tr>
                                             </tbody>
                                          </table>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="modal fade" id="addingServiceModal" tabindex="-1" role="dialog" aria-labelledby="addingServiceModalLabel" aria-hidden="true">
         <div class="modal-dialog" role="document">
            <div class="modal-content">
               <div class="modal-header">
               <h5 class="modal-title" id="addingServiceModalLabel">إضافة خدمة للمزود</h5>
               <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
               </button>
               </div>
               <div class="modal-body">
                  <div class="row">
                     <div class="col">
                        <label>اختر الخدمة:</label>
                        <select v-model="newService" class="form-control" v-if="servicesArr">
                           <option value="">اختر الخدمة</option>
                           <option v-for="(serv, code) in servicesArr" :key="code" :value="code">{{ serv }}</option>
                        </select>
                     </div>
                  </div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">إلغاء الامر</button>
                  <button type="button" class="btn btn-success" @click="addingNewService">إضافة</button>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
   import alertifyjs from "alertifyjs";
   import swal from 'sweetalert';
   import { mapGetters } from 'vuex';

   export default {
      name: "UserView",
      computed: {
         ...mapGetters(["getUserData"])
      },
      data(){
         return {
            user_ref: this.$route.params.user_ref,
            back: this.$route.query.back,
            loading: true,
            hasError: false,
            row:[],
            log:[],
            providerRow: null,
            servicesArr: [],
            newService: "",
            fazaa_company: "",
            breadcrum: {
               "/": "الرئيسية",
            },
         }
      },
      methods: {
         getData(){
            this.$http.get("/api/users/get/"+ this.user_ref).then(response => {
               window.scroll(0,0),
               this.loading = false;
               this.row = response.data.row;
               this.log = response.data.logs;
               this.providerRow = response.data.providerRow;
               this.servicesArr = response.data.services;
               this.breadcrum["#"] = this.row.mobile;
               this.$store.commit("SET_BREADCRUMB", this.breadcrum);
               this.$store.commit("SET_TITLE", this.row.name);
            }).catch(error => {
               console.log(error);
               this.hasError = true;
               this.$store.commit("SET_ERROR", "لدينا خطأ فني");
            });
         },
         async removeActiveService(code, index){
            this.$store.commit("SET_ERROR", "");
            let deleteServ = await swal({
               title: "حذف خدمة",
               text: "هل تريد تأكيد حذف الخدمة من المزود ؟",
               icon: "warning",
               dangerMode: true,
               buttons: {
                  confirm: true,
                  cancel: true,
               }
            });
            
            if(deleteServ == true){
               this.$http.post("/api/users/removeService", {
                  user: this.user_ref,
                  service: code,
                  status: "active"
               }).then(() => {
                  this.$delete(this.providerRow.active_services, index);
               }).catch(err => {
                  if(err.data){
                     this.$store.commit("SET_ERROR", err.data.error);
                  }
               });
            }
         },
         async approveWaitingService(code, index){
            this.$store.commit("SET_ERROR", "");
            let deleteServ = await swal({
               title: "تأكيد خدمة",
               text: "هل تريد تأكيد الوافقة على الخدمة ؟",
               icon: "info",
               dangerMode: true,
               buttons: {
                  confirm: true,
                  cancel: true,
               }
            });
            
            if(deleteServ == true){
               this.$http.post("/api/users/addingService", {
                  user: this.user_ref,
                  service: code
               }).then(() => {
                  if(this.providerRow.active_services && this.providerRow.active_services.length){
                     this.providerRow.active_services.push(this.providerRow.waiting_services[index]);
                  }else{
                     this.providerRow.active_services = [this.providerRow.waiting_services[index]];
                  }
                  this.$delete(this.providerRow.waiting_services, index);
               }).catch(err => {
                  if(err.data){
                     this.$store.commit("SET_ERROR", err.data.error);
                  }
               });
            }
         },
         async removeWaitingService(code, index){
            this.$store.commit("SET_ERROR", "");
            let deleteServ = await swal({
               title: "حذف خدمة",
               text: "هل تريد تأكيد حذف الخدمة من المزود ؟",
               icon: "warning",
               dangerMode: true,
               buttons: {
                  confirm: true,
                  cancel: true,
               }
            });
            
            if(deleteServ == true){
               this.$http.post("/api/users/removeService", {
                  user: this.user_ref,
                  service: code,
                  status: "waiting"
               }).then(() => {
                  this.$delete(this.providerRow.waiting_services, index);
               }).catch(err => {
                  if(err.data){
                     this.$store.commit("SET_ERROR", err.data.error);
                  }
               });
            }
         },
         async addingNewService(){
            if(this.newService == ""){
               alertifyjs.error("يجب اختيار الخدمة");
            }else{
               this.$http.post("/api/users/addingService", {
                  user: this.user_ref,
                  service: this.newService
               }).then(() => {
                  this.newService = "";
                  if(this.providerRow.active_services && this.providerRow.active_services.length){
                     if(this.providerRow.active_services.indexOf(this.newService) == -1){
                        this.providerRow.active_services.push(this.newService);
                     }
                  }else{
                     this.providerRow.active_services = [this.newService];
                  }
                  if(this.providerRow.waiting_services.indexOf(this.newService) != -1){
                     this.$delete(this.providerRow.waiting_services, this.providerRow.waiting_services.indexOf(this.newService));
                  }
                  alertifyjs.success("تم إضافة الخدمة بنجاح");
               }).catch(() => {
                  alertifyjs.error("غير قادر على إضافة الخدمة ، يرجى المحاولة لاحقاً");
               });
            }
         }
      }, 
      created(){
         if(this.back){
            if(this.back == "servicesApproval"){
               this.breadcrum["/servicesApproval"] = "طلبات الخدمات";
            }else if(this.back == "providersRequests"){
               this.breadcrum["/providersRequests"] = "طلبات الإنضمام";
            }
         }else{
            this.breadcrum["/users"] = "عملاء ومزودين";
         }

         this.$store.commit("SET_ERROR", "");
         this.$store.commit("SET_SUCCESS", "");

         this.getData();
      },
   }
</script>