import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {
      token: null,
      name: "",
      is_admin: false,
      can_create: false,
      can_edit: false,
      can_export: false,
      can_see_reports: false,
    },
    activeMenu: "",
    globalError: "",
    successMsg: "",
    screenTitle: "لوحة التحكم",
    breadcrumb: {
      "/": "الرئيسية",
    }
  },
  getters: {
    isAuth(state){
      return !!state.user.token;
    },
    haveError(state){
      return state.globalError;
    },
    getSuccessMsg(state){
      return state.successMsg;
    },
    getScreenTitle(state){
      return state.screenTitle;
    },
    getBreadcrumb(state){
      return state.breadcrumb;
    },
    getActiveMenu(state){
      return state.activeMenu;
    },
    getUserData(state){
      return state.user;
    }
  },
  mutations: {
    CURRENT_USER_FETCHED(state, user){
      state.user.token = user.token;
      state.user.name = user.name;
      state.user.is_admin = user.is_admin;
      state.user.can_create = user.can_create;
      state.user.can_edit = user.can_edit;
      state.user.can_export = user.can_export;
      state.user.can_see_reports = user.can_see_reports;
    }, 
    SET_ERROR(state, error){
      state.globalError = error;
    },
    SET_SUCCESS(state, msg){
      state.successMsg = msg;
    },
    SET_TITLE(state, msg){
      state.screenTitle = msg;
    },
    SET_BREADCRUMB(state, links){
      state.breadcrumb = links;
    },
    SET_ACTIVE_MENU(state, menu){
      state.activeMenu = menu;
    },
  },
  actions: {
    async initialLoad(context){
      if(localStorage.ezUserToken){
        Vue.axios.defaults.headers.common.Authorization = `Bearer ${localStorage.ezUserToken}`;
        const res = await Vue.axios.get("/api/auth/currentUser");
        context.commit("CURRENT_USER_FETCHED", res.data.user);
      }
    }, 
    async setLoginUser(context, payload){
      localStorage.setItem("ezUserToken", payload.token);
      context.commit("CURRENT_USER_FETCHED", payload);
    },
    async setUserLogout(context){
      localStorage.removeItem("ezUserToken");
      context.commit("CURRENT_USER_FETCHED", {
        token: null,
        name: "",
        is_admin: false,
        can_create: false,
        can_edit: false,
        can_export: false,
        can_see_reports: false,
      });
      window.location.href = '/';
    },
  },
  modules: {
  }
})
