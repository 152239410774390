<template>
    <div>
        <div class="row mb-5">
            <div class="col" >
                <div class="card border-left-warning shadow h-100 py-2">
                    <div class="card-body">
                        <form action="" @submit.prevent="search">
                            <div class="row">
                                <!-- model_number -->
                                <div class="col">
                                    <input type="text" v-model="model_number" class="form-control" placeholder="رقم الطلب">
                                </div>
                                    <!-- action -->
                                <div class="col">
                                <select class="form-control" v-model="action">
                                    <option value="">نوع العملية</option>
                                    <option value="create">إضافة</option>
                                    <option value="update">تعديل</option>
                                    <option value="delete">حذف</option>
                                    <option value="add">إضافة خدمة</option>
                                    <option value="remove">إلغاء خدمة</option>
                                </select>
                                </div>
                                    <!--model_name-->
                                <div class="col">
                                    <select class="form-control" v-model="model_name">
                                        <option value="">القسم</option>
                                        <option value="orders">طلبات</option>
                                        <option value="ezhalha_users">مديرين الموقع</option>
                                        <option value="packages">الباقات</option>
                                        <option value="towing_c ities">مدن السطحات</option>
                                        <option value="towing_lines">خطوط السطحات</option>
                                        <option value="users">عميل او مزود</option>
                                        <option value="payment_history">سجل المدفوعات</option>
                                        <option value="providers">مزودين الخدمة </option>
                                    </select>
                                </div>
                                <!-- user-email-->
                                <div class=" col">
                                    <input type="text" v-model="user_email" class="form-control" placeholder="البريد الالكتروني">
                                </div>
                                <!--user name-->
                                <div class="col">
                                    <input type="text" v-model="user_name" class="form-control" placeholder="اسم المستخدم">
                                </div>
                                    <button type="button" class="btn btn-light ml-2" @click="restData">الغاء</button>
                                <!-- submit -->
                                <div class="col">
                                    <button type="submit" class="btn btn-success"><i class="fas fa-search"></i></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div class="card shadow mb-4">
            <div class="card-header py-3">
            <h6 class=" m-0 font-weight-bold text-primary">سجل العمليات</h6>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col text-right pb-3 pagination-div ">
                        <a href="" class="d-inline-block mr-2" v-if="prevPage.length" @click.prevent="goToPrev"><i class="far fa-caret-square-right"></i></a>
                        <div class="d-inline-block rounded border border-secondary">{{ pageNumber }}</div>
                        <a href="" class="d-inline-block ml-2" v-if="nextPage" @click.prevent="goToNext">
                        <i class="far fa-caret-square-left"></i>
                        </a>
                    </div>
                </div>
                <div class=" table-responsive">
                    <div class="big-loader text-center pt-50 pb-50" v-if="loading">
                    <loader />
                    </div>
                    <table v-if="!loading" class="table table-bordered table-striped text-center " id="dataTable" widht="100%" cellspacing="0">
                        <thead>
                            <tr>
                                <th>التاريخ</th>
                                <th>العنوان</th>
                                <th>نوع العمليه</th>
                                <th>المستخدم</th>
                                <th>البيانات</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(val,index) in logData" :key= "index">
                                <td>{{val.created_at_string}}</td>
                                <td>{{val.title}}</td>
                                <td>{{val.action}}</td>
                                <td>{{val.user_name}}</td>
                                <td>
                                    <ul class="text-left">
                                        <li v-for="(dval, dindex) in val.req_data" :key="dindex">
                                        {{dval}} = {{dindex}}
                                        </li>
                                    </ul>  
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row">
                    <div class="col text-right pb-3 pagination-div ">
                        <a href="" class="d-inline-block mr-2" v-if="prevPage.length" @click.prevent="goToPrev"><i class="far fa-caret-square-right"></i></a>
                        <div class="d-inline-block rounded border border-secondary">{{ pageNumber }}</div>
                        <a href="" class="d-inline-block ml-2" v-if="nextPage" @click.prevent="goToNext">
                        <i class="far fa-caret-square-left"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
    name :"Logging",
    data(){
        return{
            loading: true,
            logData:[],
            mainUri: "/api/logging",
            uri: "/api/logging",
            breadcrum: {
                "/": "الرئيسية",
                "#": "سجل العمليات",
            },
            // form data
            model_number: '',
            action:'',
            model_name:'',
            user_email:'',
            user_name:'',
            prevPage:[],
            pageNumber:1,
            nextPage:null,
            has_Searsh:'',
        }
        
    },
    methods: {
        getdata(){
            this.$http.get(this.uri).then(res =>{
                this.loading = false;
                this.logData = res.data.rows;
                this.nextPage= res.data.next;
            });
        },
        search(){
            this.loading= true;
            this.uri=`${this.mainUri}?action=${this.action}&model_number=${this.model_number}&model_name=${this.model_name}&user_email=${this.user_email}&user_name=${this.user_name}`;
            this.pageNumber=1;
            this.prevPage=[];
            this.has_Searsh=true;
            this.getdata();
        },
        restData(){
            this.loading= true;
            this.uri= this.mainUri;
            this.model_number='';
            this.action='';
            this.model_name='';
            this.user_email='';
            this.user_name='';
            this.pageNumber=1;
            this.prevPage=[];
            this.has_Searsh=false;
            this.getdata();
        },
        goToNext(){
            this.loading=true;
            this.prevPage.push(this.nextPage);
            this.pageNumber=this.pageNumber+1;
            if(this.has_Searsh){
                this.uri=`${this.mainUri}?page=${this.nextPage}&action=${this.action}&model_number=${this.model_number}&model_name=${this.model_name}&user_email=${this.user_email}&user_name=${this.user_name}`;
            }else{
                this.uri=`${this.mainUri}?page=${this.nextPage}`;
            }
            this.getdata();
        },
        goToPrev(){
            this.loading=true;
            let search_param='';
            if(this.has_Searsh){
                search_param=`action=${this.action}&model_number=${this.model_number}&model_name=${this.model_name}&user_email=${this.user_email}&user_name=${this.user_name}`;
            }
            this.$delete(this.prevPage, this.prevPage.length - 1);
            let prevIndex = this.prevPage.length - 1;
            if(this.prevPage.length){
                this.uri=`${this.mainUri}?page=${this.prevPage[prevIndex]}`;
                if(this.has_Searsh){
                    this.uri=`${this.mainUri}?page=${this.prevPage[prevIndex]}&${search_param}`;
                }
            }else{
                this.uri=`${this.mainUri}`;
                if(this.has_Searsh){
                    this.uri=`${this.mainUri}?${search_param}`;
                }
            }
            this.pageNumber=this.pageNumber-1;
            this.getdata();
        },
    },
    created(){
        window.scroll(0,0);
        this.$store.commit("SET_ACTIVE_MENU", "Logging");
        this.$store.commit("SET_BREADCRUMB", this.breadcrum);
        this.$store.commit("SET_ERROR", "");
        this.$store.commit("SET_SUCCESS", "");
        this.$store.commit("SET_TITLE", "سجل العمليات");
        this.getdata();
    }
}
</script>
