<template>
   <div>
      <div class="row mb-4">
         <div class="col">
            <div class="card border-left-warning shadow h-100 py-2">
               <div class="card-body">
                  <form action="" @submit.prevent="getData">
                     <div class="row">
                        <div class="col">
                           <div class="row">
                              <div class="col">
                                 <select class="form-control" v-model="year" aria-placeholder="السنة">
                                    <option value="2021">2021</option>
                                    <option value="2022">2022</option>
                                 </select>
                              </div>
                              <div class="col">
                                 <select class="form-control" v-model="month" aria-placeholder="الشهر">
                                    <option value="01">01</option>
                                    <option value="02">02</option>
                                    <option value="03">03</option>
                                    <option value="04">04</option>
                                    <option value="05">05</option>
                                    <option value="06">06</option>
                                    <option value="07">07</option>
                                    <option value="08">08</option>
                                    <option value="09">09</option>
                                    <option value="10">10</option>
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                 </select>
                              </div>
                           </div>
                        </div>
                        <div class="col-auto">
                           <button type="submit" class="btn btn-success">
                              <i class="fas fa-search"></i>
                           </button>
                        </div>
                     </div>
                  </form>
               </div>
            </div>
         </div>
      </div>

      <div class="row" v-if="loading">
         <div class="col">
            <div class="big-loader text-center pt-50 pb-50">
               <loader />
            </div>
         </div>
      </div>

      <div class="card shadow mb-4" v-if="!loading">
         <div class="card-header py-3">
            <h6 class="m-0 font-weight-bold text-primary">افضل واسوأ المزودين</h6>
         </div>

         <div class="card-body">
            <div class="row mb-5">
               <div class="col">
                  <DataTable
                     title="افضل 15 مزود من حيث عدد الطلبات الناجحة"
                     :columns="tableColums"
                     :rows="data.best_providers"
                     :exportable="true"
                     v-if="data.best_providers"
                  />
               </div>
            </div>
            <div class="row mb-5">
               <div class="col">
                  <DataTable
                     title="اسوأ 15 مزود من حيث إلغاء الطلب بواسطة المزود"
                     :columns="tableColums"
                     :rows="data.bad_providers"
                     :exportable="true"
                     v-if="data.bad_providers"
                  />
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
   import DataTable from "vue-materialize-datatable";

   export default 
   {
      name: "ProvidersReport",
      components: {
         DataTable
      },
      data(){
         return {
            loading: true,
            breadcrum: {
               "/": "الرئيسية",
               "#": "تقرير المزودين",
            },
            year: "",
            month: "",
            data: {},
            tableColums: [
               {
                  label: "الاسم",
                  field: "name",
                  numeric: false,
                  html: false
               },
               {
                  label: "الجوال",
                  field: "mobile",
                  numeric: false,
                  html: false
               },
               {
                  label: "Done",
                  field: "done",
                  numeric: true,
                  html: false
               },
               {
                  label: "Expired",
                  field: "expired",
                  numeric: true,
                  html: false
               },
               {
                  label: "Canceled by user",
                  field: "cancelled_by_user",
                  numeric: true,
                  html: false
               },
               {
                  label: "Cancelled by provider",
                  field: "cancelled_by_provider",
                  numeric: true,
                  html: false
               },
            ],
         }
      },
      methods: {
         getData(){
            this.loading = true;
            this.$store.commit("SET_ERROR", "");
            this.$http.get("/api/reports/providers/" + this.year + this.month).then(response => {
               this.loading = false;
               this.data = response.data;
            }).catch(err => {
               this.$store.commit("SET_ERROR", err.data.error);
            });
         },
      },
      created(){
         window.scroll(0,0);
         this.$store.commit("SET_ACTIVE_MENU", "providers_report");
         this.$store.commit("SET_BREADCRUMB", this.breadcrum);
         this.$store.commit("SET_ERROR", "");
         this.$store.commit("SET_SUCCESS", "");
         this.$store.commit("SET_TITLE", "تقرير المزودين");
         
         let date = new Date();
         this.year = date.getFullYear();
         if(date.getMonth() < 10){
            this.month = `0${date.getMonth()+1}`;
         }else{
            this.month = date.getMonth()+1;
         }
         
         this.getData();
      }
   }
</script>