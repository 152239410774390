<template>
  <div id="app">
    <div id="wrapper">
      <Menu v-if="isAuth" :activeMenu="getActiveMenu"></Menu>
      <div id="content-wrapper" class="d-flex flex-column">
        <div id="content">
          <!-- Topbar -->
          <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow" v-if="isAuth">
            <!-- Sidebar Toggle (Topbar) -->
            <button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle mr-3">
                <i class="fa fa-bars"></i>
            </button>
            <!-- Topbar Search -->
            <!-- form
                class="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
                <div class="input-group">
                    <input type="text" class="form-control bg-light border-0 small" placeholder="Search for..."
                        aria-label="Search" aria-describedby="basic-addon2">
                    <div class="input-group-append">
                        <button class="btn btn-primary" type="button">
                            <i class="fas fa-search fa-sm"></i>
                        </button>
                    </div>
                </div>
            </form -->
            <!-- Topbar Navbar -->
            <ul class="navbar-nav ml-auto">
              <!-- Nav Item - Search Dropdown (Visible Only XS) -->
              <li class="nav-item dropdown no-arrow d-sm-none">
                  <a class="nav-link dropdown-toggle" href="#" id="searchDropdown" role="button"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i class="fas fa-search fa-fw"></i>
                  </a>
                  <!-- Dropdown - Messages -->
                  <div class="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in"
                      aria-labelledby="searchDropdown">
                      <form class="form-inline mr-auto w-100 navbar-search">
                          <div class="input-group">
                              <input type="text" class="form-control bg-light border-0 small"
                                  placeholder="Search for..." aria-label="Search"
                                  aria-describedby="basic-addon2">
                              <div class="input-group-append">
                                  <button class="btn btn-primary" type="button">
                                      <i class="fas fa-search fa-sm"></i>
                                  </button>
                              </div>
                          </div>
                      </form>
                  </div>
              </li>

              <!-- Nav Item - Alerts -->
              <!-- li class="nav-item dropdown no-arrow mx-1">
                  <a class="nav-link dropdown-toggle" href="#" id="alertsDropdown" role="button"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i class="fas fa-bell fa-fw"></i>
                      
                      <span class="badge badge-danger badge-counter">3+</span>
                  </a>
                  
                  <div class="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
                      aria-labelledby="alertsDropdown">
                      <h6 class="dropdown-header">
                          Alerts Center
                      </h6>
                      <a class="dropdown-item d-flex align-items-center" href="#">
                          <div class="mr-3">
                              <div class="icon-circle bg-primary">
                                  <i class="fas fa-file-alt text-white"></i>
                              </div>
                          </div>
                          <div>
                              <div class="small text-gray-500">December 12, 2019</div>
                              <span class="font-weight-bold">A new monthly report is ready to download!</span>
                          </div>
                      </a>
                      <a class="dropdown-item d-flex align-items-center" href="#">
                          <div class="mr-3">
                              <div class="icon-circle bg-success">
                                  <i class="fas fa-donate text-white"></i>
                              </div>
                          </div>
                          <div>
                              <div class="small text-gray-500">December 7, 2019</div>
                              $290.29 has been deposited into your account!
                          </div>
                      </a>
                      <a class="dropdown-item d-flex align-items-center" href="#">
                          <div class="mr-3">
                              <div class="icon-circle bg-warning">
                                  <i class="fas fa-exclamation-triangle text-white"></i>
                              </div>
                          </div>
                          <div>
                              <div class="small text-gray-500">December 2, 2019</div>
                              Spending Alert: We've noticed unusually high spending for your account.
                          </div>
                      </a>
                      <a class="dropdown-item text-center small text-gray-500" href="#">Show All Alerts</a>
                  </div>
              </li -->

              <!-- Nav Item - Messages -->
              <!-- li class="nav-item dropdown no-arrow mx-1">
                  <a class="nav-link dropdown-toggle" href="#" id="messagesDropdown" role="button"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i class="fas fa-envelope fa-fw"></i>
                      
                      <span class="badge badge-danger badge-counter">7</span>
                  </a>
                  
                  <div class="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
                      aria-labelledby="messagesDropdown">
                      <h6 class="dropdown-header">
                          Message Center
                      </h6>
                      <a class="dropdown-item d-flex align-items-center" href="#">
                          <div class="dropdown-list-image mr-3">
                              <img class="rounded-circle" src="img/undraw_profile_1.svg"
                                  alt="">
                              <div class="status-indicator bg-success"></div>
                          </div>
                          <div class="font-weight-bold">
                              <div class="text-truncate">Hi there! I am wondering if you can help me with a
                                  problem I've been having.</div>
                              <div class="small text-gray-500">Emily Fowler · 58m</div>
                          </div>
                      </a>
                      <a class="dropdown-item d-flex align-items-center" href="#">
                          <div class="dropdown-list-image mr-3">
                              <img class="rounded-circle" src="img/undraw_profile_2.svg"
                                  alt="">
                              <div class="status-indicator"></div>
                          </div>
                          <div>
                              <div class="text-truncate">I have the photos that you ordered last month, how
                                  would you like them sent to you?</div>
                              <div class="small text-gray-500">Jae Chun · 1d</div>
                          </div>
                      </a>
                      <a class="dropdown-item d-flex align-items-center" href="#">
                          <div class="dropdown-list-image mr-3">
                              <img class="rounded-circle" src="img/undraw_profile_3.svg"
                                  alt="">
                              <div class="status-indicator bg-warning"></div>
                          </div>
                          <div>
                              <div class="text-truncate">Last month's report looks great, I am very happy with
                                  the progress so far, keep up the good work!</div>
                              <div class="small text-gray-500">Morgan Alvarez · 2d</div>
                          </div>
                      </a>
                      <a class="dropdown-item d-flex align-items-center" href="#">
                          <div class="dropdown-list-image mr-3">
                              <img class="rounded-circle" src="https://source.unsplash.com/Mv9hjnEUHR4/60x60"
                                  alt="">
                              <div class="status-indicator bg-success"></div>
                          </div>
                          <div>
                              <div class="text-truncate">Am I a good boy? The reason I ask is because someone
                                  told me that people say this to all dogs, even if they aren't good...</div>
                              <div class="small text-gray-500">Chicken the Dog · 2w</div>
                          </div>
                      </a>
                      <a class="dropdown-item text-center small text-gray-500" href="#">Read More Messages</a>
                  </div>
              </li -->

              <div class="topbar-divider d-none d-sm-block"></div>

              <!-- Nav Item - User Information -->
              <li class="nav-item dropdown no-arrow">
                  <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <span class="mr-2 d-none d-lg-inline text-gray-600 small">{{ userName }}</span>
                      <img class="img-profile rounded-circle"
                          src="/theme/img/undraw_profile.svg">
                  </a>
                  <!-- Dropdown - User Information -->
                  <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                      aria-labelledby="userDropdown">
                      <!-- a class="dropdown-item" href="#">
                          <i class="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                          Profile
                      </a>
                      <a class="dropdown-item" href="#">
                          <i class="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                          Settings
                      </a>
                      <a class="dropdown-item" href="#">
                          <i class="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                          Activity Log
                      </a>
                      <div class="dropdown-divider"></div -->
                      <router-link class="dropdown-item" to="/RestPass">
                          <i class="fas fa-unlock mr-2 text-gray-400"></i>
                          تغير كلمة المرور 
                      </router-link>
                      <div class="dropdown-divider"></div>
                      <a class="dropdown-item" @click.prevent="logout">
                          <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                          تسجيل الخروج
                      </a>
                  </div>
              </li>
            </ul>
          </nav>
          <!-- End of Topbar -->

          <!-- Begin Page Content -->
          <div class="container-fluid">

              <!-- Page Heading -->
              <div class="d-sm-flex align-items-center justify-content-between mb-4" v-if="isAuth">
                  <h1 class="h3 mb-0 text-gray-800">{{ getScreenTitle }}</h1>
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item" v-for="(bread, index) in getBreadcrumb" :key="index" v-bind:class="{active: index == '#'}">
                            <router-link :to="index" v-if="index != '#'">{{ bread }}</router-link>
                            <span v-else>{{ bread }}</span>
                        </li>
                    </ol>
                </nav>
              </div>

              <div class="row">
                  <div class="col">
                    <div v-if="getSuccessMsg != ''">
                        <div class="alert alert-success">{{ getSuccessMsg }}</div>
                    </div>
                    <div v-if="haveError != ''">
                        <div class="alert alert-danger">{{ haveError }}</div>
                    </div>
                    <div v-if="loading" class="text-center">
                        <loader />
                    </div>
                  </div>
              </div>

              <router-view />

          </div>
          <!-- /.container-fluid -->
        </div>
        <!-- End of Main Content -->

        <!-- Footer -->
        <footer class="sticky-footer bg-white">
            <div class="container my-auto">
                <div class="copyright text-center my-auto">
                    <span>Copyright &copy; Ezhalha 2020</span>
                </div>
            </div>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';
import Menu from '@/components/Menu.vue';

export default {
  components: { 
    Menu
  },
  name: "App",

  data(){
    return{
      loading: true,
      error: false,
      errorMessage: "",
      userName: this.$store.state.user.name,
      moduleName: this.$router.history.current.name,
    }
  },

  methods: {
    ...mapActions(["initialLoad"]),
    logout(){
        this.$store.dispatch("setUserLogout");
    }
  }, 

  computed: {
    ...mapGetters(["isAuth", "haveError", "getSuccessMsg", "getScreenTitle", "getBreadcrumb", "getActiveMenu", "getUserData"])
  },

  async mounted(){
    /* try{
      await this.initialLoad();
      console.log("mounted check");
      this.loading = false;
      if(this.isAuth == false && this.$router.history.current.name != "Login"){
        // this.$router.replace('/login');
        window.location.href = "/login";
      }
    } catch(err){
      console.log("mounted error");
      this.loading = false;
      this.error = true;
    } */
  }, 

    async created(){
        try{
            await this.initialLoad();
            this.loading = false;
            if(this.isAuth == false && this.$router.history.current.name != "Login" && this.moduleName != 'AllProvidersIframe' && this.moduleName != 'ProviderMapIframe'){
                // this.$router.replace('/login');
                window.location.href = "/login";
            }
        } catch(err){
            this.loading = false;
            this.error = true;
            this.errorMessage = "يرجى إعادة تسجيل الدخول";
        }
    },
}
</script>

<style lang="scss">
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
