<template>
    <div>
        <div class="card shadow mb-4 ">
            <div class="card-header py-3">
                <h6 class="m-0 font-weight-bold text-primary">تفاصيل كشف الحساب</h6>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col">
                        <div class="table-responsive">
                            <div class="big-loader text-center pt-50 pb-50" v-if="loading">
                                <loader />
                            </div>
                        </div>
                        <table v-if="!loading" class="table text-center table-borderd table-striped " id="dataTable" width="100%" cellspacing="0">
                            <tbody>
                                <tr>
                                    <th>رقم الطلب</th>
                                    <td>{{payment.order_number}}</td>
                                    <th></th>
                                    <td></td>
                                </tr>
                                <tr>
                                    <th>التاريخ</th>
                                    <td>{{payment.created_at}}</td>
                                    <th>تاريخ اخر تحديث</th>
                                    <td>{{payment.updated_at}}</td>
                                </tr>
                                <tr>
                                    <th>البيان</th>
                                    <td>{{payment.title}}</td>
                                    <th>طريقة الدفع</th>
                                    <td>{{payment.gateway}}</td>
                                </tr>
                                <tr>
                                    <th>القيمة</th>
                                    <td>{{payment.amount}}</td>
                                    <th>دائن / مدين</th>
                                    <td>{{payment.credit_debit}}</td>
                                    
                                </tr>
                                <tr>
                                    <th>الرصيد قبل الطلب</th>
                                    <td>{{payment.wallet_before}}</td>
                                    <th>الرصيد بعد العملية</th>
                                    <td>{{payment.wallet_after}}</td>
                                </tr>
                                <tr>
                                    <th>الرقم المرجعي</th>
                                    <td>{{payment.reference}}</td>
                                    <th>اضافة الرصيد</th>
                                    <td>{{payment.wallet_recharge}}</td>
                                </tr>
                                <tr>
                                    <th>الحالة</th>
                                    <td>{{payment.status}}</td>
                                    <th>العملة</th>
                                    <td>{{payment.currency}}</td>
                                </tr>
                                <tr>
                                    <th>الخصم</th>
                                    <td>{{payment.discount_amount}}</td>
                                    <th>العمولة</th>
                                    <td>{{payment.is_commission}}</td>
                                </tr>
                                <tr>
                                    <th>عمولة ازهلها</th>
                                    <td>{{payment.ezhalha_commission}}</td>
                                    <th>نسبة عمولة ازهلها</th>
                                    <td>{{payment.ezhalha_commission_percentage}}</td>
                                </tr>
                                <tr>
                                    <th>عمولة المزود</th>
                                    <td>{{payment.provider_commission}}</td>
                                    <th>نسبة عمولة المزود</th>
                                    <td>{{payment.provider_commission_percentage}}</td>
                                </tr>
                                <tr>
                                    <th>تكلفة الخدمة</th>
                                    <td>{{payment.service_cost}}</td>
                                    <th>ضرائب الخدمة</th>
                                    <td>{{payment.tax_for_service}}</td>
                                </tr>
                                <tr>
                                    <th>تكلفة الخدمة بعد الخصم</th>
                                    <td>{{payment.service_after_discount}}</td>
                                    <th></th>
                                    <td></td>
                                </tr>
                                <tr>
                                    <th>إجمالي المبلغ</th>
                                    <td>{{payment.total_amount}}</td>
                                    <th>نوع الدفع</th>
                                    <td>{{payment.payment_type}}</td>
                                </tr>
                                <tr>
                                    <th>رابط الطلب</th>
                                    <td>{{payment.order_ref_str}}</td>
                                    <th>اسم المالك</th>
                                    <td>{{payment.owner_name}}</td>
                                </tr>
                                <tr>
                                    <th>نوع المالك</th>
                                    <td>{{payment.owner_type}}</td>
                                    <th>رابط المالك</th>
                                    <td>{{payment.owner_ref}}</td>
                                </tr>
                                <tr>
                                    <th>المشتريات</th>
                                    <td>{{payment.purchases}}</td>
                                    <th>اختبار</th>
                                    <td>{{payment.for_test}}</td>
                                </tr> 
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

    import {mapGetters} from 'vuex';
    export default{
        name:"paymentsDetails",
        computed: {
            ...mapGetters(["getUserData"])
        },
        data(){
            return{
                payment_ref: this.$route.params.payment_ref,
                loading: true,
                payment:{},   
            }
        },
        methods:{
            getData(){
                this.$http.get("/api/payments/get/"+this.payment_ref).then(response =>{
                    this.loading = false;
                    this.payment = response.data.row;
                }).catch(error => {
                    console.log(error)
                    this.$store.commit("SET_ERROR", "لدينا خطأ فني");
                })
            }
        },
        created(){
            window.scroll(0,0);
            this.$store.commit("SET_ERROR", "");
            this.$store.commit("SET_SUCCESS", "");

            this.getData();
        },
    }
    
</script>