<template>
   <div>
      <div class="row mb-5">
         <div class="col">
            <div class="card border-left-warning shadow h-100 py-2">
               <div class="card-body">
                  <form action="" @submit.prevent="search">
                     <div class="row">
                        <div class="col">
                           <div class="row">
                              <div class="col">
                                 <input type="text" v-model="mobile" class="form-control" placeholder="رقم الجوال" >
                              </div>
                           </div>
                        </div>
                        <div class="col-auto">
                           <button type="submit" class="btn btn-success">
                              <i class="fas fa-search"></i>
                           </button>
                           <button type="button" v-if="hasSearch" class="btn btn-light ml-2" @click.prevent="resetSearch">إلغاء</button>
                        </div>
                     </div>
                  </form>
               </div>
            </div>
         </div>
      </div>
      <div class="card shadow mb-4">
         <div class="card-header py-3">
            <div class="row">
               <div class="col col-sm-6">
                  <h6 class="m-0 font-weight-bold text-primary">طلبات الإنضمام</h6>
               </div>
               <div class="col col-sm-6 text-right">
                  <button class="btn btn-light" @click="exportData()" v-if="!exportLoading && (getUserData.is_admin || getUserData.can_export)"><i class="far fa-file-excel"></i> تصدير</button>
                  <span v-else-if="!exportError && (getUserData.is_admin || getUserData.can_export)">جاري تحميل الملف ،،، يرجى الانتظار ...</span>
                  <div class="alert alert-danger" v-if="exportError">حدث خطأ اثناء تحميل الملف</div>
               </div>
            </div>
         </div>

         <div class="card-body">
            <div class="row">
               <div class="col text-right pb-3 pagination-div">
                  <a href="" class="d-inline-block mr-2" v-if="prevPage.length" @click.prevent="goToPrev"><i class="far fa-caret-square-right"></i></a>
                  <div class="d-inline-block rounded border border-secondary">{{ pageNumber }}</div>
                  <a href="" class="d-inline-block ml-2" v-if="nextPage" @click.prevent="goToNext"><i class="far fa-caret-square-left"></i></a>
               </div>
            </div>
            <div class="table-responsive">
               <div class="big-loader text-center pt-50 pb-50" v-if="loading">
                  <loader />
               </div>
               <table v-if="!loading" class="table table-bordered table-striped text-center" id="dataTable" width="100%" cellspacing="0">
                  <thead>
                     <tr>
                        <th>#</th>
                        <th>الاسم</th>
                        <th>الجوال</th>
                        <th>تاريخ الانضمام</th>
                        <th>العمليات</th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr v-for="(row, index) in rows" :key="index">
                        <td v-if="pageNumber == 1">{{ (index + 1) }}</td>
                        <td v-else>{{ (index + 1) + ((pageNumber - 1)*40)}}</td>
                        <td>{{ row.name }}</td>
                        <td>{{ row.mobile }}</td>
                        <td>{{ row.signup_date  }}</td>
                        <td>
                           <router-link :to="'/users/'+row.id+'?back=providersRequests'" class="btn btn-info">
                              <i class="fas fa-edit"></i>
                           </router-link>
                        </td>
                     </tr>
                  </tbody>
               </table>
            </div>
         </div>
      </div>
   </div>
</template>

<script >
   import { mapGetters } from 'vuex';

   export default 
   {
      name: "ProvidersRequests",
      computed: {
         ...mapGetters(["getUserData"])
      },
      data(){
         return {
            loading: true,
            cities_loader: true,
            exportLoading: false,
            exportError: false,
            breadcrum: {
               "/": "الرئيسية",
               "#": "طلبات الإنضمام",
            },
            rows: [],
            pageNumber: 1,
            nextPage: null,
            prevBuffer: null,
            prevPage: [],
            hasSearch: false,
            mainUrl: "/api/providers/providersRequests",
            endPoint: "/api/providers/providersRequests",
            searchUrl: "",
            services: [],
            orderStatus: {
               draft: "Open",
               sent: "Scheduled",
               sale: "Live",
               done: "Done",
               cancel: "Cancelled",
               hold: "Hold",
               expired: "Expired",
               unavailable: "Unavailable",
               cancelled_by_user: "Canceled by user",
               cancelled_by_provider: "Cancelled by provider",
               reassign: "Reassign",
            },
            cities: [
               {
                  id: "not",
                  text: "بحث بالمدينة",
               }
            ],
            mobile: "",
            userType: "",
            sortingBy: "",
         }
      },
      methods: {
         getData(){
            this.$http.get(this.endPoint).then((response) => {
               this.loading = false;
               this.rows = response.data.rows;
               this.nextPage = response.data.next;
               if(this.prevBuffer != null && this.rows.length >= 40 && this.pageNumber != 1){
                  this.prevPage.push(this.prevBuffer);
               }
            }).catch(err => {
               this.$store.commit("SET_ERROR", err.data.error);
            });
         },
         goToNext(){
            this.prevBuffer = this.nextPage;
            this.endPoint = this.mainUrl + "?page=" + this.nextPage;
            if(this.searchUrl != "")
               this.endPoint = this.endPoint + "&" + this.searchUrl;
            this.loading = true;
            this.pageNumber = this.pageNumber + 1;
            this.getData();
         },
         goToPrev(){
            let prevIndex = this.prevPage.length - 1;
            
            if(this.pageNumber == 2){
               this.endPoint = this.mainUrl;
               if(this.searchUrl != "")
                  this.endPoint = this.endPoint + "?" + this.searchUrl;
            }else{
               this.endPoint = this.mainUrl + "?page=" + this.prevPage[prevIndex];
               if(this.searchUrl != "")
                  this.endPoint = this.endPoint + "&" + this.searchUrl;
            }
            
            this.$delete(this.prevPage, prevIndex);
            
            this.loading = true;
            this.pageNumber = this.pageNumber - 1;
            this.getData();
            if(this.pageNumber == 1){
               this.prevPage = [];
            }
         },
         search(){
            this.searchUrl = "";
            let searchParam = [];
            if(this.mobile.trim() != ""){
               searchParam.push("mobile="+this.mobile.trim());
            }
            if(this.userType != ""){
               searchParam.push("userType="+this.userType);
            }
            if(this.sortingBy != ""){
               searchParam.push("sortingBy="+this.sortingBy);
            }

            if(searchParam.length == 0){
               return;
            }
            this.searchUrl = searchParam.join("&");

            this.endPoint = this.mainUrl + "?" + searchParam.join("&");
            this.hasSearch = true;
            this.loading = true;
            this.pageNumber = 1;
            this.nextPage = null;
            this.prevPage = [];
            this.getData();
         },
         resetSearch(){
            this.mobile = "";
            this.searchUrl = "";
            this.userType = "";
            this.sortingBy = "";

            this.endPoint = this.mainUrl;
            this.loading = true;
            this.hasSearch = false;
            this.getData();
         },
         exportData(){
            this.exportLoading = true;
            this.exportError = false;
            this.$http.get("/api/export/providers/providerRequests", {responseType: "blob"}).then((response) => {
               var fileURL = window.URL.createObjectURL(new Blob([response.data]));
               var fileLink = document.createElement('a');

               fileLink.href = fileURL;
               fileLink.setAttribute('download', 'providers_data.xlsx');
               document.body.appendChild(fileLink);

               fileLink.click();
               this.exportLoading = false;
            }).catch((e) => {
               this.exportError = true;
               console.log(e);
            });
         },
      },
      created(){
         window.scroll(0,0);
         this.$store.commit("SET_ACTIVE_MENU", "providers_requests");
         this.$store.commit("SET_BREADCRUMB", this.breadcrum);
         this.$store.commit("SET_ERROR", "");
         this.$store.commit("SET_SUCCESS", "");
         this.$store.commit("SET_TITLE", "طلبات الإنضمام");
         
         this.getData();
      }
   }
</script>