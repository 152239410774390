<template>
   <div>
      <div class="row mb-3" v-if="getUserData.is_admin || getUserData.can_export">
         <div class="col text-right">
            <router-link :to="'/cities/create'" class="btn btn-light">
               <i class="far fa-file-excel"></i> إضافة مدينة 
            </router-link>
         </div>
      </div>

      <div class="card shadow mb-4">
         <div class="card-header py-3">
            <h6 class="m-0 font-weight-bold text-primary">المدن</h6>
         </div>

         <div class="card-body">
            <div class="table-responsive">
               <div class="big-loader text-center pt-50 pb-50" v-if="loading">
                  <loader />
               </div>
               <table v-if="!loading" class="table table-bordered table-striped text-center" id="dataTable" width="100%" cellspacing="0">
                  <thead>
                     <tr>
                        <th>الاسم عربي</th>
                        <th>الاسم انجليزي</th>
                        <th>الترتيب</th>
                        <th>قابل للشحن</th>
                        <th>قابل للتفريغ</th>
                        <th>العمليات</th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr v-for="(row, index) in rows" :key="index">
                        <td>{{ row.name_ar }}</td>
                        <td>{{ row.name_en }}</td>
                        <td>{{ row.seq }}</td>
                        <td>{{ (row.can_pickup)? 'نعم' : 'لا' }}</td>
                        <td>{{ (row.can_dropoff)? 'نعم' : 'لا' }}</td>
                        <td>
                           <router-link :to="'/cities/'+row.id" class="btn btn-info">
                              <i class="fas fa-edit"></i>
                           </router-link>
                        </td>
                     </tr>
                  </tbody>
               </table>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
   import { mapGetters } from 'vuex';
   export default 
   {
      name: "AllCities",
      components: {},
      computed: {
         ...mapGetters(["getUserData"])
      },
      data(){
         return {
            loading: true,
            breadcrum: {
               "/": "الرئيسية",
               "#": "المدن",
            },
            rows: [],
            pageNumber: 1,
            nextPage: null,
            prevPage: [],
            endPoint: "/api/towing/cities",
         }
      },
      methods: {
         getData(){
            this.$http.get(this.endPoint).then(response => {
               this.loading = false;
               this.rows = response.data.rows;
            }).catch(err => {
               this.$store.commit("SET_ERROR", err.data.error);
            });
         },
      },
      created(){
         window.scroll(0, 0);
         this.$store.commit("SET_ACTIVE_MENU", "all_cities");
         this.$store.commit("SET_BREADCRUMB", this.breadcrum);
         this.$store.commit("SET_ERROR", "");
         this.$store.commit("SET_SUCCESS", "");
         this.$store.commit("SET_TITLE", "المدن");
         
         this.getData();
      }
   }
</script>