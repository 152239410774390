<template>
   <div>
      <div class="row" v-if="loading">
         <div class="col">
            <div class="big-loader text-center pt-50 pb-50">
               <loader />
            </div>
         </div>
      </div>

      <div class="row pb-5" v-if="!hasError && !loading">
         <div class="col text-right">
            <!-- router-link :to="`/userForm/${user_ref}`" class="btn btn-warning"><i class="far fa-edit"></i> تعديل</router-link -->
            <a :href="`https://google.com/maps/search/${providerRow.location._latitude},${providerRow.location._longitude}`" target="_blank" v-if="providerRow && providerRow.location" class="btn btn-info ml-3">
               <i class="fas fa-map-marked-alt"></i> موقعه على الخريطة
            </a>
         </div>
      </div>

      <div class="row" v-if="!hasError && !loading">
         <div class="col">
            <div class="card shadow mb-4">
               <div class="card-body">
                  <div class="row mb-5" v-if="row.provider_img">
                     <div class="col text-center">
                        <img :src="row.provider_img" class="img-thumbnail profile-img">
                     </div>
                  </div>
                  <div class="row">
                     <div class="col">
                        <form action="" @submit.prevent="editUser">
                           <table class="table">
                              <tbody>
                                 <tr>
                                    <td class="w-50">
                                       <label for="">الاسم</label>
                                       <input type="text" v-model="name" class="form-control">
                                    </td>
                                    <td class="w-50">
                                       <label for="">الجوال</label>
                                       <div>{{ row.mobile }}</div>
                                    </td>
                                 </tr>
                                 <tr v-if="providerRow">
                                    <td>
                                       <strong class="mr-2">تاريخ الانشاء:</strong>
                                       {{( row.creation_date )? row.creation_date : 'لا يوجد'}}
                                    </td>
                                    <td>
                                       <strong class="mr-2">المدينة:</strong>
                                       {{providerRow.current_city}}
                                    </td>
                                 </tr>
                                 <tr>
                                    <td><strong>نوع الحساب:</strong> {{ (row.is_provider)? "مزود" : "عميل" }}</td>
                                    <td></td>
                                 </tr>
                                 <tr v-if="!providerRow">
                                    <td>
                                       <label for="">حالة الحظر</label>
                                       <select v-model="is_blocked" class="form-control">
                                          <option value="not_blocked">غير محظور</option>
                                          <option value="blocked">محظور</option>
                                       </select>
                                    </td>
                                    <td></td>
                                 </tr>
                              </tbody>
                           </table>
                           <table class="table">
                              <thead>
                                 <tr>
                                    <th>الرصيد القديم</th>
                                    <th>الرصيد كعميل</th>
                                    <th>الرصيد كمزود</th>
                                    <th>الرصيد في الشركة</th>
                                 </tr>
                              </thead>
                              <tbody>
                                 <tr>
                                    <td>{{ (row.balance)? row.balance: 'لا يوجد' }}</td>
                                    <td>{{ (row.wallet_user)? row.wallet_user : 'لا يوجد'}}</td>
                                    <td>{{ (row.wallet_provider)? row.wallet_provider: 'لا يوجد' }}</td>
                                    <td>{{ (row.wallet_company)? row.wallet_company : 'لا يوجد' }}</td>
                                 </tr>
                              </tbody>
                           </table>
                           <table class="table" v-if="providerRow">
                              <tbody>
                                 <tr>
                                    <td class="w-50"><strong>STC Mobile:</strong> {{ row.stc_mobile }}</td>
                                    <td class="w-50"><strong>الجنسية:</strong> {{ row.nationality }}</td>
                                 </tr>
                                 <tr>
                                    <td><strong>التوثيق:</strong> {{ (providerRow.trusted)? "موثق" : "غير موثق" }}</td>
                                    <td><strong>تاريخ التوثيق:</strong> {{ providerRow.trusted_date }}</td>
                                 </tr>
                                 <tr>
                                    <td>
                                       <label for="">حالة طلب الإنضمام</label>
                                       <select v-model="is_provider_approved" class="form-control">
                                          <option value="not_approved">بإنتظار الموافقة</option>
                                          <option value="approved">تمت الموافقة</option>
                                       </select>
                                    </td>
                                    <td>
                                       <label for="">حالة الحظر</label>
                                       <select v-model="is_blocked" class="form-control">
                                          <option value="not_blocked">غير محظور</option>
                                          <option value="blocked">محظور</option>
                                       </select>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td><strong>التقييم:</strong> {{ providerRow.review_avg }}</td>
                                    <td><strong>عدد المقيمين:</strong> {{ providerRow.review_count }}</td>
                                 </tr>
                                 <tr>
                                    <td><strong>تاريخ الميلاد:</strong> {{ row.birthday_date }}</td>
                                    <td><strong>حالة التسجيل فى الهيئة:</strong> {{ (row.citc_id && row.citc_id != '')? "مسجل" : "غير مسجل" }}</td>
                                 </tr>
                                 <tr>
                                    <td><strong>نوع الجوال:</strong> {{ (row.device)? row.device.deviceType : "" }}</td>
                                    <td><strong>اصدار التطبيق:</strong> {{ (row.device)? row.device.buildNumber : "" }}</td>
                                 </tr>
                                 <tr>
                                    <td><strong>ماركة السيارة:</strong> {{ row.carBrand }}</td>
                                    <td><strong>نوع السيارة:</strong> {{ row.carModel }} {{ row.carYear }}</td>
                                 </tr>
                                 <tr>
                                    <td><strong>رقم اللوحة:</strong> {{ row.carNumber }}</td>
                                    <td><strong>رقم الهوية:</strong> {{ row.identity_number }}</td>
                                 </tr>
                                 <tr>
                                    <td>
                                       <div><strong>صورة التأمين:</strong></div>
                                       <img :src="row.carInsuranceImg" v-if="row.carInsuranceImg" class="img-thumbnail thum-400">
                                    </td>
                                    <td>
                                       <div><strong>صورة الهوية:</strong></div>
                                       <img :src="row.identity_img" v-if="row.identity_img" class="img-thumbnail thum-400">
                                    </td>
                                 </tr>
                                 <tr>
                                    <td>
                                       <div><strong>صورة السيارة:</strong></div>
                                       <img :src="row.car_img" v-if="row.car_img" class="img-thumbnail thum-400">
                                    </td>
                                    <td>
                                       <div><strong>صورة رخصة السيارة:</strong></div>
                                       <img :src="row.car_license_img" v-if="row.car_license_img" class="img-thumbnail thum-400">
                                    </td>
                                 </tr>
                                 <tr>
                                    <td>
                                       <label for="">اولوية في قبول الطلبات</label>
                                       <select v-model="force_order_approval" class="form-control">
                                          <option value="no">لأ</option>
                                          <option value="yes">نعم قبول مباشر للطلبات</option>
                                       </select>
                                    </td>
                                    <td></td>
                                 </tr>
                                 <tr>
                                    <td>
                                       <label for="" class=""><strong>الشركة التابع لها المزود</strong></label>
                                       <select class="form-control" v-model="fazaa_company" aria-placeholder="الشركة التابع لها المزود">
                                          <option value="">الشركة الغير تابع لها المزود</option>
                                          <option v-for="(company,index) in companys" :key="index" :value="company.id" >{{company.name}}</option>
                                       </select>
                                    </td>
                                    <td>
                                       <label for=""><strong>نوع المزود</strong></label>
                                       <select class="form-control" v-model="fazaa_type" aria-placeholder="نوع المزود">                                          
                                          <option value="freelance">Freelance</option>
                                          <option value="supplier">Supplier</option>                                          
                                          <option value="workshop">Workshop</option>
                                          <option value="employee">Test Employee</option>
                                          <option value="internal">Internal Fazaa</option>
                                          <option value="other">Not Fazaa</option>
                                       </select>
                                    </td>
                                 </tr>
                              </tbody>
                           </table>
                           <div class="row">
                              <div class="col text-center">
                                 <button type="submit" class="btn btn-success" v-if="!formLoading">إرسال</button>
                                 <div class="text-center pt-2 pb-2" v-if="formLoading">
                                    <inlineloader />
                                 </div>
                              </div>
                           </div>
                        </form>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
   import alertifyjs from "alertifyjs";
   import { mapGetters } from 'vuex';

   export default {
      name: "UserForm",
      computed: {
         ...mapGetters(["getUserData"])
      },
      data(){
         return {
            user_ref: this.$route.params.user_ref,
            back: this.$route.query.back,
            loading: true,
            formLoading: false,
            hasError: false,
            row: [],
            providerRow: null,
            companys:[],
            rows:[],
            fazaa_company: "",
            fazaa_type: "",
            breadcrum: {
               "/": "الرئيسية",
            },

            name: "",
            is_blocked: "not_blocked",
            is_provider_approved: "not_approved",
            force_order_approval: "no",
         }
      },
      methods: {
         getData(){
            setTimeout(function (usr){
               if(!usr.is_admin && !usr.can_edit){
                  window.location.href = "/";
                  return;
               }
            },2000, this.getUserData);

            this.$http.get("/api/users/get/"+ this.user_ref).then(response => {
               this.loading = false;
               this.row = response.data.row;
               this.providerRow = response.data.providerRow;
               this.fazaa_company = this.row.fazaa_company;
               this.fazaa_type = this.row.fazaa_type;
               if(this.back){
                  this.breadcrum[`/users/${this.user_ref}?back=${this.back}`] = this.row.name;
               }else{
                  this.breadcrum[`/users/${this.user_ref}`] = this.row.name;
               }
               this.breadcrum["#"] = "تعديل بيانات";
               this.$store.commit("SET_BREADCRUMB", this.breadcrum);

               this.name = this.row.name;
               this.is_blocked = (this.row.is_blocked)? "blocked" : "not_blocked";
               this.is_provider_approved = (this.row.is_provider_approved)? "approved" : "not_approved";
            }).catch(error => {
               console.log(error);
               this.hasError = true;
               this.$store.commit("SET_ERROR", "لدينا خطأ فني");
            });
         },
         editUser(){
            this.formLoading = true;
            this.$store.commit("SET_ERROR", "");

            this.$http.post("/api/users/set/"+ this.user_ref, {
               name: this.name.trim(),
               is_blocked: this.is_blocked,
               is_provider_approved: this.is_provider_approved,
               force_order_approval: this.force_order_approval,
            }).then(response => {
               this.formLoading = false;
               if(response.data && response.data.status == "OK"){
                  alertifyjs.success("تم التعديل بنجاح");
                  this.$router.back();
               }else{
                  this.$store.commit("SET_ERROR", "لدينا خطأ فني");
               }
            }).catch(error => {
               this.formLoading = false;
               if(error.data && error.data.error){
                  this.$store.commit("SET_ERROR", error.data.error);
               }else{
                  this.$store.commit("SET_ERROR", "لدينا خطأ فني");
               }
            });
         },
         companies(){
            this.$http.get("/api/companies").then(response => {
               this.companys = response.data.rows;
            });
         }
      }, 
      created(){
         this.companies();
         if(this.back){
            if(this.back == "servicesApproval"){
               this.breadcrum["/servicesApproval"] = "طلبات الخدمات";
            }else if(this.back == "providersRequests"){
               this.breadcrum["/providersRequests"] = "طلبات الإنضمام";
            }
         }else{
            this.breadcrum["/users"] = "عملاء ومزودين";
         }

         this.$store.commit("SET_ERROR", "");
         this.$store.commit("SET_SUCCESS", "");

         if(this.user_ref != "create"){
            this.$store.commit("SET_TITLE", "تعديل بيانات");
            this.getData();
         }else{
            this.$store.commit("SET_TITLE", "إضافة جديد");
            this.breadcrum["#"] = "إضافة جديد";
         }
      },
   }
</script>