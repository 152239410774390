<template>
   <div>
      <div class="row" v-if="loading">
         <div class="col">
            <div class="big-loader text-center pt-50 pb-50">
               <loader />
            </div>
         </div>
      </div>

      <div class="row" v-if="!hasError && !loading">
         <div class="col">
            <div class="card shadow mb-4">
               <form action="" @submit.prevent="submit">
               <div class="card-body">
                  <div class="row justify-content-center">
                     <div class="col-sm-8">
                        <div class="form-group">
                           <label>الاسم:</label>
                           <input type="text" class="form-control" v-model="name" placeholder="الاسم">
                        </div>
                        <div class="form-group">
                           <label>البريد الإلكتروني:</label>
                           <input type="email" class="form-control" v-model="email" placeholder="البريد الإلكتروني">
                        </div>
                        <div class="form-group">
                           <label>كلمة المرور:</label>
                           <input type="password" class="form-control" v-model="password" placeholder="كلمة المرور">
                        </div>
                        <div class="form-group">
                           <label for="">الحاله</label>
                           <select class="form-control" v-model="status">
                              <option value="active">نشط</option>
                              <option value="inactive">غير نشط</option>
                           </select>
                        </div>
                        <fieldset>
                           <legend>الصلاحيات</legend>
                           <div class="form-group form-check">
                              <input type="checkbox" class="form-check-input" id="userIsAdmin" v-model="isAdmin">
                              <label class="form-check-label" for="userIsAdmin">مدير للموقع (يمتلك كل الصلاحيات)</label>
                           </div>
                           <div class="form-group form-check">
                              <input type="checkbox" class="form-check-input" id="userCanCreate" v-model="canCreate">
                              <label class="form-check-label" for="userCanCreate">امكانية الإضافة</label>
                           </div>
                           <div class="form-group form-check">
                              <input type="checkbox" class="form-check-input" id="userCanEdit" v-model="canEdit">
                              <label class="form-check-label" for="userCanEdit">امكانية التعديل</label>
                           </div>
                           <div class="form-group form-check">
                              <input type="checkbox" class="form-check-input" id="userCanExport" v-model="canExport">
                              <label class="form-check-label" for="userCanExport">امكانية التصدير</label>
                           </div>
                           <div class="form-group form-check">
                              <input type="checkbox" class="form-check-input" id="userCanSeeReports" v-model="canSeeReports">
                              <label class="form-check-label" for="userCanSeeReports">امكانية مشاهدة التقارير</label>
                           </div>
                        </fieldset>
                     </div>
                  </div>
               </div>
               <div class="card-footer text-center">
                  <button type="submit" class="btn btn-success" v-if="!formLoader">إرسال</button>
                  <div class="text-center pt-2 pb-2" v-if="formLoader">
                     <inlineloader />
                  </div>
               </div>
               </form>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
   import { mapGetters } from 'vuex';

   export default {
      name: "ManagerForm",
      computed: {
         ...mapGetters(["getUserData"])
      },
      data(){
         return {
            user_ref: this.$route.params.user_ref,
            loading: true,
            hasError: false,
            formLoader: false,
            name: "",
            email: "",
            password: "",
            isAdmin: false,
            canCreate: false,
            canEdit: false,
            canExport: false,
            canSeeReports: false,
            status:"active",
            breadcrum: {
               "/": "الرئيسية",
               "/managers": "مديرين الموقع",
            },
         }
      },
      methods: {
         getData(){
            setTimeout(function(usr){
               if(!usr.is_admin){
                  window.location.href = "/";
               return;
               }
            },2000, this.getUserData);
            
            this.$http.get("/api/auth/get/" + this.user_ref).then(response => {
               this.loading = false;
               this.name = response.data.row.name;
               this.email =response.data.row.email;
               this.isAdmin = response.data.row.is_admin;
               this.canCreate = response.data.row.can_create;
               this.canEdit = response.data.row.can_edit;
               this.canExport = response.data.row.can_export;
               this.status= response.data.row.status;
               this.canSeeReports = response.data.row.can_see_reports;
               this.breadcrum["#"] = this.name;
               this.$store.commit("SET_BREADCRUMB", this.breadcrum);
               this.$store.commit("SET_TITLE", this.name);
            }).catch(error => {
               console.log(error);
               this.hasError = true;
               this.$store.commit("SET_ERROR", "لدينا خطأ فني");
            });
         },
         submit(){
            this.$store.commit("SET_ERROR", "");
            this.$store.commit("SET_SUCCESS", "");

            if(this.email.trim() == "" || this.name.trim() == ""){
               this.$store.commit("SET_ERROR", "كل البيانات مطلوبة");
               return false;
            }

            if(this.user_ref == "create"){
               if(this.password == ""){
                  this.$store.commit("SET_ERROR", "كل البيانات مطلوبة");
                  return false;
               }
               if(this.password.length < 6){
                  this.$store.commit("SET_ERROR", "كلمة المرور يجب ان لا تقل عن 6 احرف");
                  return false;
               }

               this.formLoader = true;
               this.$http.post("/api/auth/create", {
                  name: this.name.trim(),
                  email: this.email.trim(),
                  password: this.password,
                  is_admin: this.isAdmin,
                  can_create: this.canCreate,
                  can_edit: this.canEdit,
                  can_export: this.canExport,
                  can_see_reports: this.canSeeReports,
                  status: this.status,
               }).then(() => {
                  this.formLoader = false;
                  this.$router.replace('/managers');
               }).catch(error => {
                  this.formLoader = false;
                  this.$store.commit("SET_ERROR", error.data.error);
               });
            }else{
               if(this.password != "" && this.password.length < 6){
                  this.$store.commit("SET_ERROR", "كلمة المرور يجب ان لا تقل عن 6 احرف");
                  return false;
               }

               this.formLoader = true;
               this.$http.post("/api/auth/edit/" + this.user_ref, {
                  name: this.name.trim(),
                  email: this.email.trim(),
                  password: this.password,
                  is_admin: this.isAdmin,
                  can_create: this.canCreate,
                  can_edit: this.canEdit,
                  can_export: this.canExport,
                  status:this.status,
                  can_see_reports: this.canSeeReports,
               }).then(() => {
                  this.formLoader = false;
                  this.$store.commit("SET_SUCCESS", "تم التعديل بنجاح");
               }).catch(error => {
                  this.formLoader = false;
                  this.$store.commit("SET_ERROR", error.data.error);
               });
            }
         }
      }, 
      created(){
         window.scroll(0,0);
         this.$store.commit("SET_ERROR", "");
         this.$store.commit("SET_SUCCESS", "");
         
         if(this.user_ref == "create"){
            this.breadcrum["#"] = 'إضافة مدير';
            this.loading = false;
         }

         this.$store.commit("SET_BREADCRUMB", this.breadcrum);
         this.$store.commit("SET_TITLE", "مديرين الموقع");

         if(this.user_ref != "create"){
            this.getData();
         }
      },
   }
</script>