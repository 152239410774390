<template>
   <div>
      <div class="row mb-4">
         <div class="col">
            <div class="card border-left-warning shadow h-100 py-2">
               <div class="card-body">
                  <form action="" @submit.prevent="getData">
                     <div class="row">
                        <div class="col">
                           <div class="row">
                              <div class="col">
                                 <select class="form-control" v-model="year" aria-placeholder="السنة">
                                    <option value="2021">2021</option>
                                    <option value="2022">2022</option>
                                 </select>
                              </div>
                              <div class="col">
                                 <select class="form-control" v-model="month" aria-placeholder="الشهر">
                                    <option value="01">01</option>
                                    <option value="02">02</option>
                                    <option value="03">03</option>
                                    <option value="04">04</option>
                                    <option value="05">05</option>
                                    <option value="06">06</option>
                                    <option value="07">07</option>
                                    <option value="08">08</option>
                                    <option value="09">09</option>
                                    <option value="10">10</option>
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                 </select>
                              </div>
                           </div>
                        </div>
                        <div class="col-auto">
                           <button type="submit" class="btn btn-success">
                              <i class="fas fa-search"></i>
                           </button>
                        </div>
                     </div>
                  </form>
               </div>
            </div>
         </div>
      </div>

      <div class="row" v-if="loading">
         <div class="col">
            <div class="big-loader text-center pt-50 pb-50">
               <loader />
            </div>
         </div>
      </div>

      <div class="row mb-4" v-if="!loading">
         <div class="col">
            <div class="card border-left-success shadow py-2">
               <div class="card-body">
                  <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                           <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                              طلبات مكتملة بنجاح
                           </div>
                           <div class="h5 mb-0 font-weight-bold text-gray-800">{{ data.orders_completed }}</div>
                        </div>
                        <div class="col-auto">
                           <i class="fas fa-thumbs-up fa-2x text-gray-300"></i>
                        </div>
                  </div>
               </div>
            </div>
         </div>
         <div class="col">
            <div class="card border-left-danger shadow py-2">
               <div class="card-body">
                  <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                           <div class="text-xs font-weight-bold text-danger text-uppercase mb-1">
                              طلبات غير مكتملة
                           </div>
                           <div class="h5 mb-0 font-weight-bold text-gray-800">{{ data.orders_not_completed }}</div>
                        </div>
                        <div class="col-auto">
                           <i class="fas fa-thumbs-down fa-2x text-gray-300"></i>
                        </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="card shadow mb-4" v-if="!loading">
         <div class="card-header py-3">
            <h6 class="m-0 font-weight-bold text-primary">تقرير الطلبات</h6>
         </div>

         <div class="card-body">
            <div class="row mb-5">
               <div class="col pt-4">
                  <div class="chart-h-300">
                     <ChartLine :data="statusChart" :options="chartOption" />
                  </div>
               </div>
               <div class="col-auto">
                  <table class="table table-striped table-sm">
                     <thead>
                        <tr>
                           <th>الحالة</th>
                           <th>العدد</th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr v-for="(key, val) in data.status" :key="val">
                           <td>{{ (orderStatus[val])? orderStatus[val] : val }}</td>
                           <td>{{ key }}</td>
                        </tr>
                     </tbody>
                  </table>
               </div>
            </div>

            <div class="row mb-5">
               <div class="col"><hr></div>
            </div>

            <div class="row mb-5">
               <div class="col">
                  <div class="chart-h-500">
                     <ChartLine :data="servicesChart" :options="chartOption" />
                  </div>
               </div>
            </div>

            <div class="row mb-5">
               <div class="col"><hr></div>
            </div>

            <div class="row mb-5">
               <div class="col">
                  <DataTable
                     title="بيانات الخدمات"
                     :columns="tableColums"
                     :rows="data.services"
                     :exportable="false"
                     v-if="data.services"
                  />
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
   import ChartLine from "@/components/ChartLine.vue";
   import DataTable from "vue-materialize-datatable";

   export default 
   {
      name: "OrdersReport",
      components: {
         ChartLine, DataTable
      },
      data(){
         return {
            loading: true,
            breadcrum: {
               "/": "الرئيسية",
               "#": "تقرير الطلبات",
            },
            year: "",
            month: "",
            orderStatus: {
               draft: "Open",
               sent: "Scheduled",
               sale: "Live",
               approved: "Approved",
               done: "Done",
               cancel: "Cancelled",
               hold: "Hold",
               expired: "Expired",
               unavailable: "Unavailable",
               cancelled_by_user: "Canceled by user",
               cancelled_by_provider: "Cancelled by provider",
               reassign: "Reassign",
            },
            data: {},
            chartOption: {
               responsive: true,
               maintainAspectRatio: false
            },
            statusChart: {},
            servicesChart: {},
            tableColums: [
               {
                  label: "الخدمة",
                  field: "name",
                  numeric: false,
                  html: false
               },
               {
                  label: "Done",
                  field: "done",
                  numeric: true,
                  html: false
               },
               {
                  label: "Expired",
                  field: "expired",
                  numeric: true,
                  html: false
               },
               {
                  label: "Canceled by user",
                  field: "cancelled_by_user",
                  numeric: true,
                  html: false
               },
               {
                  label: "Cancelled by provider",
                  field: "cancelled_by_provider",
                  numeric: true,
                  html: false
               },
            ],
         }
      },
      methods: {
         getData(){
            this.loading = true;
            this.$store.commit("SET_ERROR", "");
            this.$http.get("/api/reports/orders/" + this.year + this.month).then(response => {
               this.loading = false;
               this.data = response.data;

               if(this.data.status){
                  let statusLabels = [];
                  let statusData = [];
                  for(const [key, val] of Object.entries(this.data.status)){
                     statusLabels.push((this.orderStatus[key])? this.orderStatus[key] : key);
                     statusData.push(val);
                  }
                  this.statusChart = {
                     labels: statusLabels,
                     datasets: [{
                        label: "حالات الطلبات خلال الشهر",
                        backgroundColor: "#36b9cc",
                        hoverBackgroundColor: "#2e59d9",
                        borderColor: "#36b9cc",
                        data: statusData,
                     }],
                  };
               }

               if(this.data.services){
                  let servicesLabels = [];
                  let servicesData = [];
                  this.data.services.forEach(element => {
                     servicesLabels.push(element.name);
                     servicesData.push((element.done)? element.done : 0);
                  });
                  this.servicesChart = {
                     labels: servicesLabels,
                     datasets: [{
                        label: "الخدمات المكتملة بنجاح",
                        backgroundColor: "#28a745",
                        hoverBackgroundColor: "#2e59d9",
                        borderColor: "#28a745",
                        data: servicesData,
                     }],
                  };
               }
            }).catch(err => {
               this.$store.commit("SET_ERROR", err.data.error);
            });
         },
      },
      created(){
         this.$store.commit("SET_ACTIVE_MENU", "orders_report");
         this.$store.commit("SET_BREADCRUMB", this.breadcrum);
         this.$store.commit("SET_ERROR", "");
         this.$store.commit("SET_SUCCESS", "");
         this.$store.commit("SET_TITLE", "تقرير الطلبات");
         
         let date = new Date();
         this.year = date.getFullYear();
         if(date.getMonth() < 10){
            this.month = `0${date.getMonth()+1}`;
         }else{
            this.month = date.getMonth()+1;
         }
         
         this.getData();
      }
   }
</script>

<style>
  .chart-h-500 {
    position: relative;
    height: 500px;
  }
  .chart-h-500 > div {
    position: relative;
    height: 100%;
  }
</style>