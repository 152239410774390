<template>
   <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">

      <!-- Sidebar - Brand -->
      <router-link class="sidebar-brand d-flex align-items-center justify-content-center" to="/">
         <div class="sidebar-brand-text mx-3 pt-3 pb-3">
            <img src="/img/logo_mini.png" class="img-fluid">
         </div>
      </router-link>

      <!-- Divider -->
      <hr class="sidebar-divider my-0">

      <!-- Nav Item - Dashboard -->
      <li class="nav-item" v-bind:class="{active: activeMenu == ''}">
         <router-link class="nav-link" to="/">
            <i class="fas fa-fw fa-tachometer-alt"></i>
            <span>Dashboard</span>
         </router-link>
      </li>

      <!-- Divider -->
      <hr class="sidebar-divider">

      <!-- Heading -->
      <div class="sidebar-heading">
         خدمة العملاء
      </div>

      <!-- Nav Item - Pages Collapse Menu -->
      <li class="nav-item">
         <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseTwo"
            aria-expanded="true" aria-controls="collapseTwo">
            <i class="fas fa-truck"></i>
            <span>الطلبات</span>
         </a>
         <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
            <div class="bg-white py-2 collapse-inner rounded">
               <router-link to="/OrderdashBoared" class="collapse-item" v-bind:class="{active: activeMenu =='OrderdashBoared'}">ملخص الطلبات</router-link>
               <!-- <router-link to="/All_Cities" class="collapse-item" v-bind:class="{active: activeMenu =='All_Cities'}">ملخص المدن</router-link> -->
               <router-link to="/allOrders" class="collapse-item" v-bind:class="{active: activeMenu == 'all_orders'}">جميع الطلبات</router-link>
               <router-link to="/Follow" class="collapse-item" v-bind:class="{active: activeMenu == 'Follow'}">متابعة الطلبات </router-link>
            </div>
         </div>
      </li>
      <li class="nav-item" v-bind:class="{active: activeMenu == 'users'}">
         <router-link to="/users" class="nav-link">
            <i class="fas fa-user-friends"></i>
            <span>عملاء ومزودين</span>
         </router-link>
      </li>

      <li class="nav-item">
         <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseProviders"
            aria-expanded="true" aria-controls="collapseProviders">
            <i class="fas fa-user-tag"></i>
            <span>مزودين الخدمة</span>
         </a>
         <div id="collapseProviders" class="collapse" aria-labelledby="headingSetting" data-parent="#accordionSidebar">
            <div class="bg-white py-2 collapse-inner rounded">
               <router-link to="/servicesApproval" class="collapse-item" v-bind:class="{active: activeMenu == 'services_approval'}">طلبات الخدمات</router-link>
               <router-link to="/providersRequests" class="collapse-item" v-bind:class="{active: activeMenu == 'providers_requests'}">طلبات الإنضمام</router-link>
               <router-link to="/providersRejected" class="collapse-item" v-bind:class="{active: activeMenu == 'providers_Rejected'}">طلبات المحظورة</router-link>
               <router-link to="/providersBlocked" class="collapse-item" v-bind:class="{active: activeMenu == 'providers_Blocked'}">طلبات المرفوضه</router-link>
            </div>
         </div>
      </li>
      <li class="nav-item" v-bind:class="{active: activeMenu == 'operationCompanies'}">
         <a href="/operationCompanies" class="nav-link" >
         <i class="fas fa-building "></i>
         <span>الشركات المشغلة</span>
         </a>
      </li>

      <!-- Divider -->
      <hr class="sidebar-divider">
      <div class="sidebar-heading">
         تسويق
      </div>

      <li class="nav-item" v-bind:class="{active: activeMenu == 'packages'}">
         <router-link to="/packages" class="nav-link">
            <i class="fas fa-cubes"></i>
            <span>الباقات</span>
         </router-link>
      </li>

      <!-- Divider -->
      <hr class="sidebar-divider">
      <div class="sidebar-heading">
         المالية
      </div>

      <li class="nav-item" v-bind:class="{active: activeMenu == 'transactions'}">
         <router-link to="/transactions" class="nav-link">
            <i class="fas fa-user-friends"></i>
            <span>المعاملات المالية</span>
         </router-link>
      </li>

      <li class="nav-item" v-bind:class="{active: activeMenu == 'newTransactions'}">
         <router-link to="/newTransactions" class="nav-link">
            <i class="fas fa-user-friends"></i>
            <span>المعاملات المالية الجديدة</span>
         </router-link>
      </li>

      <hr class="sidebar-divider" v-if="getUserData.is_admin || getUserData.can_see_reports">
      <div class="sidebar-heading" v-if="getUserData.is_admin || getUserData.can_see_reports">
         التقارير
      </div>

      <li class="nav-item" v-bind:class="{active: activeMenu == 'all_providers'}" v-if="getUserData.is_admin || getUserData.can_see_reports">
         <router-link to="/allProviders" class="nav-link">
            <i class="fas fa-street-view"></i>
            <span>عين الصقر</span>
         </router-link>
      </li>
      <li class="nav-item" v-bind:class="{active: activeMenu == 'orders_report'}" v-if="getUserData.is_admin || getUserData.can_see_reports">
         <router-link to="/ordersReport" class="nav-link">
            <i class="fas fa-truck-moving"></i>
            <span>تقرير الطلبات</span>
         </router-link>
      </li>
      <li class="nav-item" v-bind:class="{active: activeMenu == 'cities_report'}" v-if="getUserData.is_admin || getUserData.can_see_reports">
         <router-link to="/citiesReport" class="nav-link">
            <i class="fas fa-map-marked-alt"></i>
            <span>تقرير المدن</span>
         </router-link>
      </li>
      <li class="nav-item" v-bind:class="{active: activeMenu == 'clients_report'}" v-if="getUserData.is_admin || getUserData.can_see_reports">
         <router-link to="/clientsReport" class="nav-link">
            <i class="fas fa-user"></i>
            <span>تقرير العملاء</span>
         </router-link>
      </li>
      <li class="nav-item" v-bind:class="{active: activeMenu == 'providers_report'}" v-if="getUserData.is_admin || getUserData.can_see_reports">
         <router-link to="/providersReport" class="nav-link">
            <i class="fas fa-user-tag"></i>
            <span>تقرير المزودين</span>
         </router-link>
      </li>
      <li class="nav-item" v-bind:class="{active: activeMenu == 'places_report'}" v-if="getUserData.is_admin || getUserData.can_see_reports">
         <router-link to="/placesReport" class="nav-link">
            <i class="fas fa-utensils"></i>
            <span>تقرير المطاعم</span>
         </router-link>
      </li>
      <li class="nav-item" v-bind:class="{active: activeMenu == 'accounting_report'}" v-if="getUserData.is_admin || getUserData.can_see_reports">
         <router-link to="/accountingReport" class="nav-link">
            <i class="fas fa-file-invoice-dollar"></i>
            <span>تقرير المالية</span>
         </router-link>
      </li>
      <li class="nav-item" v-bind:class="{active: activeMenu == 'Logging'}" v-if="getUserData.is_admin || getUserData.can_see_reports">
         <router-link to="/logging" class="nav-link">
            <i class="fas fa-user-secret"></i>
            <span>سجل العمليات</span>
         </router-link>
      </li>

      <hr class="sidebar-divider">

      <li class="nav-item" v-if="getUserData.is_admin">
         <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseSetting"
            aria-expanded="true" aria-controls="collapseSetting">
            <i class="fas fa-cogs"></i>
            <span>الإعدادات العامة</span>
         </a>
         <div id="collapseSetting" class="collapse" aria-labelledby="headingSetting" data-parent="#accordionSidebar">
            <div class="bg-white py-2 collapse-inner rounded">
               <router-link to="/managers" class="collapse-item" v-bind:class="{active: activeMenu == 'managers'}">مديرين الموقع</router-link>
               <router-link to="/cities" class="collapse-item" v-bind:class="{active: activeMenu == 'all_cities'}">مدن السطحات</router-link>
               <router-link to="/lines" class="collapse-item" v-bind:class="{active: activeMenu == 'all_lines'}">خطوط السطحات</router-link>
            </div>
         </div>
      </li>

   </ul>
</template>

<script>
   import { mapGetters } from 'vuex';

   export default {
      name: "Menu",
      props: ["activeMenu"],
      computed: {
         ...mapGetters(["getUserData"])
      },
   }
</script>