<template>
    <div>
        <div class=" row pb-5">
            <div class="col text-right">
                <button class="btn btn-light UpDate-btn" @click.prevent="UpDate()">
                    <i class="fas fa-sync-alt mr-2"></i> تحديث الصفحة  
                </button>
            </div>
        </div>
        
        <div class="card-header py3">
            <h6 class="m-0 font-weight-bold text-primary">متابعة الطلبات</h6>
        </div>
        <div class="card shadow mb-4">
            <div class="card-body">
                <div class="row">
                    <div class="col text-right pb-3 pagination-div">
                        <a href="" class="d-inline-block mr-2" v-if="prevPage.length" @click.prevent="goToPrev"><i class="far fa-caret-square-right"></i></a>
                        <div class="d-inline-block rounded border border-secondary">{{pageNumber}}</div>
                        <a href="" class="d-inline-block ml-2" v-if="nextPage" @click.prevent="goToPrev"></a>
                    </div>
                </div>
                <div class="table-responsive">
                    <div class="big-loader text-center pt-50 pb-50" v-if="loading">
                        <loader />
                    </div>
                    <table v-if="!loading" class="table table-bordered table-striped text-center" id="dataTable" width="100%" cellspacing="0">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>الخدمة</th>
                                <th>العميل</th>
                                <th>المزود</th>
                                <th>قيمةالخدمة</th>
                                <th>الاجمالي</th>
                                <th>الدفع</th>
                                <th>المدينة</th>
                                <th>الحالة</th>
                                <th>التاريخ</th>
                                <th>العمليات</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(row ,index) in rows" :key="index">
                                <td> {{row.order_number}} </td>
                                <td> {{(services[row.main_services_code])? services[row.main_services_code] : row.services_name }}</td>
                                <td>{{row.user_name}} <br /> {{ row.user_mobile }}</td>
                                <td> {{ row.provider_name }} <br /> {{row.provider_mobile}} </td>
                                <td> {{row.service_cost }}</td>
                                <td> {{row.total_price }}</td>
                                <td> {{row.payment_method }}</td>
                                <td> {{ row.city }}</td>
                                <td>{{(orderStatus[row.status])? orderStatus[row.status] : row.status }} </td>
                                <td>{{row.order_time}}</td>
                                <td>
                                    <router-link :to="'/orderEdit/'+row.id" class="btn btn-info" >
                                        <i class="fas fa-edit"></i>
                                    </router-link>   
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                    <div class="row">
                        <div class="col text-right pb-3 pagination-div">
                        <a href="" class="d-inline-block mr-2" v-if="prevPage.length" @click.prevent="goToPrev"><i class="far fa-caret-square-right"></i></a>
                        <div class="d-inline-block rounded border border-secondary">{{ pageNumber }}</div>
                        <a href="" class="d-inline-block ml-2" v-if="nextPage" @click.prevent="goToNext"><i class="far fa-caret-square-left"></i></a>
                        </div>
                    </div>
            </div>
        </div>
    </div>
</template>

<script>
     import { mapGetters } from 'vuex';
     
    export default {
        name:"Follow",
        computed: {
         ...mapGetters(["getUserData"])
        },
        data(){
        return {
            loading: true,
            cities_loader: true,
            breadcrum:{
                "/" : "الرئيسية",
                "#" : "متابعة الطلبات"
            },
            rows:[],
            pageNumber: 1,
            nextPage: null,
            prevPage:[],
            mainUrl:"/api/orders?",
            endPoint: "/api/orders?",
            services:[],
            hasError: false,
            cities: [
               {
                  id: "not",
                  text: "بحث بالمدينة",
               }
            ],
            orderStatus:{
                draft: "open",
                sent: "Scheduled",
                sale: "Live",
                approved: "Approved",
                done : "Done",
                cancel: "Cancelled",
                hold: "Hold",
                expired: "Expired",
                Unavailable:"Unavailable",
                cancelled_by_user: "Cancelled by user",
                cancelled_by_provider: "Cancelled by provider",
                reassign: "Reassign",
            },
        }
    },
    methods:{
        getCities(){
            this.$http.get("/api/orders/cities").then(response => {
               this.cities_loader = false;
               this.cities = response.data.data;
               this.services = response.data.services;
            }).catch(error => {
               this.$store.commit("SET_ERROR", error.data.error);
            });
         },
        getData (){
            setTimeout(function(usr){
               if(!usr.is_admin && !usr.can_edit){
                  window.location.href = "/";
                  return;
               }
            }, 2000, this.getUserData);

            this.$http.get( this.endPoint +"status=followNeeds").then(response => {
               this.loading = false ;
               this.rows = response.data.rows;
               this.nextPage = response.data.next;
            });
        },
         goToNext(){
            this.prevPage.push(this.nextPage);
            this.loading= true;
            this.endPoint= this.mainUrl + "page=" + this.nextPage + "&";
            this.pageNumber = this.pageNumber + 1;
            this.getData();
        },
         goToPrev(){
            let prevIndex= this.prevPage.length -1;
            if(this.pageNumber== 2){
               this.endPoint= this.mainUrl;
            }else{
               this.endPoint= this.mainUrl + "page=" + this.prevPage[prevIndex]+ "&";
            }
            this.$delete(this.prevPage , prevIndex);
            this.loading = true;
            this.pageNumber = this.pageNumber -1 ;
            this.getData();
            if(this.pageNumber== 1) {
               this.prevPage= [];
            } 
        },
        UpDate(){
            this.loading= true,
            this.getData();
        },  
    },
    created(){
        window.scroll(0, 0);
        this.$store.commit("SET_ACTIVE_MENU", "Follow" );
        this.$store.commit("SET_BREADCRUMB" , this.breadcrum);
        this.$store.commit("SET_ERROR" , "");
        this.$store.commit("SET_SUCCESS" , "" );
        this.$store.commit("SET_TITLE", "متابعة الطلبات");
        this.getData();
        this.getCities();
        this.UpDate();
       
    }       
};               
</script>