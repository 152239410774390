<template>
   <div>
      <div class="row mb-3" v-if="getUserData.is_admin || getUserData.can_export">
         <div class="col text-right">
            <button type="button" class="btn btn-light" data-toggle="modal" data-target="#exportModal">
               <i class="far fa-file-excel"></i> تصدير الطلبات 
            </button>
         </div>
      </div>

      <div class="row mb-5">
         <div class="col">
            <div class="card border-left-warning shadow h-100 py-2">
               <div class="card-body">
                  <form action="" @submit.prevent="search">
                     <div class="row">
                        <div class="col">
                           <div class="row">
                              <div class="col">
                                 <input type="text" v-model="searchOrder" class="form-control" placeholder="رقم الطلب" >
                              </div>
                              <div class="col">
                                 <input type="text" v-model="searchClient" class="form-control" placeholder="جوال العميل" >
                              </div>
                              <div class="col">
                                 <input type="text" v-model="searchProvider" class="form-control" placeholder="جوال المزود" >
                              </div>
                              <div class="col">
                                 <select class="form-control" v-model="searchService" aria-placeholder="الخدمة">
                                    <option value="">الخدمة</option>
                                    <option v-for="(key, value) in services" :key="value" :value="value">{{ key }}</option>
                                 </select>
                              </div>
                              <div class="col">
                                 <select class="form-control" v-model="searchStatus" aria-placeholder="الحالة">
                                    <option value="">حالة الطلب</option>
                                    <option v-for="(key, value) in orderStatus" :key="value" :value="value">{{ key }}</option>
                                 </select>
                              </div>
                              <div class="col">
                                 <Select2 v-model="searchCity" :options="cities" :settings="{placeholder: 'بحث بالمدينة'}" />
                              </div>
                              <div class="col">
                                 <select class="form-control" v-model="searchAssign" aria-placeholder="المتابعة">
                                    <option value="">جميع الطلبات</option>
                                    <option value="me">طلباتي فقط</option>
                                 </select>
                              </div>
                           </div>
                        </div>
                        <div class="col-auto">
                           <button type="submit" class="btn btn-success">
                              <i class="fas fa-search"></i>
                           </button>
                           <button type="button" v-if="hasSearch" class="btn btn-light ml-2" @click.prevent="resetSearch">إلغاء</button>
                        </div>
                     </div>
                  </form>
               </div>
            </div>
         </div>
      </div>

      <div class="card shadow mb-4">
         <div class="card-header py-3">
            <h6 class="m-0 font-weight-bold text-primary">الطلبات</h6>
         </div>

         <div class="card-body">
            <div class="row">
               <div class="col text-right pb-3 pagination-div">
                  <a href="" class="d-inline-block mr-2" v-if="prevPage.length" @click.prevent="goToPrev"><i class="far fa-caret-square-right"></i></a>
                  <div class="d-inline-block rounded border border-secondary">{{ pageNumber }}</div>
                  <a href="" class="d-inline-block ml-2" v-if="nextPage" @click.prevent="goToNext"><i class="far fa-caret-square-left"></i></a>
               </div>
            </div>
            <div class="table-responsive">
               <div class="big-loader text-center pt-50 pb-50" v-if="loading">
                  <loader />
               </div>
               <table v-if="!loading" class=" text-center table table-bordered table-striped " id="dataTable" width="100%" cellspacing="0">
                  <thead>
                     <tr>
                        <th>#</th>
                        <th>رقم الطلب</th>
                        <th>الخدمة</th>
                        <th>العميل</th>
                        <th>المزود</th>
                        <th>قيمة الخدمة</th>
                        <th>الاجمالي</th>
                        <th>الدفع</th>
                        <th>المدينة</th>
                        <th>الحالة</th>
                        <th>التاريخ</th>
                        <th>العمليات</th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr v-for="(row, index) in rows" :key="index">
                        <td v-if="pageNumber == 1">{{( index + 1 )}}</td>
                        <td v-else>{{( index + 1) + ((pageNumber - 1 )*40)}}</td>
                        <td>
                           <router-link :to="'/order/'+row.id+'/'+row.status"  >
                              {{ row.order_number }}
                           </router-link>                        
                        </td>

                        <td>{{ (services[row.main_service_code])? services[row.main_service_code] : row.service_name }}</td>

                        <td>
                           <router-link :to= "'/users/'+ row.user_ref_str">
                              {{ row.user_name }}<br />{{ row.user_mobile }}
                           </router-link>
                        </td>
                        
                        <td>
                           <router-link :to= "'/users/'+ row.provider_ref_str "> 
                              {{ row.provider_name }}<br />{{ row.provider_mobile }}
                           </router-link>
                        </td>

                        <td>{{ row.service_cost }}</td>
                        <td>{{ row.total_price }}</td>
                        <td>{{ row.payment_method }}</td>
                        <td>{{ row.city }}</td>
                        <td>{{ (orderStatus[row.status])? orderStatus[row.status] : row.status }}</td>
                        <td>{{ row.order_time }}</td>
                        <td>
                           <router-link :to="'/order/'+row.id+'/'+row.status" class="btn btn-info">
                              <i class="fas fa-edit"></i>
                           </router-link>
                        </td>
                     </tr>
                  </tbody>
               </table>
            </div>
            <div class="row">
               <div class="col text-right pb-3 pagination-div">
                  <a href="" class="d-inline-block mr-2" v-if="prevPage.length" @click.prevent="goToPrev"><i class="far fa-caret-square-right"></i></a>
                  <div class="d-inline-block rounded border border-secondary">{{ pageNumber }}</div>
                  <a href="" class="d-inline-block ml-2" v-if="nextPage" @click.prevent="goToNext"><i class="far fa-caret-square-left"></i></a>
               </div>
            </div>
         </div>
      </div>

      <div class="modal fade" id="exportModal" tabindex="-1" aria-labelledby="exportModalLabel" aria-hidden="true">
         <div class="modal-dialog modal-lg">
            <div class="modal-content">
               <div class="modal-header">
                  <h5 class="modal-title" id="exportModalLabel">تصدير الطلبات</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                     <span aria-hidden="true">&times;</span>
                  </button>
               </div>
               <form action="" @submit.prevent="exportOrders">
                  <div class="modal-body">
                     <div class="row">
                        <div class="col">
                           <div class="alert alert-warning">يرجى العلم ان التصدير قد يأخذ بعض الوقت بناء على الفترة المحددة للتصدير</div>
                        </div>
                     </div>
                     <div class="row" v-if="exportError != ''">
                        <div class="col">
                           <div class="alert alert-danger">{{ exportError }}</div>
                        </div>
                     </div>
                     <div class="row" v-if="exportDone">
                        <div class="col">
                           <div class="alert alert-success">تم تصدير الملف بنجاح</div>
                        </div>
                     </div>
                     <div class="row">
                        <div class="col ltr">
                           <datepicker v-model="exportStart" format="yyyy-MM-dd" input-class="form-control" placeholder="من تاريخ"></datepicker>
                        </div>
                        <div class="col ltr">
                           <datepicker v-model="exportEnd" format="yyyy-MM-dd" input-class="form-control" placeholder="إلى تاريخ"></datepicker>
                        </div>
                        <div class="col">
                           <select class="form-control" v-model="exportStatus" aria-placeholder="الحالة">
                              <option value="all">الكل</option>
                              <option v-for="(key, value) in orderStatus" :key="value" :value="value">{{ key }}</option>
                           </select>
                        </div>
                        <div class="col">
                           <select class="form-control" v-model="exportPayment" aria-placeholder="الدفع">
                              <option value="all">الكل</option>
                              <option value="Cash">Cash</option>
                              <option value="online">Online Payments</option>
                              <option value="ApplePay">ApplePay</option>
                              <option value="CreditCard">CreditCard</option>
                              <option value="Wallet">Wallet</option>
                              <option value="STCPay">STCPay</option>
                              <option value="Credit">Credit</option>
                              <option value="waiting_for_payment">Waiting for payment</option>
                              <option value="rejected">Rejected</option>
                              <option value="cancelled_for_payment">Cancelled_for_payment</option>
                           </select>
                        </div>
                     </div>
                  </div>
                  <div class="modal-footer">
                     <inlineloader v-if="exportLoading" />
                     <button type="submit" class="btn btn-primary" v-else>تصدير</button>
                     <button type="button" class="btn btn-secondary" data-dismiss="modal">إغلاق</button>
                  </div>
               </form>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

   import Select2 from 'v-select2-component';
   import Datepicker from 'vuejs-datepicker';
   import { mapGetters } from 'vuex';

   export default 
   {
      name: "AllOrders",
      components: {
         Select2, Datepicker
      },
      computed: {
         ...mapGetters(["getUserData"])
      },
      data(){
         return {
            loading: true,
            cities_loader: true,
            breadcrum: {
               "/": "الرئيسية",
               "#": "الطلبات",
            },
            rows: [],
            pageNumber: 1,
            nextPage: null,
            prevPage: [],
            hasSearch: false,
            mainUrl: "/api/orders",
            endPoint: "/api/orders",
            searchUrl: "",
            services: [],
            orderStatus: {
               draft: "Open",
               sent: "Scheduled",
               sale: "Live",
               approved: "Approved",
               done: "Done",
               cancel: "Cancelled",
               hold: "Hold",
               expired: "Expired",
               unavailable: "Unavailable",
               cancelled_by_user: "Canceled by user",
               cancelled_by_provider: "Cancelled by provider",
               reassign: "Reassign",
               waiting_for_payment:"Waiting for payment",
               rejected: "Rejected",
               cancelled_for_payment:"Cancelled_for_payment",
            },
            cities: [
               {
                  id: "not",
                  text: "بحث بالمدينة",
               }
            ],
            searchOrder: "",
            searchClient: "",
            searchProvider: "",
            searchCity: "not",
            searchStatus: "",
            searchService: "",
            searchAssign: "",
            exportStart: "",
            exportEnd: "",
            exportStatus: "done",
            exportPayment: "all",
            exportError: "",
            exportLoading: false,
            exportDone: false,
           
         }
      },
      methods: {
         getCities(){
            this.$http.get("/api/orders/cities").then(response => {
               this.cities_loader = false;
               this.cities = response.data.data;
               this.services = response.data.services;
            }).catch(error => {
               this.$store.commit("SET_ERROR", error.data.error);
            });
         },
         getData(){
            this.$http.get(this.endPoint).then(response => {
               this.loading = false;
               this.rows = response.data.rows;
               this.nextPage = response.data.next;
            }).catch(err => {
               this.$store.commit("SET_ERROR", err.data.error);
            });
         },
         goToNext(){
            this.prevPage.push(this.nextPage);
            this.endPoint = this.mainUrl + "?page=" + this.nextPage;
            if(this.searchUrl != "")
               this.endPoint = this.endPoint + "&" + this.searchUrl;
            this.loading = true;
            this.pageNumber = this.pageNumber + 1;
            this.getData();
         },
         goToPrev(){
            let prevIndex = this.prevPage.length - 1;
            
            if(this.pageNumber == 2){
               this.endPoint = this.mainUrl;
               if(this.searchUrl != "")
                  this.endPoint = this.endPoint + "?" + this.searchUrl;
            }else{
               this.endPoint = this.mainUrl + "?page=" + this.prevPage[prevIndex];
               if(this.searchUrl != "")
                  this.endPoint = this.endPoint + "&" + this.searchUrl;
            }
            
            this.$delete(this.prevPage, prevIndex);
            
            this.loading = true;
            this.pageNumber = this.pageNumber - 1;
            this.getData();
            if(this.pageNumber == 1){
               this.prevPage = [];
            }
         },
         search(){
            this.searchUrl = "";
            let searchParam = [];
            if(this.searchOrder.trim() != ""){
               searchParam.push("order="+this.searchOrder.trim());
            }
            if(this.searchClient.trim() != ""){
               searchParam.push("client="+this.searchClient.trim());
            }
            if(this.searchProvider.trim() != ""){
               searchParam.push("provider="+this.searchProvider.trim());
            }
            if(this.searchCity != "not"){
               searchParam.push("city="+this.searchCity);
            }
            if(this.searchStatus != ""){
               searchParam.push("status="+this.searchStatus);
            }
            if(this.searchService != ""){
               searchParam.push("service="+this.searchService);
            }
            if(this.searchAssign != ""){
               searchParam.push("assign="+this.searchAssign);
            }

            if(searchParam.length == 0){
               return;
            }
            this.searchUrl = searchParam.join("&");

            this.endPoint = this.mainUrl + "?" + searchParam.join("&");
            this.hasSearch = true;
            this.loading = true;
            this.pageNumber = 1;
            this.nextPage = null;
            this.prevPage = [];
            this.getData();
         },
         resetSearch(){
            this.searchOrder = "";
            this.searchClient = "";
            this.searchProvider = "";
            this.searchCity = "not";
            this.searchStatus = "";
            this.searchUrl = "";
            this.searchService = "";
            this.searchAssign = "";

            this.endPoint = this.mainUrl;
            this.loading = true;
            this.hasSearch = false;
            this.getData();
         },
         exportOrders(){
            this.exportError = "";
            this.exportDone = false;
            this.exportLoading = true;
            if(this.exportStart == "" | this.exportEnd == ""){
               this.exportError = "لابد من تحديد الفترة المراد تصديرها";
               this.exportLoading = false;
               return;
            }

            this.$http.post("/api/export/orders/slice", {
               "start": this.getDateFormat(this.exportStart),
               "end": this.getDateFormat(this.exportEnd),
               "status": this.exportStatus,
               "payment": this.exportPayment,
            }, {responseType: "blob"}).then((response) => {
               var fileURL = window.URL.createObjectURL(new Blob([response.data]));
               var fileLink = document.createElement('a');

               fileLink.href = fileURL;
               fileLink.setAttribute('download', 'orders_export.xlsx');
               document.body.appendChild(fileLink);

               fileLink.click();
               this.exportLoading = false;
               this.exportDone = true;
            }).catch(() => {
               this.exportError = "غير قادر على التصدير";
               this.exportLoading = false;
            });
         },
         getDateFormat(dat){
            let date = new Date(dat);
            return date.getFullYear() + '-' + 
               (date.getMonth() +1) + '-' + 
               date.getDate();
         }
      },
      created(){
         window.scroll(0, 0);
         this.$store.commit("SET_ACTIVE_MENU", "all_orders");
         this.$store.commit("SET_BREADCRUMB", this.breadcrum);
         this.$store.commit("SET_ERROR", "");
         this.$store.commit("SET_SUCCESS", "");
         this.$store.commit("SET_TITLE", "الطلبات");
         
         this.getCities();
         this.getData();
      }
   }
</script>