<template>
    <div>
        <div class="row" v-if="loading">
            <div class="col">
                <div class="big-loader text-center pt-5- pb-50">
                    <loader />
                </div>
            </div>
        </div>

        <div class="row" v-if="!hasError && !loading">
            <div class="col">
                <div class="card shadow mb-4">
                    <form action="" @submit.prevent="submit">
                        <div class="card-body">
                            <div class="row justify-content-center">
                                <div class="col-sm-8">
                                    <div class="alert alert-warning ltr" role="alert">
                                        <strong>هذا السند لايمكن التعديل بعد الاضافة</strong>
                                    </div>
                                    <div class="form-group">
                                        <label for="">رقم الطلب</label>
                                        <input type="text" v-model="order_number" class="form-control" placeholder="رقم الطلب">
                                    </div>
                                    <div class="form-group">
                                        <label for="">المبلغ</label>
                                        <input type="text" v-model="amount" class="form-control ltr" placeholder="المبلغ">
                                    </div>
                                    <div class="form-group">
                                        <label for="">دائن / مدين</label>
                                        <select class="form-control" v-model="credit_debit">
                                            <option value="1"> دائن (1) </option>
                                            <option value="-1"> مدين (1-) </option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <label for="">طريقة الدفع</label>
                                        <select class="form-control" v-model="payment_type">
                                            <option value="cash">Cash</option>
                                            <option value="stc">STC</option>
                                            <option value="appelpay">AppelPay</option>
                                            <option value="visa">Visa</option>
                                            <option value="transfaer">Transfaer</option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <label for="">هل هذا السند مقابل عمولة</label>
                                        <input type="checkbox" name="commission" class="form-check-input ml-2" v-model="is_commission">
                                        <label for="commission" class="form-check-label ml-2">نعم</label>
                                    </div>
                                    <div class="form-group">
                                        <label for="">الرقم المرجعي</label>
                                        <input type="text" class="form-control" v-model="reference" placeholder="الرقم المرجعي">
                                    </div>
                                    <div class="form-group">
                                        <label for="">وصف العملية <small>سيظهر فى تطبيق المزود</small></label>
                                        <input type="text" v-model="discription" class="form-control" placeholder="وصف العملية">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer text-center">
                            <button type="submit" class="btn btn-success" v-if="!formLoader">إرسال</button>
                            <div class="text-center pt-50 pb-50" v-if="formLoader">
                                <inlineloader />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    export default {
        name: "CreatePaymentNew",
        computed:{
            ...mapGetters(['getUserData'])
        },
        data(){
            return{
                user_ref: this.$route.params.user_ref,
                owner_type: this.$route.query.owner_type,
                loading: false,
                hasError: false,
                formLoader: false,
                order_number: "",
                amount: 0,
                discription: "",
                credit_debit: "1",
                payment_type:'cash',
                is_commission: false,
                reference: "",
                breadcrum: {
                    "/": "الرئيسية",
                    "/users": "عملاء ومزودين",
                    "#": "إضافة سند قبض",
                },
            }
        },
        methods:{
            submit(){ 
                this.$store.commit("SET_ERROR", "");
                this.$store.commit("SET_SUCCESS", "");

                if(this.amount == 0 || this.discription.trim() == ""){
                    this.$store.commit("SET_ERROR", "يجب ادخال المبلغ ووصف العملية");
                    return false;
                }
                this.formLoader = true;
                this.$http.post("/api/payments/create/" + this.user_ref, {
                    order_number: this.order_number.trim(),
                    amount: this.amount,
                    discription: this.discription.trim(),
                    credit_debit: this.credit_debit.trim(),
                    payment_type: this.payment_type.trim(),
                    is_commission: this.is_commission,
                    reference: this.reference.trim(),
                    owner_type: this.owner_type,
                }).then(() => {
                    this.formLoader = false;
                    this.$router.replace('/users/paymentsNew/' + this.user_ref + '?type=' + this.owner_type);
                }).catch(error =>{
                    this.formLoader = false;
                    this.$store.commit("SET_ERROR", error.data.error);
                });
            }
        },
        created(){
            window.scroll(0, 0);
            this.$store.commit("SET_ERROR", "");
            this.$store.commit("SET_SUCCESS", "");
            this.$store.commit("SET_BREADCRUMB", this.breadcrum);
            this.$store.commit("SET_TITLE", "إضافة سند قبض");

            setTimeout(function(usr){
                if(!usr.is_admin && !usr.can_create){
                    window.location.href = "/";
                    return;
                    }
            }, 2000, this.getUserData);
        }
    }
</script>