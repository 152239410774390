<template>
   <div>
      <div class="row mb-5">
         <div class="col">
            <div class="card border-left-warning shadow h-100 py-2">
               <div class="card-body">
                  <form action="" @submit.prevent="search">
                     <div class="row">
                        <div class="col">
                           <div class="row">
                              <div class="col ltr">
                                 <datepicker v-model="searchStart" format="yyyy-MM-dd" input-class="form-control" placeholder="من تاريخ"></datepicker>
                              </div>
                              <div class="col ltr">
                                 <datepicker v-model="searchEnd" format="yyyy-MM-dd" input-class="form-control" placeholder="إلى تاريخ"></datepicker>
                              </div>
                              <div class="col">
                                 <select v-model="searchType" class="form-control">
                                    <option value="">جميع السندات</option>
                                    <option value="me">سنداتي</option>
                                 </select>
                              </div>
                           </div>
                        </div>
                        <div class="col-auto">
                           <button type="submit" class="btn btn-success">
                              <i class="fas fa-search"></i>
                           </button>
                           <button type="button" v-if="hasSearch" class="btn btn-light ml-2" @click.prevent="resetSearch">إلغاء</button>
                        </div>
                     </div>
                  </form>
               </div>
            </div>
         </div>
      </div>
      <div class="card shadow mb-4">
         <div class="card-header py-3">
            <h6 class="m-0 font-weight-bold text-primary">المعاملات المالية</h6>
         </div>

         <div class="card-body">
            <div class="row">
               <div class="col text-right pb-3 pagination-div">
                  <a href="" class="d-inline-block mr-2" v-if="prevPage.length" @click.prevent="goToPrev"><i class="far fa-caret-square-right"></i></a>
                  <div class="d-inline-block rounded border border-secondary">{{ pageNumber }}</div>
                  <a href="" class="d-inline-block ml-2" v-if="nextPage" @click.prevent="goToNext"><i class="far fa-caret-square-left"></i></a>
               </div>
            </div>
            <div class="table-responsive">
               <div class="big-loader text-center pt-50 pb-50" v-if="loading">
                  <loader />
               </div>
               <table v-if="!loading" class="table table-bordered table-striped text-center" id="dataTable" width="100%" cellspacing="0">
                  <thead>
                     <tr>
                        <th>رقم الطلب</th>
                        <th>الاسم</th>
                        <th>الجوال</th>
                        <th>مزود خدمة</th>
                        <th>بتاريخ</th>
                        <th>القيمة</th>
                        <th>الرصيد بعد العملية</th>
                        <th>الرصيد قبل الطلب</th>
                        <th>طريقة الدفع</th>
                        <th>البيان</th>
                        <th>إضافة رصيد</th>
                        <th>الحالة</th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr v-for="(row, index) in rows" :key="index">
                        <td>{{ row.order_number }}</td>
                        <td>{{ row.name }}</td>
                        <td>{{ row.mobile }}</td>
                        <td>
                           <i class="far fa-check-circle" v-if="row.is_provider"></i>
                           <i class="far fa-times-circle text-secondary" v-else></i>
                        </td>
                        <td>{{ row.on }}</td>
                        <td>{{ row.amount }}</td>
                        <td>{{ row.current_balance }}</td>
                        <td>{{ row.balance_before_order }}</td>
                        <td>{{ row.gateway }}</td>
                        <td>{{ row.reference }}</td>
                        <td>{{ (row.wallet_recharge)? true : false }}</td>
                        <td>{{ row.status }}</td>
                     </tr>
                  </tbody>
               </table>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
   import Datepicker from 'vuejs-datepicker';

   export default 
   {
      name: "Transactions",
      components: {
         Datepicker
      },
      data(){
         return {
            loading: true,
            cities_loader: true,
            breadcrum: {
               "/": "الرئيسية",
               "#": "المعاملات المالية",
            },
            rows: [],
            pageNumber: 1,
            nextPage: null,
            prevPage: [],
            hasSearch: false,
            mainUrl: "/api/financial/transactions",
            endPoint: "/api/financial/transactions",
            searchUrl: "",
            searchStart: "",
            searchEnd: "",
            searchType: "",
         }
      },
      methods: {
         getData(){
            this.$http.get(this.endPoint).then(response => {
               this.loading = false;
               this.rows = response.data.rows;
               this.nextPage = response.data.next;
            }).catch(err => {
               this.$store.commit("SET_ERROR", err.data.error);
            });
         },
         goToNext(){
            this.prevPage.push(this.nextPage);
            this.endPoint = this.mainUrl + "?page=" + this.nextPage;
            if(this.searchUrl != "")
               this.endPoint = this.endPoint + "&" + this.searchUrl;
            this.loading = true;
            this.pageNumber = this.pageNumber + 1;
            this.getData();
         },
         goToPrev(){
            let prevIndex = this.prevPage.length - 1;
            
            if(this.pageNumber == 2){
               this.endPoint = this.mainUrl;
               if(this.searchUrl != "")
                  this.endPoint = this.endPoint + "?" + this.searchUrl;
            }else{
               this.endPoint = this.mainUrl + "?page=" + this.prevPage[prevIndex];
               if(this.searchUrl != "")
                  this.endPoint = this.endPoint + "&" + this.searchUrl;
            }
            
            this.$delete(this.prevPage, prevIndex);
            
            this.loading = true;
            this.pageNumber = this.pageNumber - 1;
            this.getData();
            if(this.pageNumber == 1){
               this.prevPage = [];
            }
         },
         search(){
            this.searchUrl = "";
            let searchParam = [];
            
            if(this.searchStart != ""){
               searchParam.push("start="+this.getDateFormat(this.searchStart));
            }
            if(this.searchEnd != ""){
               searchParam.push("end="+this.getDateFormat(this.searchEnd));
            }
            if(this.searchType != ""){
               searchParam.push("type="+this.searchType);
            }

            if(searchParam.length == 0){
               return;
            }
            this.searchUrl = searchParam.join("&");

            this.endPoint = this.mainUrl + "?" + searchParam.join("&");
            this.hasSearch = true;
            this.loading = true;
            this.pageNumber = 1;
            this.nextPage = null;
            this.prevPage = [];
            this.getData();
         },
         resetSearch(){
            this.searchStart = "";
            this.searchEnd = "";
            this.searchType = "";
            this.searchUrl = "";

            this.endPoint = this.mainUrl;
            this.loading = true;
            this.hasSearch = false;
            this.getData();
         },
         getDateFormat(dat){
            let date = new Date(dat);
            return date.getFullYear() + '-' + 
               (date.getMonth() +1) + '-' + 
               date.getDate();
         }
      },
      created(){
         window.scroll(0,0);
         this.$store.commit("SET_ACTIVE_MENU", "transactions");
         this.$store.commit("SET_BREADCRUMB", this.breadcrum);
         this.$store.commit("SET_ERROR", "");
         this.$store.commit("SET_SUCCESS", "");
         this.$store.commit("SET_TITLE", "المعاملات المالية");
         
         this.getData();
      }
   }
</script>