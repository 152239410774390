<template>
   <div>
      <div class="row" v-if="loading">
         <div class="col">
            <div class="big-loader text-center pt-50 pb-50">
               <loader />
            </div>
         </div>
      </div>

      <div class="row pb-3" v-if="!hasError && !loading && (getUserData.is_admin || getUserData.can_edit)">
         <div class="col text-right">
            <router-link :to="'/packages/' + package_ref" class="btn btn-warning btn-sm text-white ml-2"><i class="fas fa-edit"></i> تعديل</router-link>
         </div>
      </div>

      <div class="row" v-if="!hasError && !loading">
         <div class="col">
            <div class="card shadow mb-4">
               <div class="card-body">
                  <div class="row">
                     <div class="col">
                        <div class="row pb-4">
                           <div class="col">
                              <strong for="">خاص بتطبيق</strong>
                              <div>{{ row.app }}</div>
                           </div>
                           <div class="col">
                              <strong for="">الحالة</strong>
                              <div>{{ row.status }}</div>
                           </div>
                        </div>

                        <div class="row pb-4">
                           <div class="col">
                              <strong for="">الاسم - عربي</strong>
                              <div>{{ row.name_ar }}</div>
                           </div>
                           <div class="col">
                              <strong for="">الاسم - انجليزي</strong>
                              <div>{{ row.name_en }}</div>
                           </div>
                        </div>

                        <div class="row pb-4">
                           <div class="col">
                              <strong for="">الوصف - عربي</strong>
                              <div>{{ row.description_ar }}</div>
                           </div>
                           <div class="col">
                              <strong for="">الوصف - انجليزي</strong>
                              <div>{{ row.description_en }}</div>
                           </div>
                        </div>

                        <div class="row pb-4">
                           <div class="col">
                              <strong for="">قابلة للشراء</strong>
                              <div>{{ (availableArr[row.available_to_buy])? availableArr[row.available_to_buy] : row.available_to_buy }}</div>
                              <div v-if="row.available_to_buy == 'bank_card'">اسم البنك: {{ row.bank_name }}</div>
                           </div>
                           <div class="col">
                              <strong for="">مدة فعالية الباقة</strong>
                              <div>{{ row.activation_period_number }} - {{ row.activation_period_type }}</div>
                           </div>
                        </div>

                        <div class="row pb-4">
                           <div class="col">
                              <strong for="">ملاحظات داخليه لا تظهر في التطبيق</strong>
                              <div>{{ row.notice }}</div>
                           </div>
                        </div>

                        <div class="row pb-4">
                           <div class="col">
                              <strong for="">سعر الشراء</strong>
                              <div>{{ row.amount }} ريال</div>
                           </div>
                           <div class="col" v-if="row.app == 'provider'">
                              <strong for="">يتم شحن محظفة المزود بمبلغ</strong>
                              <div>{{ row.pay_credit }}</div>
                           </div>
                        </div>

                        <div class="row pb-4" v-if="row.custom_services && row.app == 'customer'">
                           <div class="col">
                              <hr>
                           </div>
                        </div>

                        <div class="row pb-4" v-if="row.custom_services && row.app == 'customer'">
                           <div class="col">
                              <table class="table table-striped">
                                 <thead>
                                    <th>#</th>
                                    <th>الخدمة</th>
                                    <th>نوع الخصم</th>
                                    <th>قيمة الخصم</th>
                                    <th>الحد الاقصى</th>
                                    <th>مرات الاستخدام</th>
                                 </thead>
                                 <tbody>
                                    <tr v-for="(item, index) in row.services" :key="index">
                                       <td>{{ (index + 1) }}</td>
                                       <td>{{ item.name }}</td>
                                       <td>{{ item.discount_type }}</td>
                                       <td>{{ item.discount_value }}</td>
                                       <td>{{ item.max_value }}</td>
                                       <td>{{ item.using_count }}</td>
                                    </tr>
                                 </tbody>
                              </table>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="row pt-4">
         <div class="col">
            <datatable
               title="المستفيدون من الباقة"
               :columns="tableColums"
               :rows="purchases"
               :exportable="true"
               v-if="purchases.length"
            />
         </div>
      </div>
   </div>
</template>

<script>
   import DataTable from "vue-materialize-datatable";
   import { mapGetters } from 'vuex';

   export default {
      name: "PackageView",
      components: {
         "datatable": DataTable
      },
      computed: {
         ...mapGetters(["getUserData"])
      },
      data(){
         return {
            package_ref: this.$route.params.package_ref,
            loading: true,
            formLoading: false,
            hasError: false,
            servicesArr: [],
            newService: "",
            breadcrum: {
               "/": "الرئيسية",
               "/packages": "الباقات",
            },
            row: [],
            purchases: [],
            availableArr: {
               "yes": "نعم ، يظهر فى التطبيق",
               "no": "لا ، لا يظهر فى التطبيق",
               "bank_card": "تفعيل تلقائي عند استخدام بطاقة بنكية",
               "stc_cashback": "STC Cash back",
            },
            tableColums: [
               {
                  label: "الاسم",
                  field: "name",
                  numeric: false,
                  html: false
               },
               {
                  label: "الجوال",
                  field: "mobile",
                  numeric: false,
                  html: false
               },
               {
                  label: "القيمة",
                  field: "amount",
                  numeric: false,
                  html: false
               },
               {
                  label: "بتاريخ",
                  field: "created_at",
                  numeric: false,
                  html: false
               },
            ],
         }
      },
      methods: {
         getData(){
            this.$http.get("/api/packages/get/"+ this.package_ref).then(response => {
               this.loading = false;
               this.row = response.data.row;
               this.purchases = response.data.purchases;
               this.breadcrum["#"] = this.row.name_ar;
               this.$store.commit("SET_BREADCRUMB", this.breadcrum);
            }).catch(error => {
               this.hasError = true;
               if(error.data && error.data.error){
                  this.$store.commit("SET_ERROR", error.data.error);
               }else{
                  this.$store.commit("SET_ERROR", "لدينا خطأ فني");
               }
            });
         },
      }, 
      created(){
         this.$store.commit("SET_ERROR", "");
         this.$store.commit("SET_SUCCESS", "");
         this.$store.commit("SET_TITLE", "بيانات الباقة");

         this.getData();
      },
   }
</script>