import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios';
import VueAxios from 'vue-axios';
import Loader from "./components/Loader";
import InlineLoader from "./components/InlineLoader";

Vue.config.productionTip = false

axios.defaults.headers.post['Content-Type'] = 'application/json; charset=utf-8';
axios.interceptors.response.use((response) => {
  return response;
}, function(error){
  if(error.response.status == 401){
    router.replace("/login");
  }
  return Promise.reject(error.response);
});

Vue.use(VueAxios, axios);
Vue.component('loader', Loader);
Vue.component('inlineloader', InlineLoader);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
