<template>
   <div>
      <div class="row" v-if="loading">
         <div class="col">
            <div class="big-loader text-center pt-50 pb-50">
               <loader />
            </div>
         </div>
      </div>

      <div class="row" v-if="!hasError">
         <div class="col pb-5">
            <div class="google-map" ref="googleMap" style="height: 300px;"></div>
         </div>
      </div>
   </div>
</template>

<script>
   import GoogleMapsApiLoader from 'google-maps-api-loader';
   import { mapGetters } from 'vuex';

   export default {
      name: "ProviderMapIframe",
      computed: {
         ...mapGetters(["getUserData"])
      },
      data(){
         return {
            order_ref: this.$route.params.order_ref,
            loading: true,
            hasError: false,
            order: [],
            lastTrack: null,
            arroundProviders: [],
            
            mapConfig: {
               zoom: 10,
               center: { lat: -25.363, lng: 131.044 }
            },
            mapApi: "AIzaSyCvp2KggXNAbN1zdNRSBHh3eNNXsEp1XEo",
            google: null,
            map: null,
            clientMarker: { lat: -25.363, lng: 131.044 },
            markersList: [],
            infoWindowList: [],
         }
      },
      methods: {
         getData(){
            this.$http.get("/api/stream/getOrderSuggestedProviders/"+ this.order_ref + "?providers=true&token=sxPjx67DAcMdvhNCHhhSxbZQdgFqMJmhZpSazzfQWAsUC4MBMqTQ3GB4BFrLKCJzmdgNUZcec4vnaBCeetphh8w7k6RnA2adn9KBJbGQzJQLDAa8GH5zHZfuDaQeCbx").then(response => {
               this.loading = false;
               this.order = response.data.row;
               this.mapConfig.center = {lat: this.order.latitude, lng: this.order.longitude};
               this.clientMarker = {lat: this.order.latitude, lng: this.order.longitude};
               this.lastTrack = response.data.lastTrack;
               this.arroundProviders = response.data.arroundProviders;
               
               this.initializeMap();
            }).catch(error => {
               console.log(error);
               this.hasError = true;
               this.$store.commit("SET_ERROR", "لدينا خطأ فني");
            });
         },
         initializeMap() {
            const mapContainer = this.$refs.googleMap;
            this.map = new this.google.maps.Map(
               mapContainer, this.mapConfig
            );

            new this.google.maps.Marker({
               position: this.clientMarker,
               map: this.map,
               icon: "/img/man.png",
            });

            if(this.order.places){
               this.order.places.map(place => {
                  new this.google.maps.Marker({
                     position: {lat: place.location._latitude, lng: place.location._longitude},
                     map: this.map,
                     icon: "/img/cutlery.png",
                  });
               });
            }

            if(this.lastTrack){
               new this.google.maps.Marker({
                  position: {lat: this.lastTrack.latitude, lng: this.lastTrack.longitude},
                  map: this.map,
                  icon: "/img/location-pin.png",
               });
            }

            if(this.arroundProviders){
               this.arroundProviders.map(rProvider => {
                  this.markersList[rProvider.id] = new this.google.maps.Marker({
                     position: {lat: rProvider.latitude, lng: rProvider.longitude},
                     map: this.map,
                     icon: "/img/car-parking.png",
                  });

                  this.infoWindowList[rProvider.id] = new this.google.maps.InfoWindow({
                     content: `<div style="padding: 10px;">الاسم: ${rProvider.name} <br>الجوال: ${rProvider.mobile}</div>`
                  });

                  this.markersList[rProvider.id].addListener("click", () => {
                     this.infoWindowList[rProvider.id].open(this.map, this.markersList[rProvider.id]);
                  });
               });
            }
         },
      }, 
      created(){
         this.$store.commit("SET_TITLE", "suggested providers");
         this.getData();
      },
      async mounted() {
         const googleMapApi = await GoogleMapsApiLoader({
            apiKey: this.mapApi
         });
         this.google = googleMapApi;
      },
   }
</script>