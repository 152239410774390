<template>
   <div>
      <div class="row" v-if="loading">
         <div class="col">
            <div class="big-loader text-center pt-50 pb-50">
               <loader />
            </div>
         </div>
      </div>

      <div class="row pb-5" v-if="!hasError">
         <div class="col text-right">
            <router-link :to="`/users/payments/create/${user_ref}`" class="btn btn-warning" v-if="getUserData.is_admin || getUserData.can_create"><i class="far fa-edit"></i> إضافة سند قبض</router-link>
            <button class="btn btn-secondary ml-3" @click="revisionDone"><i class="fas fa-user-check"></i> تمت المراجعة</button>
         </div>
      </div>

      <div class="row" v-if="!hasError && !loading">
         <div class="col mb-3">
            <div class="text-center pt-2 pb-2" v-if="balanceLoader">
               <inlineloader />
            </div>
            <button type="button" class="btn btn-info" v-else @click="getOldBalance">استعلام عن الرصيد فى التطبيق القديم</button>
            <div class="ml-3 d-inline-block" v-if="oldBalance != null">{{ oldBalance }} ريال</div>
         </div>
      </div>
      <div class="row" v-if="oldBalance != null">
         <div class="col">
            <div class="alert alert-warning">تنبيه : الحساب فى التطبيق القديم إذا كان اقل من صفر (-) فتعني ان له رصيد في ازهلها وإذا كان اكبر من صفر (+) فتعني ان عليه عملة إلى إزهلها</div>
         </div>
      </div>

      <div class="row" v-if="!hasError && !loading">
         <div class="col">
            <div class="card shadow mb-4">
               <div class="card-body">
                  <div class="row">
                     <div class="col table-wrap">
                        <datatable
                           :title="`كشف حساب ${row.name} - الرصيد الحالي ${row.balance} ريال`"
                           :columns="tableColums"
                           :rows="payments"
                           :exportable="true"
                           v-if="payments"
                        />
                        <div v-else class="alert alert-info">لا توجد بيانات</div>
                     </div>
                  </div>
                  <div class="row mt-5" v-if="row && row.revision_users">
                     <div class="col">
                        <h4>المراجعات : </h4>
                        <table class="table table-striped">
                           <thead>
                              <tr>
                                 <th>المراجع</th>
                                 <th>تاريخ المراجعة</th>
                              </tr>
                           </thead>
                           <tbody>
                              <tr v-for="(user, index) in row.revision_users" :key="index">
                                 <td>{{ user.name }}</td>
                                 <td><div class="ltr">{{ user.date_str }}</div></td>
                              </tr>
                           </tbody>
                        </table>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
   import DataTable from "vue-materialize-datatable";
   import alertifyjs from "alertifyjs";
   import swal from 'sweetalert';
   import { mapGetters } from 'vuex';
   
   export default {
      name: "UserPayments",
      components: {
         "datatable": DataTable
      },
      computed: {
         ...mapGetters(["getUserData"])
      },
      data(){
         return {
            user_ref: this.$route.params.user_ref,
            loading: true,
            hasError: false,
            balanceLoader: false,
            row: [],
            payments: null,
            oldBalance: null,
            tableColums: [
               {
                  label: "رقم الطلب",
                  field: "order_number",
                  numeric: false,
                  html: false
               },
               {
                  label: "بتاريخ",
                  field: "on",
                  numeric: false,
                  html: false
               },
               {
                  label: "القيمة",
                  field: "amount",
                  numeric: true,
                  html: false
               },
               {
                  label: "الرصيد بعد العملية",
                  field: "current_balance",
                  numeric: true,
                  html: false
               },
               {
                  label: "الرصيد قبل الطلب",
                  field: "balance_before_order",
                  numeric: true,
                  html: false
               },
               {
                  label: "طريقة الدفع",
                  field: "gateway",
                  numeric: false,
                  html: false
               },
               {
                  label: "البيان",
                  field: "reference",
                  numeric: false,
                  html: false
               },
               {
                  label: "الرقم المرجعي",
                  field: (row) => {
                     let ref_code = (row.response)? row.response.id : "";
                     if(row.STCPayPmtReference){
                        ref_code = row.STCPayPmtReference;
                     }
                     
                     if(ref_code == "" && row.p_id != "" && row.gateway == "checkout"){
                        ref_code = row.p_id;
                     }
                     return ref_code;
                  },
                  numeric: false,
                  html: false
               },
               {
                  label: "إضافة رصيد",
                  field: "wallet_recharge",
                  numeric: false,
                  html: false
               },
               {
                  label: "الحالة",
                  field: "status",
                  numeric: false,
                  html: false
               },
            ],
            breadcrum: {
               "/": "الرئيسية",
               "/users": "عملاء ومزودين",
            },
         }
      },
      methods: {
         getData(){
            this.$store.commit("SET_ERROR", "");
            this.$http.get("/api/users/payments/"+ this.user_ref).then(response => {
               this.loading = false;
               this.row = response.data.row;
               this.payments = response.data.payments;
               this.breadcrum["/users/"+this.user_ref] = this.row.name;
               this.breadcrum["#"] = "كشف الحساب";
               this.$store.commit("SET_BREADCRUMB", this.breadcrum);
               this.$store.commit("SET_TITLE", "كشف حساب : " + this.row.name);
            }).catch(error => {
               console.log(error);
               this.hasError = true;
               this.$store.commit("SET_ERROR", "لدينا خطأ فني");
            });
         },
         getOldBalance(){
            this.balanceLoader = true;
            this.$store.commit("SET_ERROR", "");
            this.$http.get("/api/providers/getOldBalance/"+ this.row.mobile).then(response => {
               this.balanceLoader = false;
               this.oldBalance = response.data.balance;
            }).catch(error => {
               this.balanceLoader = false;
               if(error.data.error){
                  this.$store.commit("SET_ERROR", "الحساب غير مسجل فى التطبيق القديم");
               }else{
                  this.$store.commit("SET_ERROR", "لدينا خطأ فني");
               }
            });
         },
         async revisionDone(){
            this.$store.commit("SET_ERROR", "");
            let con = await swal({
               title: "مراجعة الحساب",
               text: "هل تريد تأكيد مراجعة الحساب ؟",
               icon: "info",
               buttons: {
                  confirm: true,
                  cancel: true,
               }
            });
            
            if(con == true){
               this.$http.get("/api/providers/revisionDone/" + this.user_ref).then(() => {
                  alertifyjs.success("تم مراجعة الحساب");
               }).catch(() => {
                  alertifyjs.error("لدينا خطأ فني يرجى المحاولة في وقت لاحق");
               });
            }
         }
      }, 
      created(){
         window.scroll(0,0);
         this.$store.commit("SET_ERROR", "");
         this.$store.commit("SET_SUCCESS", "");

         this.getData();
      },
   }
</script>

<style>
   .table-wrap table tr td {
      white-space: normal !important;
   }
</style>