import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login';
import AllOrders from '../views/AllOrders';
import Order from '../views/Order';
import OrderEdit from '../views/OrderEdit';
import Users from '../views/users/Users';
import UserView from '../views/users/UserView';
import UserPayments from '../views/users/Payments';
import OrderdashBoared from '../views/users/OrderdashBoared';
import CreatePayment from '../views/users/CreatePayment';
import ServicesApproval from '../views/users/ServicesApproval';
import ProvidersRequests from '../views/users/ProvidersRequests';
import ProvidersRejected from '../views/users/ProvidersRejected';
import ProvidersBlocked from '../views/users/ProvidersBlocked';
import UserForm from '../views/users/UserForm';
import RestPass_Form from '../views/users/RestPass_Form';
import Managers from '../views/managers/Managers';
import ManagerForm from '../views/managers/ManagerForm';
import Transactions from '../views/financial/Transactions';
import AllProviders from '../views/reports/AllProviders';
import AllProvidersIframe from '../views/reports/AllProvidersIframe';
import OrdersReport from '../views/reports/OrdersReport';
import CitiesReport from '../views/reports/CitiesReport';
import ClientsReport from '../views/reports/ClientsReport';
import ProvidersReport from '../views/reports/ProvidersReport';
import PlacesReport from '../views/reports/PlacesReport';
import AccountingReport from '../views/reports/AccountingReport';
import Packages from "../views/packages/Packages";
import PackageForm from "../views/packages/PackageForm";
import PackageView from "../views/packages/PackageView";
import ProviderMapIframe from "../views/reports/ProviderMapIframe";
import AllCities from "../views/towing/cities_list";
import citiesForm from "../views/towing/cities_form";
import AllLines from "../views/towing/lines_list";
import linesForm from "../views/towing/lines_form";
import Logging from "../views/Logging";
import All_Cities from '../views/users/All_Cities';
import OrdersFliter from '../views/users/OrdersFliter';
import Follow from '../views/users/Follow';
import operationCompanies from '../views/Companys/operationCompanies';
import PaymentsC from '../views/Companys/PaymentsC';
import CompaniesForm from '../views/Companys/CompaniesForm';
import createPay from '../views/Companys/CreatePay';
import paymentsNew from '../views/users/paymentsNew';
import CreatePaymentNew from '../views/users/CreatePaymentNew';
import paymentsDetails from '../views/users/paymentsDetails';
import paymentHistory from '../views/Companys/paymentHistory';
import newTransactions from '../views/financial/newTransactions';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  // ORDERS
  {
    path: '/allOrders',
    name: 'AllOrders',
    component: AllOrders
  },
  {
    path: '/order/:order_ref/:order_status',
    name: 'Order',
    component: Order
  },
  {
    path: '/orderEdit/:order_ref',
    name: 'OrderEdit',
    component: OrderEdit
  },
  {
    path: '/ProviderMapIframe/:order_ref',
    name: 'ProviderMapIframe',
    component: ProviderMapIframe
  },
  // USERS
  {
    path: '/users',
    name: 'Users',
    component: Users
  },
  {
    path: '/users/:user_ref',
    name: 'UserView',
    component: UserView
  },
  {
    path: '/userForm/:user_ref',
    name: 'UserForm',
    component: UserForm
  },
  {
    path:'/RestPass',
    name:'RestPass_Form',
    component:RestPass_Form
  },
  {
    path:'/OrderdashBoared',
    name:'OrderdashBoared',
    component:OrderdashBoared
  },
  {
    path:'/All_Cities',
    name:'All_Cities',
    component: All_Cities
  },
  {
    path: '/users/payments/:user_ref',
    name: 'UserPayments',
    component: UserPayments
  },
  {
    path: '/users/payments/create/:user_ref',
    name: 'CreatePayment',
    component: CreatePayment
  },
  {
    path: '/servicesApproval',
    name: 'ServicesApproval',
    component: ServicesApproval
  },
  {
    path: '/providersRequests',
    name: 'ProvidersRequests',
    component: ProvidersRequests
  },
  {
    path: '/providersRejected',
    name: 'providersRejected',
    component: ProvidersRejected
  },
  {
    path: '/ProvidersBlocked',
    name: 'ProvidersBlocked',
    component: ProvidersBlocked
  },
  // financial
  {
    path: '/transactions',
    name: 'Transactions',
    component: Transactions
  },
  // MANAGER
  {
    path: '/managers',
    name: 'Managers',
    component: Managers
  },
  {
    path: '/managers/:user_ref',
    name: 'ManagerForm',
    component: ManagerForm
  },
  // reports
  {
    path: '/allProviders',
    name: 'AllProviders',
    component: AllProviders
  },
  {
    path: '/AllProvidersIframe',
    name: 'AllProvidersIframe',
    component: AllProvidersIframe
  },
  {
    path: '/ordersReport',
    name: 'OrdersReport',
    component: OrdersReport
  },
  {
    path: '/citiesReport',
    name: 'CitiesReport',
    component: CitiesReport
  },
  {
    path: '/clientsReport',
    name: 'ClientsReport',
    component: ClientsReport
  },
  {
    path: '/providersReport',
    name: 'ProvidersReport',
    component: ProvidersReport
  },
  {
    path: '/placesReport',
    name: 'PlacesReport',
    component: PlacesReport
  },
  {
    path: '/accountingReport',
    name: 'AccountingReport',
    component: AccountingReport
  },
  // packages
  {
    path: '/packages',
    name: 'Packages',
    component: Packages
  },
  {
    path: '/packages/:package_ref',
    name: 'PackageForm',
    component: PackageForm
  },
  {
    path: '/packages/view/:package_ref',
    name: 'PackageView',
    component: PackageView
  },
  // towing 
  {
    path: '/AllCities',
    name: 'AllCities',
    component: AllCities
  },
  {
    path: '/cities/:city_ref',
    name: 'citiesForm',
    component: citiesForm
  },
  {
    path: '/lines',
    name: 'AllLines',
    component: AllLines
  },
  {
    path: '/lines/:line_ref',
    name: 'linesForm',
    component: linesForm
  },
  {
    path: '/logging',
    name: 'Logging',
    component: Logging
  },
  {
    path: '/OrdersFliter',
    name: 'OrdersFliter',
    component: OrdersFliter
  },
  {
    path: "/Follow",
    name: "Follow",
    component:Follow
  },
  {
    path: "/operationCompanies",
    name: "operationCompanies",
    component: operationCompanies
  },
  {
    path: "/PaymentsC/:user_ref",
    name: "PaymentsC",
    component: PaymentsC
  },
  {
    path: "/CompaniesForm/:user_ref",
    name: "CompaniesForm",
    component: CompaniesForm
  },
  {
    path: '/companies/paymentsC/create/:user_ref',
    name: "createPay",
    component: createPay
  },
  {
    path: '/users/paymentsNew/:user_ref',
    name: 'paymentsNew',
    component: paymentsNew
  },
  {
    path: '/users/CreatePaymentNew/create/:user_ref',
    name: 'CreatePaymentNew',
    component: CreatePaymentNew
  },
  {
    path: '/paymentsDetails/:payment_ref',
    name: 'paymentsDetails',
    component:paymentsDetails
  },
  {
    path: "/paymentHistory/:user_ref",
    name: "paymentHistory",
    component:paymentHistory
  },
  {
    path: '/newTransactions',
    name: 'newTransactions',
    component: newTransactions
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
